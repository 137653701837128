import React, { Component } from "react";
import ServerPagination from "../../components/paginatedtable";
import { appHelpers, graphHelpers } from "../../_helpers";
import { Table, Badge } from "react-bootstrap";
import TableCell from "../../components/table/tablecell";
import { appConstants } from "../../_constants";
import { alertActions, commonActions } from "../../_actions";
import { commonService } from "../../_services";
import { connect } from "react-redux";
import RecordModal from "./recordModal";
import DrillDownTable from "./airlinePaxDrilldownTable";
import { store } from "../../_store";
import moment from "moment";

const renderStatus = status => {
  switch (status) {
    case false:
      return <Badge variant="danger">Issues Exist</Badge>;
    case true:
      return <Badge variant="success">Good</Badge>;
    default:
      return <Badge variant="secondary">No Status</Badge>;
  }
};

const status = [
  {
    Id: 0,
    Name: "All Status"
  },
  {
    Id: false,
    Name: "Issues Exist"
  },
  {
    Id: true,
    Name: "Good"
  }
];

const AirlinePaxTrackerTableStructure = ({
  records,
  handleRowClick,
  dateTime,
  trackerType,
  pageIndex
}) => (
    <div>
      <Table hover className="table-vgg-darkblue" responsive="sm">
        <thead>
          <tr>
            <th>S/N</th>
            <th>Airline</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {records.map((record, index) => {
            return (
              <tr
                key={index}
                className="pointer"
                onClick={() => handleRowClick(record, dateTime, trackerType)}
              >
                <TableCell content={`${pageIndex + index + 1}`} />
                <TableCell content={record.AirlineName} />
                <TableCell content={renderStatus(record.Status)} />
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );

class PaginatedTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      drillDownData: null,
      timelineShow: false,
      processStatus: "",
      fileRecords: [],
      dateTime: null,
      trackerType: null,
      pageSize: 10,
      drillDownTotalCount: 0,
      drillDownCurrentPage: 1,
      selectedAirport: null,
      drillDownStatus: status,
      airports: [],
      flightNumbers: [],
      selectedFlightNumber: null,
      selectedStatus: null
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const reduxState = store.getState();
    let { airports } = reduxState;

    if (airports) {
      let modifiedAirport = [...airports.Results];
      modifiedAirport.unshift({ Name: "All Airports", Code: "-1" });
      this.setState({ airports: modifiedAirport });
    } else {
      dispatch(commonActions.getAllAirports());
    }
  }

  componentWillReceiveProps() {
    let { airports } = store.getState();
    if (airports) {
      let modifiedAirport = [...airports.Results];
      modifiedAirport.unshift({ Name: "All Airports", Code: "-1" });
      this.setState({ airports: modifiedAirport });
    }
  }

  fetchAirlineFlightNumbers = airlineId => {
    const { dispatch } = this.props;
    commonService.getAirlinesFlightNumbers(airlineId).then(res => {
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        let modifiedFlights = [...response.Results];
        let renameKey = graphHelpers.renamePropertyKeys(
          modifiedFlights,
          "FlightNumber",
          "Name"
        );

        renameKey.unshift({ Name: "All Flights", Id: "-1" });
        this.setState({ flightNumbers: renameKey });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  handleRowClick = (fileRecords, dateTime, trackerType) => {
    this.setState(
      {
        dateTime: dateTime,
        trackerType: trackerType,
        fileRecords: fileRecords
      },
      () => {
        this.getDrillDownData();
        this.fetchAirlineFlightNumbers(fileRecords.AirlineId);
      }
    );
  };

  handleFlightChange = selected => {
    this.setState({ selectedFlightNumber: selected ? selected.Name : "" });
  };

  handleAirportChange = selected => {
    this.setState({ selectedAirport: selected ? selected.Code : "" });
  };

  handleStatusChange = selected => {
    this.setState({ selectedStatus: selected ? selected.Id : "" });
  };

  //method to get data for drill down table on click of row
  getDrillDownData = (pageNumber = 1) => {
    const {
      fileRecords,
      dateTime,
      trackerType,
      pageSize,
      selectedAirport,
      selectedStatus,
      selectedFlightNumber
    } = this.state;
    const { dispatch } = this.props;

    let fromIndex;

    fromIndex = (pageNumber - 1) * pageSize;

    let parameter = {
      AirlineId: fileRecords.AirlineId,
      DateTime: moment(dateTime).format("YYYY-MM-DD"),
      TrackerType: trackerType
    };

    if (selectedAirport !== "-1" && selectedAirport !== "") {
      parameter.AirportCode = selectedAirport;
    }

    if (selectedStatus !== 0 && selectedStatus !== "") {
      parameter.Status = selectedStatus;
    }

    if (
      selectedFlightNumber !== "-1" &&
      selectedFlightNumber !== "All Flights" &&
      selectedFlightNumber !== ""
    ) {
      parameter.FlightNumber = selectedFlightNumber;
    }

    let payload = {
      PageSize: pageSize,
      From: fromIndex,
      Parameter: parameter
    };
    dispatch(alertActions.startRequest());
    commonService.getAirportLocation(payload).then(
      res => {
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          const response = res.response;
          let drillDownRecord = response.Results.map(
            item => item.PassengerAirlineResult
          );
          this.setState({
            drillDownData: drillDownRecord,
            timelineShow: true,
            drillDownTotalCount: response.TotalCount,
            drillDownCurrentPage: pageNumber
          });
          dispatch(alertActions.stopRequest());
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(alertActions.error(res.response.Message));
          dispatch(alertActions.stopRequest());
          dispatch(alertActions.error(res.response));
        }
      },
      error => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        dispatch(alertActions.stopRequest());
        dispatch(alertActions.error(errorMessage));
      }
    );
  };

  modalClose = () => {
    this.setState({
      timelineShow: false,
      selectedStatus: null,
      selectedAirport: null,
      selectedFlightNumber: null
    });
  };

  render() {
    const {
      totalCount,
      records,
      currentPage,
      pageSize,
      fetchRecords,
      dateTime,
      trackerType,
      recordStartDate
    } = this.props;
    const {
      drillDownData,
      fileRecords,
      timelineShow,
      drillDownTotalCount,
      drillDownCurrentPage,
      drillDownStatus,
      airports,
      flightNumbers
    } = this.state;

    let pageIndex = (currentPage - 1) * pageSize;

    let drillDownPageIndex = (drillDownCurrentPage - 1) * pageSize;

    return (
      <div>
        <div className="table-responsive" style={{ overflowX: "auto" }}>
          <ServerPagination
            fetchMore={fetchRecords}
            totalCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            target={
              <AirlinePaxTrackerTableStructure
                trackerType={trackerType}
                dateTime={dateTime}
                records={records}
                handleRowClick={this.handleRowClick}
                pageIndex={pageIndex}
              />
            }
          />
        </div>

        <RecordModal
          headerData={fileRecords}
          titlePrefix="Daily Passenger Count Tracker"
          show={timelineShow}
          onHide={this.modalClose}
          drillDownTotalCount={drillDownTotalCount}
          recordStartDate={recordStartDate}
        >
          <DrillDownTable
            records={drillDownData}
            totalCount={drillDownTotalCount}
            fetchRecords={this.getDrillDownData}
            currentPage={drillDownCurrentPage}
            pageIndex={drillDownPageIndex}
            departureAirport={airports}
            handleAirportChange={this.handleAirportChange}
            drillDownStatus={drillDownStatus}
            handleDrillDownStatusChange={this.handleStatusChange}
            handleSearch={this.getDrillDownData}
            drillDownTotalCount={drillDownTotalCount}
            handleFlightNumberChange={this.handleFlightChange}
            drillDownFlightNumber={flightNumbers}
          />
        </RecordModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { modal, requesting } = state;
  return {
    requesting,
    modal
  };
}
export default connect(mapStateToProps)(PaginatedTable);

/* Written with love by Juliet on July 2019 */
