import React from 'react';
import "./index.css"
import {
    Modal,
} from "react-bootstrap";
import Timeline from './timeline';
import moment from "moment/moment";

class timelineModal extends React.Component {
    render() {
        const { currentairline, timelinedata, manifestaggregate } = this.props;
        return (
            <Modal
                {...this.props}
                 backdrop
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={'modal-90w'}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {
                            currentairline
                        } ( {manifestaggregate.ManifestCount} manifests)
                        <span className={'time'}>
                               Created {moment(manifestaggregate.StartDate).fromNow()}
                        </span>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Timeline
                      timelineData={timelinedata}
                      airline={currentairline}
                  />
                </Modal.Body>
                {/*<Modal.Footer>*/}
                    {/*<Button onClick={this.props.onHide}>Close</Button>*/}
                {/*</Modal.Footer>*/}
            </Modal>
        );
    }
}

export default timelineModal
