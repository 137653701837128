import React from 'react';
import {
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from "recharts";
import { appConstants } from "../../_constants";

class LineChartGraphComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            opacity: {},
        }
    }
    componentDidMount() {
        const { legends } = this.props;
        let opacity = {};
        //set legends opacity
        legends.map(l => opacity[l['name']] = appConstants.GRAPH_FOCUS_OPACITY);
        this.setState({
            opacity
        });
    }
    handleMouseEnter(o) {
        const { dataKey } = o;
        const { opacity } = this.state;
        this.setState({
            opacity: { ...opacity, [dataKey]: appConstants.GRAPH_FOCUS_OPACITY },
        });
    }

    handleMouseLeave(o) {
        const { dataKey } = o;
        const { opacity } = this.state;
        this.setState({
            opacity: { ...opacity, [dataKey]: appConstants.GRAPH_DEFAULT_OPACITY },
        });
    }

    render() {
        let { data, legends } = this.props;
        let { opacity } = this.state;
        data = (data) ? data : [];
        return (
            <div>
                <ResponsiveContainer width={"100%"} height={405}>
                    <LineChart data={data}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid stroke={appConstants.GRAPH_GRID_COLOR} strokeDasharray="0 0" />
                        <XAxis dataKey="name" stroke={appConstants.GRAPH_AXIS_COLOR} />
                        <YAxis stroke={appConstants.GRAPH_AXIS_COLOR} />
                        <Tooltip />
                        <Legend onMouseEnter={(e) => this.handleMouseEnter(e)}
                            onMouseLeave={(e) => this.handleMouseLeave(e)} />
                        {
                            legends.map((legend, key) => {
                                return (
                                    <Line key={key} type="monotone" dataKey={legend.name}
                                        strokeOpacity={opacity[legend.name]}
                                        stroke={legend.color}
                                        activeDot={{ r: 8 }}
                                    />
                                )
                            })
                        }
                    </LineChart>
                </ResponsiveContainer>
            </div>
        );
    }
}

export const LineChartGraph = LineChartGraphComponent;
