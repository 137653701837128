import React, { Component } from 'react';
import AppLayout from "../layout/index";

// components
import { Row, Col } from 'react-bootstrap';
import Card from "../../components/card/index";
import { GraphHeader } from "../../components/graph/graphHeader";
import { PrimaryTab } from '../../components/tabs';
import PieChartGraph from '../../components/graph/PieGraph';
import BarChartGraph from "../../components/graph/BarGraph";
//libraries
import moment from 'moment';
import { connect } from "react-redux";
import { commonService } from "../../_services";
import { appConstants } from "../../_constants";
import { graphHelpers } from "../../_helpers";
// import { EmptyRecord } from "../../components/pageutility";
import { alertActions } from "../../_actions";
import NoResponse from "../../components/noResponse"


const pscMenu = [
    {
        name: "Avicollect vs PSC",
        link: "/avicollect-psc"
    },
    {
        name: "PDM Data vs PSC Data",
        link: "/pdm-psc"
    },
    {
        name: "Flight Date vs Settlement Date",
        link: "/flight-settlement"
    }
];

const totalSettledUnsettledLegends = [
    {
        name: "Total Settled",
        color: appConstants.BLUE_COLOR,
        colorId: 'colorPSCFlight',
        dataKey: "Settled"
    },
    {
        name: "Total Unsettled",
        color: appConstants.ORANGE_COLOR,
        colorId: 'colorAvicollectFlight',
        dataKey: "Unsettled"
    },
];

const unSettledLegends = [
    {
        name: "Not Settled",
        color: appConstants.YELLOW_COLOR,
        colorId: 'colorUnsettledAirlines',
        dataKey: "Unsettled"
    }
];

const flaggedLegends = [
    {
        name: "Flagged",
        color: appConstants.RED_COLOR,
        colorId: 'colorFlaggedAirlines',
        dataKey: "Flagged"
    }
];

class FlightSettlement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            departureStartDate: moment().subtract(1, 'month'),
            departureEndDate: moment(),
            totalSettledUnsettledStartDate: moment().subtract(1, 'month'),
            totalSettledUnsettledEndDate: moment(),
            UnsettledStartDate: moment().subtract(1, 'month'),
            UnsettledEndDate: moment(),
            aboveSLAStartDate: moment().subtract(1, 'month'),
            aboveSLAEndDate: moment(),
            totalSettledUnsettledManifestCount: [],
            unSettledManifestStatusByAirline: [],
            flaggedManifestStatusByAirline: [],
            flightStatusCount: [],
            requesting: true,
            response: false,
            flightStatusResponse: false,
            aboveSLAResponse: false,
            totalSettledUnsettledResponse: false,
            UnsettledResponse: false
        }
    }

    componentDidMount() {
        this.initializePageData();

    }

    //get flight status count
    flightStatus = (response) => {
        // console.log({response})
        let flightStatusCount = response.response.Result;
        this.setState({ flightStatusCount })
    };

    //get total settled unsettled
    totalSettledUnSettled = (response) => {
        let manifestStatusSummary = response.response.Summary;
        let totalSettledUnsettledManifestCount = [];
        totalSettledUnsettledManifestCount.push(
            {
                name: "Settled",
                Settled: manifestStatusSummary.Settled
            },
            {
                name: "UnSettled",
                Unsettled: manifestStatusSummary.Unsettled
            });

        this.setState({ totalSettledUnsettledManifestCount })
    };

    //get above SLA
    aboveSLA = (response) => {
        let flaggedManifestStatusByAirline = graphHelpers.addXAxisNames(response.response.Result.Flag.Items, "AirlineName");
        let flaggedData = [...flaggedManifestStatusByAirline];
        let reformedFlaggedManifestStatusByAirline = graphHelpers.renamePropertyKeys(flaggedData, "Count", "Flagged")

        this.setState({ flaggedManifestStatusByAirline: reformedFlaggedManifestStatusByAirline })
    };

    //get not settled
    notSettled = (response) => {
        // console.log({response});
        let unSettledManifestStatusByAirline = graphHelpers.addXAxisNames(response.response.Result.Unsettled.Items, "AirlineName");
        let unSettledData = [...unSettledManifestStatusByAirline];
        let reformedunSettledManifestStatusByAirline = graphHelpers.renamePropertyKeys(unSettledData, "Count", "Unsettled");
        // console.log({reformedunSettledManifestStatusByAirline});

        this.setState({ unSettledManifestStatusByAirline: reformedunSettledManifestStatusByAirline })
    };

    //on page load
    initializePageData = () => {
        let { departureStartDate, departureEndDate } = this.state;
        const { dispatch } = this.props;
        departureStartDate = departureStartDate.format("YYYY-MM-DDTHH:mm");
        departureEndDate = departureEndDate.format("YYYY-MM-DDTHH:mm");

        const payload = {
            StartDate: departureStartDate,
            EndDate: departureEndDate
        };

        dispatch(alertActions.startRequest());
        Promise.all([
            commonService.searchPscManifestStatus(payload),
            commonService.searchPscManifestStatusByAirline(payload),
        ]
        ).then(response => {
            dispatch(alertActions.stopRequest());
            let manifestStatus = response[0];
            let manifestStatusByAirline = response[1];
            if (manifestStatus.response.Result !== []) {
                this.setState({
                    flightStatusResponse: true,
                    totalSettledUnsettledResponse: true,
                })
            }
            if (manifestStatusByAirline.response.Result !== []) {
                this.setState({
                    UnsettledResponse: true,
                    aboveSLAResponse: true
                })
            }

            this.flightStatus(manifestStatus);
            this.totalSettledUnSettled(manifestStatus);
            this.notSettled(manifestStatusByAirline);
            this.aboveSLA(manifestStatusByAirline);

            // dispatch(alertActions.stopRequest());

        }).catch(err => {
            // console.log({err});
            this.setState({
                flightStatusResponse: false,
                totalSettledUnsettledResponse: false,
                UnsettledResponse: false,
                aboveSLAResponse: false
            })
            dispatch(alertActions.stopRequest());
            dispatch(alertActions.error(err))
        });
        dispatch(alertActions.startRequest());
    };

    // date picker function to get flight status count
    searchFlightStatusCount = async (payload) => {
        const { dispatch } = this.props;

        dispatch(alertActions.startRequest());
        try {
            const response = await commonService.searchPscManifestStatus(payload);
            if (response.status === appConstants.SUCCESS_RESPONSE) {
                this.setState({ flightStatusResponse: true });
                this.flightStatus(response);
            } else if (response.status === appConstants.ERROR_RESPONSE) {
                dispatch(alertActions.error(response.response))
            }
            dispatch(alertActions.stopRequest());
        } catch (err) {
            this.setState({ flightStatusResponse: true });
            dispatch(alertActions.stopRequest());
            dispatch(alertActions.error(err))

        }
    };

    // date picker function to get total settled and unsettled
    searchTotalSettledUnsettled = async (payload) => {
        const { dispatch } = this.props;
        dispatch(alertActions.startRequest());
        try {
            const response = await commonService.searchPscManifestStatus(payload);
            if (response.status === appConstants.SUCCESS_RESPONSE) {
                this.setState({ totalSettledUnsettledResponse: true });
                this.totalSettledUnSettled(response);
            } else if (response.status === appConstants.ERROR_RESPONSE) {
                dispatch(alertActions.error(response.response))
            }
            dispatch(alertActions.stopRequest());
        } catch (err) {
            dispatch(alertActions.stopRequest());
            dispatch(alertActions.error(err))

        }
    };

    // date picker function to get not settled
    searchNotSettled = async (payload) => {
        const { dispatch } = this.props;

        dispatch(alertActions.startRequest());
        try {
            const response = await commonService.searchPscManifestStatusByAirline(payload);

            if (response.status === appConstants.SUCCESS_RESPONSE) {
                this.setState({ UnsettledResponse: true });
                this.notSettled(response);
            } else if (response.status === appConstants.ERROR_RESPONSE) {
                dispatch(alertActions.error(response.response))
            }
            dispatch(alertActions.stopRequest());
        } catch (err) {
            this.setState({ UnsettledResponse: true });
            dispatch(alertActions.stopRequest());
            dispatch(alertActions.error(err))

        }
    };

    // date picker function to get above SLA data
    searchAboveSLA = async (payload) => {
        // let {aboveSLAStartDate, aboveSLAEndDate} = this.state;
        const { dispatch } = this.props;

        // const duration = moment.duration(moment(aboveSLAStartDate).diff(aboveSLAEndDate)).asDays();
        dispatch(alertActions.startRequest());
        try {
            const response = await commonService.searchPscManifestStatusByAirline(payload);
            if (response.status === appConstants.SUCCESS_RESPONSE) {
                this.setState({ aboveSLAResponse: true });

                this.aboveSLA(response);
            } else if (response.status === appConstants.ERROR_RESPONSE) {
                dispatch(alertActions.error(response.response))
            }
            dispatch(alertActions.stopRequest());
        } catch (err) {
            this.setState({ aboveSLAResponse: false })
            dispatch(alertActions.stopRequest());
            dispatch(alertActions.error(err))

        }
    };

    // handle apply click on date picker
    handleApply = async (event, picker, type) => {

        let StartDate = picker.startDate.format("YYYY-MM-DDTHH:mm");
        let EndDate = picker.endDate.format("YYYY-MM-DDTHH:mm");

        if (StartDate === EndDate) {
            StartDate = picker.startDate.format("YYYY-MM-DDT00:00");
            EndDate = picker.endDate.format("YYYY-MM-DDT23:00");
        }
        const payload = { StartDate, EndDate };
        try {
            let response; // = await commonService.searchManifestProcessDuration(payload);
            switch (type) {
                case appConstants.FLIGHT_STATUS_COUNT:
                    this.setState({
                        departureStartDate: picker.startDate,
                        DepartureEndDate: picker.endDate
                    }, () => {
                        response = this.searchFlightStatusCount(payload);
                    });
                    break;
                case appConstants.TOTAL_SETTLED_UNSETTLED:
                    this.setState({
                        totalSettledUnsettledStartDate: picker.startDate,
                        totalSettledUnsettledEndDate: picker.endDate
                    }, () => {
                        response = this.searchTotalSettledUnsettled(payload);
                    });
                    break;
                case appConstants.NOT_SETTLED:
                    this.setState({
                        UnsettledStartDate: picker.startDate,
                        UnsettledEndDate: picker.endDate
                    }, () => {
                        response = this.searchNotSettled(payload);
                    });
                    break;
                case appConstants.ABOVE_SLA:
                    this.setState({
                        aboveSLAStartDate: picker.startDate,
                        aboveSLAEndDate: picker.endDate
                    }, () => {
                        response = this.searchAboveSLA(payload);
                    });
                    break;
                default:
                    response = {}
            }

            return response;
        } catch (err) {
            // console.log({ err });
        }

    };

    noData = (response, chart) => {
        // console.log(response)
        return (!response) ?
            <NoResponse
                message={"No Chart Data"}
            /> :
            chart;
    };

    render() {
        const { departureStartDate, departureEndDate, flightStatusCount, totalSettledUnsettledManifestCount,
            unSettledManifestStatusByAirline, flaggedManifestStatusByAirline, totalSettledUnsettledStartDate,
            totalSettledUnsettledEndDate, UnsettledStartDate, UnsettledEndDate, aboveSLAStartDate,
            aboveSLAEndDate, flightStatusResponse, totalSettledUnsettledResponse,
            aboveSLAResponse, UnsettledResponse
        } = this.state;

        return (
            <AppLayout title={"Passenger Service Charge"}>
                {/*  */}
                <Row>
                    <Col md={12}>
                        <PrimaryTab menus={pscMenu} activeMenuIndex={3} nameKey={"Status"} dataKey={"Count"} />
                    </Col>
                </Row>

                {/* FLIGHT DATE VS SETTLEMENT DATE */}
                <Row>
                    <Col md={6}>
                        <Card title={<GraphHeader
                            flightSettlement
                            title={"Flight Status Count"}
                            startDate={departureStartDate}
                            endDate={departureEndDate}
                            handleEvent={this.handleEvent}
                            handleApply={(e, p) => this.handleApply(e, p, appConstants.FLIGHT_STATUS_COUNT)} />
                        }>
                            {this.noData(flightStatusResponse, <PieChartGraph data={flightStatusCount} nameKey={"Status"} dataKey={"Count"} />)}
                            {/*{(!response) ?*/}
                            {/*<NoResponse*/}
                            {/*message={"No Chart Data"}*/}
                            {/*/>*/}
                            {/*:*/}
                            {/*<PieChartGraph data={flightStatusCount} nameKey={"Status"} dataKey={"Count"}/>}*/}
                        </Card>
                    </Col>
                    <Col md={6}>
                        <Card title={<GraphHeader
                            flightSettlement
                            title={"Total"}
                            startDate={totalSettledUnsettledStartDate}
                            endDate={totalSettledUnsettledEndDate}
                            handleEvent={this.handleEvent}
                            handleApply={(e, p) => this.handleApply(e, p, appConstants.TOTAL_SETTLED_UNSETTLED)} />
                        }>
                            {this.noData(totalSettledUnsettledResponse,
                                <BarChartGraph
                                    legends={totalSettledUnsettledLegends}
                                    data={totalSettledUnsettledManifestCount}
                                    formatLabel={(value) => value}
                                    responsive={true}
                                />)}
                            {/*<BarChartGraph  legends={totalSettledUnsettledLegends} data={totalSettledUnsettledManifestCount}/>*/}
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <Card title={<GraphHeader
                            flightSettlement
                            title={"Not Settled"}
                            startDate={UnsettledStartDate}
                            endDate={UnsettledEndDate}
                            handleEvent={this.handleEvent}
                            handleApply={(e, p) => this.handleApply(e, p, appConstants.NOT_SETTLED)} />
                        }>
                            {this.noData(UnsettledResponse, <BarChartGraph
                                legends={unSettledLegends}
                                data={unSettledManifestStatusByAirline}
                                formatLabel={(value) => value}
                                responsive={false}
                            />)}
                            {/*<BarChartGraph  legends={unSettledLegends} data={unSettledManifestStatusByAirline}/>*/}
                        </Card>
                    </Col>

                </Row>
                <Row>
                    <Col md={12}>
                        <Card title={<GraphHeader
                            flightSettlement
                            title={"Above SLA (T+4)"}
                            startDate={aboveSLAStartDate}
                            endDate={aboveSLAEndDate}
                            handleEvent={this.handleEvent}
                            handleApply={(e, p) => this.handleApply(e, p, appConstants.ABOVE_SLA)} />}>
                            {this.noData(aboveSLAResponse,
                                <BarChartGraph
                                    legends={flaggedLegends}
                                    data={flaggedManifestStatusByAirline}
                                    formatLabel={(value) => value}
                                    responsive={false}

                                />)}
                            {/*<BarChartGraph  legends={flaggedLegends} data={flaggedManifestStatusByAirline}/>*/}

                        </Card>
                    </Col>
                </Row>

            </AppLayout>
        )
    }
}

function mapStateToProps(state) {
    const { requesting, user } = state;
    return {
        requesting,
        user
    };
}

export default connect(mapStateToProps)(FlightSettlement);

/* Written with love by Juliet June 2019 */