import { commonConstants } from '../_constants';
import { initialState } from '../_store';

export function airports(state=initialState.airports, action) {
    switch (action.type) {
        case commonConstants.AIRPORTS_SUCCESS:
            return action.airports;
            // return Object.assign({}, state, action.airlinesCarriers);
        default:
            return state
    }
}