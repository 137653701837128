import { commonConstants } from '../_constants';
import { initialState } from '../_store';

export function manifestList(state=initialState.manifestList, action) {
     switch (action.type) {
        case commonConstants.MANIFEST_LIST_SUCCESS:
            return Object.assign({}, state, action.manifestList);
        default:
          return state
      }
}