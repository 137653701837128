import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import moment from "moment";
import 'react-vertical-timeline-component/style.min.css';
import {
    FiGitPullRequest, FiStar,
    FiDownloadCloud, FiCheckCircle, FiInfo, FiFileText
} from "react-icons/fi";
import { appHelpers } from "../../../../_helpers";
import { appConstants } from "../../../../_constants";

// const SpriteStepNameStyle = { background: appConstants.BLUE_COLOR, color: '#ffffff' };
// const SpriteIconStepStyle = { background: 'rgb(255, 255, 255)', color: appConstants.BLUE_COLOR };
const ProcessErrorIconStepStyle = { background: appConstants.RED_COLOR, color: "#fff" };
const FileProcessIconStepNameStyle = { background: 'rgb(28, 102, 87)', color: '#ffffff' };
const FileProcessIconStepStyle = { background: 'rgb(255, 255, 255)', color: '#1c6657' };

export default ({ ProcessRetries, S3Download, ReadCsvRecords, ValidateRecords, GenerateManifest, SendManifestsToFmd, FileProcessSummary }) => {
    return (
        <div>
            <VerticalTimeline>
                <VerticalTimelineElement
                    date={(<h3 className={'stepName'} style={FileProcessIconStepNameStyle}>Process File</h3>)}
                    iconStyle={FileProcessIconStepNameStyle}
                    icon={<FiStar />}
                />

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(S3Download) ? moment(S3Download.Ended).fromNow() : moment().fromNow()}
                    iconStyle={(!S3Download) ? ProcessErrorIconStepStyle : FileProcessIconStepStyle}
                    icon={<FiDownloadCloud />}
                >
                    <h2 className="vertical-timeline-element-title">{'Download File from S3 Bucket'}</h2>

                    {
                        S3Download &&
                        <p className="vertical-timeline-element-paragraph">
                            File was downloaded successfully under <strong> {appHelpers.formatMinutesDescription(S3Download.Duration)}</strong>
                        </p>
                    }
                    {
                        !S3Download &&
                        <span>File not downloaded yet!</span>
                    }

                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(ReadCsvRecords) ? moment(ReadCsvRecords.Ended).fromNow() : moment().fromNow()}
                    iconStyle={(!ReadCsvRecords) ? ProcessErrorIconStepStyle : FileProcessIconStepStyle}
                    icon={<FiGitPullRequest />}
                >
                    <h2 className="vertical-timeline-element-subtitle">{'Read Records from CSV'}</h2>
                    {
                        ReadCsvRecords &&
                        <p className="vertical-timeline-element-paragraph">
                            It took <strong> {appHelpers.formatMinutesDescription(ReadCsvRecords.Duration)}</strong> to read manifests in the file.
                        </p>
                    }
                    {
                        !ReadCsvRecords &&
                        <span>Records not available yet!</span>
                    }
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(ValidateRecords) ? moment(ValidateRecords.Ended).fromNow() : moment().fromNow()}
                    iconStyle={(!ValidateRecords || ValidateRecords.Error) ? ProcessErrorIconStepStyle : FileProcessIconStepStyle}
                    icon={<FiCheckCircle />}
                >
                    <h2 className="vertical-timeline-element-title">{'Validate Records'}</h2>
                    <p className="vertical-timeline-element-paragraph">
                        {
                            (ValidateRecords && ValidateRecords.Error) &&
                            <span>{ValidateRecords.Error}</span>
                        }
                        {
                            (ValidateRecords && !ValidateRecords.Error) &&
                            <span>No error was found! </span>
                        }
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(GenerateManifest && GenerateManifest.Ended) ? moment(GenerateManifest.Ended).fromNow() : (GenerateManifest && GenerateManifest.Started) ? moment(GenerateManifest.Started).fromNow() : moment().fromNow()}
                    iconStyle={(!GenerateManifest || !GenerateManifest.Ended) ? ProcessErrorIconStepStyle : FileProcessIconStepStyle}
                    icon={<FiFileText />}
                >
                    <h2 className="vertical-timeline-element-title">{'Generate Manifest'}</h2>
                    <p className="vertical-timeline-element-paragraph">
                        {
                            !GenerateManifest &&
                            <span>Manifests not generated yet!</span>
                        }
                        {
                            (GenerateManifest && GenerateManifest.Ended) &&
                            <span>
                                {appHelpers.numberWithCommasOnly(GenerateManifest.TotalManifests)} manifest was generated under <strong>{appHelpers.formatMinutesDescription(GenerateManifest.Duration)}</strong>
                            </span>
                        }
                        {
                            (GenerateManifest && !GenerateManifest.Ended) &&
                            <span>Manifest generation not completed yet!</span>
                        }
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(SendManifestsToFmd && SendManifestsToFmd.Ended) ? moment(SendManifestsToFmd.Ended).fromNow() : (SendManifestsToFmd && SendManifestsToFmd.Started) ? moment(SendManifestsToFmd.Started).fromNow() : moment().fromNow()}
                    iconStyle={(!SendManifestsToFmd || !SendManifestsToFmd.Ended) ? ProcessErrorIconStepStyle : FileProcessIconStepStyle}
                    icon={<FiFileText />}
                >
                    <h2 className="vertical-timeline-element-title">{'FMD'}</h2>
                    <p className="vertical-timeline-element-paragraph">
                        {
                            !SendManifestsToFmd &&
                            <span>Manifest not sent to FMD yet!</span>
                        }
                        {
                            (SendManifestsToFmd && SendManifestsToFmd.Ended) &&
                            <span>
                                <strong>{appHelpers.numberWithCommasOnly(SendManifestsToFmd.SuccesfulManifests)}</strong> out of &nbsp;
                                <strong>{appHelpers.numberWithCommasOnly(SendManifestsToFmd.TotalManifests)}</strong> manifests was successfully created.
                                &nbsp;It took <strong>{appHelpers.formatMinutesDescription(SendManifestsToFmd.Duration)}</strong>
                            </span>
                        }
                        {
                            (SendManifestsToFmd && !SendManifestsToFmd.Ended) &&
                            <span>Manifest not sent to FMD yet!</span>
                        }
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(FileProcessSummary && FileProcessSummary.Ended) ? moment(FileProcessSummary.Ended).fromNow() : (FileProcessSummary && FileProcessSummary.Started) ? moment(FileProcessSummary.Started).fromNow() : moment().fromNow()}
                    iconStyle={(!FileProcessSummary || FileProcessSummary.Error) ? ProcessErrorIconStepStyle : FileProcessIconStepStyle}
                    icon={<FiInfo />}
                >
                    <h2 className="vertical-timeline-element-title">{'Number of Attempts'}</h2>
                    <p className="vertical-timeline-element-paragraph">
                        {ProcessRetries}
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(FileProcessSummary && FileProcessSummary.Ended) ? moment(FileProcessSummary.Ended).fromNow() : (FileProcessSummary && FileProcessSummary.Started) ? moment(FileProcessSummary.Started).fromNow() : moment().fromNow()}
                    iconStyle={(!FileProcessSummary || FileProcessSummary.Error) ? ProcessErrorIconStepStyle : FileProcessIconStepStyle}
                    icon={<FiInfo />}
                >
                    <h2 className="vertical-timeline-element-title">{'File Process Summary'}</h2>
                    <p className="vertical-timeline-element-paragraph">
                        {
                            !FileProcessSummary &&
                            <span>File not processed yet!</span>
                        }
                        {
                            (FileProcessSummary && FileProcessSummary.Error) &&
                            <span>
                                {FileProcessSummary.Error}
                            </span>
                        }
                        {
                            (FileProcessSummary && !FileProcessSummary.Error) &&
                            <span>File processed successfully. It took <strong>{appHelpers.formatMinutesDescription(FileProcessSummary.Duration)}</strong></span>
                        }
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    date={`That's all.`}
                    iconStyle={{ background: 'rgb(255, 255, 255)', color: '#2992d0' }}
                    icon={<FiStar />}
                />
            </VerticalTimeline>
        </div>
    );
};
