import { combineReducers } from 'redux';

import { alert } from './alert.reducer';
import { activity } from './activity.reducer';
import { requesting } from './requesting.reducer';
import { manifestList } from './manifestlist.reducer';
import { airlinesCarriers } from './airline_carrier.reducer';
import { airports } from './airport.reducer';
import {user} from './user.reducer';

const appReducers = combineReducers({
    alert,
    activity,
    requesting,
    manifestList,
    airlinesCarriers,
    user,
    airports
})

const rootReducer = (state, action) => {
    return appReducers(state, action);
};

export default rootReducer;
