import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import moment from "moment";
import 'react-vertical-timeline-component/style.min.css';
import { FiStar, FiHash, FiDisc } from "react-icons/fi";
import { appHelpers } from "../../../../_helpers";
import { appConstants } from "../../../../_constants";

// const SpriteStepNameStyle = { background: appConstants.BLUE_COLOR, color: '#ffffff' };
// const SpriteIconStepStyle = { background: 'rgb(255, 255, 255)', color: appConstants.BLUE_COLOR };
const ProcessErrorIconStepStyle = { background: appConstants.RED_COLOR, color: "#fff" };
const SpriteStepNameStyle = { background: 'rgb(28, 102, 87)', color: '#ffffff' };
const SpriteIconStepStyle = { background: 'rgb(255, 255, 255)', color: '#1c6657' };

export default ({ Sprite }) => {
    return (
        <div>
            <VerticalTimeline>
                <VerticalTimelineElement
                    date={(<h3 className={'stepName'} style={SpriteStepNameStyle}>Sprite</h3>)}
                    iconStyle={SpriteStepNameStyle}
                    icon={<FiStar />}
                />

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(Sprite) ? moment(Sprite.CreatedDate).fromNow() : moment().fromNow()}
                    iconStyle={SpriteIconStepStyle}
                    icon={<FiHash />}
                >
                    <h2 className="vertical-timeline-element-subtitle">{'Reference'}</h2>
                    <p className="vertical-timeline-element-paragraph">
                        {
                            (Sprite) &&
                            <span>
                                {Sprite.ReferenceNumber}
                            </span>
                        }
                        {
                            (!Sprite) &&
                            <span className={'text-muted'}>
                                No reference number yet.
                            </span>
                        }
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(Sprite) ? moment(Sprite.CreatedDate).fromNow() : moment().fromNow()}
                    iconStyle={(!Sprite) ? ProcessErrorIconStepStyle : SpriteIconStepStyle}
                    icon={<FiDisc />}
                >
                    <h2 className="vertical-timeline-element-subtitle">{'Summary'}</h2>
                    {
                        !Sprite &&
                        <p className="vertical-timeline-element-paragraph">
                            Invoice not sent to Sprite yet!
                        </p>
                    }
                    {
                        Sprite &&
                        <p className="vertical-timeline-element-paragraph">
                            It took <strong>{appHelpers.formatMinutesDescription(Sprite.Duration)}</strong> to receive response from Sprite.
                        </p>
                    }
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    date={`That's all.`}
                    iconStyle={{ background: 'rgb(255, 255, 255)', color: '#2992d0' }}
                    icon={<FiStar />}
                />
            </VerticalTimeline>
        </div>
    );
};
