import moment from "moment/moment";
import { appConstants } from "../_constants";
import axios from "axios";
import { store } from '../_store';
import {history} from '../_helpers';

export const appHelpers = {
    formatDate: d => {
        return moment(d.split("T")[0]).format("MMMM DD, YYYY");
    },
    formatDateSpecific: (d, format) => {
        return moment(d).format(format || "MMMM DD, YYYY");
    },
    formatMonth: (d) => {
        return moment(d).format("MMMM YYYY");
    },
    getActiveUser: function () {
        let __usr__ = localStorage.getItem(appConstants.APP_USER_KEY);
        return __usr__ ? JSON.parse(__usr__) : false;
    },
    getRequest: function (url) {
        const { user } = store.getState();
        let combinedHeader = { "Content-Type": "application/json", AppName: appConstants.APP_ID };
        // combinedHeader.Authorization = `Bearer vbZf9ApP1_HuXLC8PjmtdK0VGmJNhAp0vL2GpyfVh3PgpjYSBQhQkpEwS1xDsjFxRphIY_G6S8SD4NgpF10ZRsy2ukNIokcmuFSiDqZIEwwvty2JG09gdq-jluOhaTcVOUW5DQU80UoX8sZ2Ouu50eXMOp9zil9cgp1YaSdyxZVQVdnePB6FM_Ye3WzwBMOn9VEnvK5BHB9OnzsIczaz6wwhSZmzGAbGQFVwnpRpvgPiyp-kEspwxxnd8AqLWPX5S2OupzumsvfCcRjeCckq9TJe0vS8t_1ByXbfxBMxBcsJBm2Jb8BYfZXx_a_yoCZSL_J4ueshRWaJIKQUBhMItUjL9o3BRQ7NfHsnh4Sadhbhdqq3EjC7Yc-VA-guqK9VwSx72GSg6J66-g91r6vk1kDCLarCXFrAYWoW5eAIecpRKNGEP7RglhZNxPM9PgbCggQpYDg9naFJTq-QNoVvLTvsk3_g1JRqpiGkppA8guR0IeS_tAoljL1IFD8HDtXVPVIFFbf9LXZzq82ZMqDyMtpS-a9AhXV3rxnBK5OYXaUypJq3xah9B46kSRoik9Xqj9o3ig`
        if (user) {
           combinedHeader.Authorization = `Bearer ${user.access_token}`;
        }
        let config = { headers: combinedHeader };
        return axios
            .get(url, config)
            .then(function (res) {
                return appHelpers.promiseResponse(res.data);
            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    let msg = error.response.data;
                    if(error.response.status === 500){
                        msg = "Oops, something went wrong";
                    }else if(error.response.status === 404){
                        msg = "Resource not found";
                    }else if(error.response.status === 401){
                        msg = appConstants.APP_USER_SESSION_EXPIRED_MESSAGE;
                        setTimeout(() => {
                            history.push('/login');
                        }, 3000)
                    }
                    return appHelpers.promiseResponse(msg, appConstants.ERROR_RESPONSE);
                    // return {statTs: appConstants.REQUEST_FAILURE, data: error.response.data};

                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    // console.log(error.request);
                    // return {status: appConstants.REQUEST_FAILURE, data: error.request};
                    const errorMessage = error.request.status === 0 ? appConstants.APP_INTERNET_CONNECTION_MESSAGE : error.request;

                    return appHelpers.promiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    // console.log('Error', error.message);
                    // return {status: appConstants.REQUEST_FAILURE, data: error.message};
                    return appHelpers.promiseResponse(error.message, appConstants.ERROR_RESPONSE);
                }
            });
    },
    postRequest: function (url, payload) {
        const { user } = store.getState();
        let combinedHeader = { "Content-Type": "application/json", AppName: appConstants.APP_ID };

        if (user) {
            // combinedHeader.Authorization = `Bearer ${user.access_token}`;
            combinedHeader.Authorization = `Bearer ${user.access_token}`
        }
        let config = { headers: combinedHeader };

        return axios.post(url, payload, config)
            .then(res => {
                return appHelpers.promiseResponse(res.data);
            }).catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    let msg = error.response.data;
                    if(error.response.status === 500){
                        msg = "Oops, something went wrong";
                    }else if(error.response.status === 404){
                        msg = "Resource not found";
                    }else if(error.response.status === 401){
                        msg = appConstants.APP_USER_SESSION_EXPIRED_MESSAGE;
                        setTimeout(() => {
                            history.push('/login');
                        }, 3000)
                    }
                    return appHelpers.promiseResponse(msg, appConstants.ERROR_RESPONSE);
                    // return {statTs: appConstants.REQUEST_FAILURE, data: error.response.data};

                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    // console.log(error.request);
                    // return {status: appConstants.REQUEST_FAILURE, data: error.request};
                    const errorMessage = error.request.status === 0 ? appConstants.APP_INTERNET_CONNECTION_MESSAGE : error.request;

                    return appHelpers.promiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    // console.log('Error', error.message);
                    // return {status: appConstants.REQUEST_FAILURE, data: error.message};
                    return appHelpers.promiseResponse(error.message, appConstants.ERROR_RESPONSE);
                }
            });
    },
    formatPromiseResponse: function (res, resType) {
        let responseType = (resType === undefined) ? appConstants.SUCCESS_RESPONSE : resType;
        return { status: responseType, response: res };
    },
    promiseResponse: function (res, resType) {
        let responseType =
            resType === undefined ? appConstants.SUCCESS_RESPONSE : resType;
        return { status: responseType, response: res };
    },
    interpretErrorResponse(error) {
        let errorMessage = "";
        if (error.response === undefined) {
            errorMessage = "Please check your internet connectivity!";
        } else {
            errorMessage = error.response.data
                ? error.response.data
                : "Unable to handle request";
        }
        if (typeof errorMessage === "string") {
            return errorMessage;
        } else {
            return "Something went wrong!";
        }
    },
    numberWithCommas: x => {
        x = x.toFixed(2);
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    },
    numberWithCommasOnly: x => {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    },
    interpretRole: function(activeUser) {
        if(activeUser.userRole === appConstants.ROLES.ADMIN){
            if(activeUser.AirlineCode === null){
                return appConstants.ROLES.ADMIN;
            }else{
                return appConstants.ROLES.AIRLINE;
            }
        }
        else if(activeUser.userRole === appConstants.ROLES.STAFF){
            if(activeUser.AirlineCode !== null){
                return appConstants.ROLES.AIRLINE;
            }else if(activeUser.AirportCode !== null){
                return appConstants.ROLES.AIRPORT_STAFF;
            }else{
                return appConstants.ROLES.STAFF;
            }
        }else{
            return activeUser.userRole;
        }
    },
    checkPageAuthorization: function (role, page) {
        switch (role){
            case appConstants.ROLES.SUPER_ADMIN:
                return 1;
            case appConstants.ROLES.ADMIN:
                return appConstants.ROLES_PAGES.ADMIN.indexOf(page);
            case appConstants.ROLES.AIRLINE:
                return appConstants.ROLES_PAGES.AIRLINE.indexOf(page);
            case appConstants.ROLES.STAFF:
                return appConstants.ROLES_PAGES.STAFF.indexOf(page);
            case appConstants.ROLES.AIRPORT_MANAGER:
                return appConstants.ROLES_PAGES.AIRPORT_MANAGER.indexOf(page);
            default:
                return -1;
        }
    },
    isAuthorised: function() {
        const { user } = store.getState();
        const { AppRole } =  user;
        const  queryString = window.location.href.split('/');
        const page_url = queryString.slice(-1)[0];
        if(AppRole){
            return (this.checkPageAuthorization(AppRole, page_url) > -1)
        }else{
            return false;
        }
    },
    routeExist: function() {
        const  queryString = window.location.href.split('/');
        const page_url = queryString.slice(-1)[0];
        return appConstants.ALL_PAGES.indexOf(page_url);
    },
    formatMinutesDescription: function (minutes) {
        let description;
        if(minutes < 1){
            const seconds = Math.ceil(minutes * 60);
            description = (seconds === 1) ? `${seconds} second` :  `${seconds} seconds`;
        } else if(minutes > 59){
            const hours = Math.ceil(minutes / 60);
            description = (hours === 1) ? `${hours} hour` :  `${hours} hours`;
        }else{
            minutes = Math.ceil(minutes);
            description = (minutes === 1) ? `${minutes} minute` :  `${minutes} minutes`;
        }
        return description;
    },

    // show label different from corresponding x-axis label
    formatToolTipLabel : (initialValue, data, initialLabelkey, finalLabelKey) => {
        let labelObject = data.find((item) => item[initialLabelkey] === initialValue)
        return labelObject[finalLabelKey]
    }
};
