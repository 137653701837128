import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import moment from "moment";
import 'react-vertical-timeline-component/style.min.css';
import { FiStar, FiDollarSign, FiHash, FiDisc } from "react-icons/fi";
import { appHelpers } from "../../../../_helpers";
import { appConstants } from "../../../../_constants";

// const InvoicingIconStepNameStyle = { background: appConstants.ORANGE_COLOR, color: '#ffffff' };
// const InvoicingIconStepStyle = { background: 'rgb(255, 255, 255)', color: appConstants.ORANGE_COLOR };
const ProcessErrorIconStepStyle = { background: appConstants.RED_COLOR, color: "#fff" };
const InvoicingIconStepNameStyle = { background: 'rgb(28, 102, 87)', color: '#ffffff' };
const InvoicingIconStepStyle = { background: 'rgb(255, 255, 255)', color: '#1c6657' };

export default ({ Invoicing, CurrencyName }) => {
    return (
        <div>
            <VerticalTimeline>
                <VerticalTimelineElement
                    date={(<h3 className={'stepName'} style={InvoicingIconStepNameStyle}>Invoicing</h3>)}
                    iconStyle={InvoicingIconStepNameStyle}
                    icon={<FiStar />}
                />

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(Invoicing) ? moment(Invoicing.CreatedDate).fromNow() : moment().fromNow()}
                    iconStyle={(!Invoicing) ? ProcessErrorIconStepStyle : InvoicingIconStepStyle}
                    icon={<FiHash />}
                >
                    <h2 className="vertical-timeline-element-subtitle">{'Reference'}</h2>
                    <p className="vertical-timeline-element-paragraph">
                        {
                            (Invoicing) &&
                            <span>
                                {Invoicing.ReferenceNumber}
                            </span>
                        }
                        {
                            (!Invoicing) &&
                            <span className={'text-muted'}>
                                No Ref. number yet.
                            </span>
                        }
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(Invoicing) ? moment(Invoicing.CreatedDate).fromNow() : moment().fromNow()}
                    iconStyle={(!Invoicing) ? ProcessErrorIconStepStyle : InvoicingIconStepStyle}
                    icon={<FiDollarSign />}
                >
                    <h2 className="vertical-timeline-element-subtitle">{'Amount'}</h2>
                    <p className="vertical-timeline-element-paragraph">
                        {
                            (Invoicing) &&
                            <span>
                                {appHelpers.numberWithCommasOnly(Invoicing.Amount)} ({CurrencyName})
                            </span>
                        }
                        {
                            (!Invoicing) &&
                            <span className={'text-muted'}>
                                Amount not calculated yet!
                            </span>
                        }

                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(Invoicing) ? moment(Invoicing.CreatedDate).fromNow() : moment().fromNow()}
                    iconStyle={(!Invoicing) ? ProcessErrorIconStepStyle : InvoicingIconStepStyle}
                    icon={<FiDisc />}
                >
                    <h2 className="vertical-timeline-element-subtitle">{'Description'}</h2>
                    <p className="vertical-timeline-element-paragraph">
                        {
                            (Invoicing && Invoicing.Description) &&
                            <span>
                                {Invoicing.Description}
                            </span>
                        }
                        {
                            (!Invoicing || !Invoicing.Description) &&
                            <span className={'text-muted'}>
                                No description.
                            </span>
                        }
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(Invoicing) ? moment(Invoicing.CreatedDate).fromNow() : moment().fromNow()}
                    iconStyle={(!Invoicing) ? ProcessErrorIconStepStyle : InvoicingIconStepStyle}
                    icon={<FiDisc />}
                >
                    <h2 className="vertical-timeline-element-subtitle">{'Number of Attempts'}</h2>
                    <p className="vertical-timeline-element-paragraph">
                        {
                            (Invoicing) &&
                            <strong>{appHelpers.numberWithCommasOnly(Invoicing.Attempts)}</strong>
                        }
                        {
                            (!Invoicing) &&
                            <strong>
                                0
                            </strong>
                        }
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(Invoicing) ? moment(Invoicing.CreatedDate).fromNow() : moment().fromNow()}
                    iconStyle={(!Invoicing) ? ProcessErrorIconStepStyle : InvoicingIconStepStyle}
                    icon={<FiDisc />}
                >
                    <h2 className="vertical-timeline-element-subtitle">{'Summary'}</h2>
                    {
                        Invoicing &&
                        <p className="vertical-timeline-element-paragraph">
                            It took <strong>{appHelpers.formatMinutesDescription(Invoicing.Duration)}</strong> to invoice manifest aggregate.
                        </p>
                    }

                </VerticalTimelineElement>

                <VerticalTimelineElement
                    date={`That's all.`}
                    iconStyle={{ background: 'rgb(255, 255, 255)', color: '#2992d0' }}
                    icon={<FiStar />}
                />
            </VerticalTimeline>
        </div>
    );
};
