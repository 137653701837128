import React, { Component } from 'react';
import AppLayout from "../layout/index";

// components
import { Row, Col } from 'react-bootstrap';
import Card from '../../components/card/index';
import { GraphHeader } from '../../components/graph/graphHeader';
import { PrimaryTab } from '../../components/tabs';

// icons

//libraries
import moment from 'moment';
import { appConstants } from '../../_constants';
import { connect } from 'react-redux';
import { commonService } from '../../_services';
import { graphHelpers, appHelpers } from '../../_helpers';
import { alertActions } from '../../_actions';
import BarChartGraph from '../../components/graph/BarGraph';
import PieChartGraph from '../../components/graph/PieGraph';

const pscMenu = [
    {
        name: "Avicollect vs PSC",
        link: "/avicollect-psc"
    },
    {
        name: "PDM Data vs PSC Data",
        link: "/pdm-psc"
    },
    {
        name: "Flight Date vs Settlement Date",
        link: "/flight-settlement"
    }
];

const locationLegends = [
    {
        name: "Sum of PDM Billable",
        color: appConstants.BLUE_COLOR,
        colorId: 'colorPDM',
        dataKey: "PDM"
    },
    {
        name: "Sum of PSC Billable Passengers",
        color: appConstants.ORANGE_COLOR,
        colorId: 'colorPSC',
        dataKey: "PSC"
    },
]


const varianceLegends = [
    {
        name: "Variance",
        color: appConstants.RED_COLOR,
        colorId: 'colorVariance',
        dataKey: "Variance"
    }
]

class PDMPSC extends Component {
    constructor(props) {
        super(props)
        this.state = {
            departureStartDate: moment().subtract(1, 'month'),
            departureEndDate: moment(),
            departureVarianceStartDate: moment().subtract(1, 'month'),
            departureVarianceEndDate: moment(),
            locationPassengerData: false,
            airlinePassengerVarianceData: false,
            locationPassengerSummaryData: false,
            airlinePassengerVarianceSummaryData: false
        }
    }

    componentDidMount() {
        let { user } = this.props;
        if (user) {
            this.initializePageData()
        }
    }


    initializePageData = () => {
        let { departureStartDate, departureEndDate } = this.state;
        const { dispatch } = this.props;

        departureStartDate = departureStartDate.format("YYYY-MM-DDTHH:mm:ss");
        departureEndDate = departureEndDate.format("YYYY-MM-DDTHH:mm:ss");
        const payload = {
            StartDate: departureStartDate,
            EndDate: departureEndDate,
        }

        Promise.all([
            commonService.fetchBillablePassengersSummary(payload),
            commonService.fetchBillablePassengersVariance(payload)
        ]).then(response => {
            const locationPassengerData = response[0];
            const airlinePassengerVarianceData = response[1];

            // console.log({ locationPassengerData })
            // console.log({ airlinePassengerVarianceData })

            const locationPassengerChartData = graphHelpers.addXAxisNames(locationPassengerData.response.Result, "AirportCode");
            const locationPassengerSummaryData = graphHelpers.generateSummaryData(locationPassengerData.response.Summary);

            const airlinePassengerVarianceChartData = graphHelpers.addXAxisNames(airlinePassengerVarianceData.response.Result, "AirlineName");
            const airlinePassengerVarianceSummaryData = graphHelpers.generateSummaryData(airlinePassengerVarianceData.response.Summary);
            // console.log({ locationPassengerChartData })
            // console.log({ airlinePassengerVarianceChartData })

            this.setState({
                locationPassengerChartData, airlinePassengerVarianceChartData,
                locationPassengerSummaryData, airlinePassengerVarianceSummaryData
            }, () => dispatch(alertActions.stopRequest()))
        })
        dispatch(alertActions.startRequest());

    }


    // handle apply click on date picker
    handleApply = async (event, picker, type) => {

        let StartDate = picker.startDate.format("YYYY-MM-DDTHH:mm:ss");
        let EndDate = picker.endDate.format("YYYY-MM-DDTHH:mm:ss");

        if (StartDate === EndDate) {
            StartDate = picker.startDate.format("YYYY-MM-DDT00:00:00");
            EndDate = picker.endDate.format("YYYY-MM-DDT23:59:59");
        }
        const payload = { StartDate, EndDate };
        try {
            let response; // = await commonService.searchManifestProcessDuration(payload);
            switch (type) {
                case appConstants.LOCATION_PAX_DIFFERENTIAL:
                    this.setState({
                        departureStartDate: picker.startDate,
                        departureEndDate: picker.endDate
                    }, () => {
                        response = this.searchLocationPaxDifferential(payload);
                    });
                    break;
                case appConstants.AIRLINE_PAX_VARIANCE:
                    this.setState({
                        departureVarianceStartDate: picker.startDate,
                        departureVarianceEndDate: picker.endDate
                    }, () => {
                        response = this.searchAirlinePaxVariance(payload);
                    });
                    break;
                default:
                    response = {}
            }

            return response;
        } catch (err) {
            console.log({ err });
        }
    };


    searchLocationPaxDifferential = async (payload) => {
        const { dispatch } = this.props;
        dispatch(alertActions.startRequest());
        try {
            const response = await commonService.fetchBillablePassengersSummary(payload);
            if (response.status === appConstants.SUCCESS_RESPONSE) {
                const locationPassengerChartData = graphHelpers.addXAxisNames(response.response.Result, "AirportCode");
                const locationPassengerSummaryData = graphHelpers.generateSummaryData(response.response.Summary);

                // console.log({ locationPassengerChartData })
                this.setState({ locationPassengerChartData, locationPassengerSummaryData })
            } else if (response.status === appConstants.ERROR_RESPONSE) {
                dispatch(alertActions.error(response.response));
                // console.log(response.response)
            }
            dispatch(alertActions.stopRequest());

        } catch (err) {
            dispatch(alertActions.stopRequest());
            dispatch(alertActions.error(err))
        }

    }

    searchAirlinePaxVariance = async (payload) => {
        const { dispatch } = this.props;
        dispatch(alertActions.startRequest());
        try {
            const response = await commonService.fetchBillablePassengersVariance(payload);
            if (response.status === appConstants.SUCCESS_RESPONSE) {
                const airlinePassengerVarianceChartData =
                    graphHelpers.addXAxisNames(response.response.Result, "AirlineName");
                const airlinePassengerVarianceSummaryData =
                    graphHelpers.generateSummaryData(response.response.Summary);

                // console.log({ airlinePassengerVarianceChartData })
                this.setState({
                    airlinePassengerVarianceChartData,
                    airlinePassengerVarianceSummaryData
                })
            } else if (response.status === appConstants.ERROR_RESPONSE) {
                dispatch(alertActions.error(response.response));
                // console.log(response.response)
            }
            dispatch(alertActions.stopRequest());

        } catch (err) {
            dispatch(alertActions.stopRequest());
            dispatch(alertActions.error(err))
        }
    }


    render() {
        const { departureStartDate, departureEndDate, departureVarianceStartDate,
            departureVarianceEndDate, locationPassengerChartData,
            airlinePassengerVarianceChartData, locationPassengerSummaryData
        } = this.state
        return (
            <AppLayout title={"Passenger Service Charge"}>
                <Row>
                    <Col md={12}>
                        <PrimaryTab menus={pscMenu} activeMenuIndex={2} />
                    </Col>
                </Row>

                {/* PDM DATA VS PSC DATA (BILLABLE PAX) */}
                <Row>
                    <Col md={9}>
                        <Card title={<GraphHeader title={"Location Wise Passenger Differential"}
                            startDate={departureStartDate}
                            endDate={departureEndDate}
                            handleEvent={this.handleEvent}
                            handleApply={(e, p) => this.handleApply(e, p, appConstants.LOCATION_PAX_DIFFERENTIAL)} />}>
                            <BarChartGraph
                                legends={locationLegends}
                                data={locationPassengerChartData}
                                formatLabel={(value) => appHelpers.formatToolTipLabel(value, locationPassengerChartData, "AirportCode", "AirportName")}

                            />
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card title={"Location Wise Passenger Differential"}>
                            {locationPassengerSummaryData &&
                                <PieChartGraph data={locationPassengerSummaryData} />}
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <Card title={<GraphHeader title={"Airline Passenger Variance"}
                            startDate={departureVarianceStartDate}
                            endDate={departureVarianceEndDate}
                            handleEvent={this.handleEvent}
                            handleApply={(e, p) => this.handleApply(e, p, appConstants.AIRLINE_PAX_VARIANCE)} />}>
                            <BarChartGraph
                                legends={varianceLegends}
                                data={airlinePassengerVarianceChartData}
                                formatLabel={(value) => value}
                            />
                        </Card>
                    </Col>
                    {/* <Col md={3}>
                        <Card title={"Airline Passenger Variance"}>
                        </Card>
                    </Col> */}
                </Row>


            </AppLayout>
        )
    }
}

function mapStateToProps(state) {
    const { requesting, user } = state;
    return {
        requesting,
        user
    };
}

export default connect(mapStateToProps)(PDMPSC)