import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import moment from "moment";
import 'react-vertical-timeline-component/style.min.css';
import { FiStar, FiDownload, FiSave, FiUploadCloud, FiEdit } from "react-icons/fi";
import { appHelpers } from "../../../../_helpers";
import { appConstants } from "../../../../_constants";

// const SpriteStepNameStyle = { background: appConstants.BLUE_COLOR, color: '#ffffff' };
// const SpriteIconStepStyle = { background: 'rgb(255, 255, 255)', color: appConstants.BLUE_COLOR };
const ProcessErrorIconStepStyle = { background: appConstants.RED_COLOR, color: "#fff" };
const FilePullIconStepNameStyle = { background: 'rgb(28, 102, 87)', color: '#ffffff' };
const FilePullIconStepStyle = { background: 'rgb(255, 255, 255)', color: '#1c6657' };

export default ({ airline, SourceFilePull, ConvertToCsv, S3Upload, SaveToDB }) => {
    return (
        <div>
            <VerticalTimeline>
                <VerticalTimelineElement
                    date={(<h3 className={'stepName'} style={FilePullIconStepNameStyle}>Pull File</h3>)}
                    iconStyle={FilePullIconStepNameStyle}
                    icon={<FiStar />}
                />

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(SourceFilePull) ? moment(SourceFilePull.CreatedDate).fromNow() : moment().fromNow()}
                    icon={<FiDownload />}
                    iconStyle={(!SourceFilePull) ? ProcessErrorIconStepStyle : FilePullIconStepStyle}
                >
                    <h2 className="vertical-timeline-element-title">{'Pull File'}</h2>
                    <p className="vertical-timeline-element-paragraph">
                        {
                            SourceFilePull &&
                            <span>
                                {airline} file was pulled via <strong>{SourceFilePull.PullMode}</strong> pull mode under
                                     <strong> {appHelpers.formatMinutesDescription(SourceFilePull.Duration)}</strong>
                            </span>
                        }
                        {
                            !SourceFilePull &&
                            <span>
                                File Not Pulled Yet!
                                </span>
                        }
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(ConvertToCsv) ? moment(ConvertToCsv.Ended).fromNow() : moment().fromNow()}
                    iconStyle={(!ConvertToCsv) ? ProcessErrorIconStepStyle : FilePullIconStepStyle}
                    icon={<FiEdit />}
                >
                    <h2 className="vertical-timeline-element-title">{'Convert File To CSV'}</h2>
                    {
                        ConvertToCsv &&
                        <p className="vertical-timeline-element-paragraph">
                            It took <strong>{appHelpers.formatMinutesDescription(ConvertToCsv.Duration)}</strong> to convert the file to CSV
                        </p>
                    }
                    {
                        !ConvertToCsv &&
                        <span>File not converted yet!</span>
                    }
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(S3Upload) ? moment(S3Upload.Ended).fromNow() : moment().fromNow()}
                    iconStyle={(!S3Upload) ? ProcessErrorIconStepStyle : FilePullIconStepStyle}
                    icon={<FiUploadCloud />}
                >
                    <h2 className="vertical-timeline-element-title">{'Upload File to S3 Bucket'}</h2>
                    {
                        S3Upload &&
                        <p className="vertical-timeline-element-paragraph">
                            It took <strong> {appHelpers.formatMinutesDescription(S3Upload.Duration)}</strong> to upload the file to S3 bucket. The file can be accessed <a
                                href={S3Upload.FileUrl}>here</a>
                        </p>
                    }
                    {
                        !S3Upload &&
                        <span>File not uploaded yet!</span>
                    }

                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(SaveToDB) ? moment(SaveToDB.Ended).fromNow() : moment().fromNow()}
                    iconStyle={(!SaveToDB) ? ProcessErrorIconStepStyle : FilePullIconStepStyle}
                    icon={<FiSave />}
                >
                    <h2 className="vertical-timeline-element-title">{'Save File to Database'}</h2>
                    {
                        SaveToDB &&
                        <p className="vertical-timeline-element-paragraph">
                            It took <strong> {appHelpers.formatMinutesDescription(SaveToDB.Duration)}</strong> to save file record in the database.
                        </p>
                    }
                    {
                        !SaveToDB &&
                        <span>File not saved yet!</span>

                    }
                </VerticalTimelineElement>



                <VerticalTimelineElement
                    date={`That's all.`}
                    iconStyle={{ background: 'rgb(255, 255, 255)', color: '#2992d0' }}
                    icon={<FiStar />}
                />
            </VerticalTimeline>
        </div>
    );
};
