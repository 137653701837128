import React from 'react';
import DatePicker from "../../components/datepicker";
import { Row, Col
} from "react-bootstrap";

const ManifestProcessHeader = ({ title, startDate, endDate, currentTime, handleEvent, handleApply }) => {
    return (
        <Row>
            <Col lg={4}>
                <h5>{title}</h5>
            </Col>
            <Col lg={{ span: 3, offset: 5 }}>
                <DatePicker
                    currentTime={currentTime}
                    startDate={startDate}
                    endDate={endDate}
                    onEvent={handleEvent}
                    onApply={handleApply}
                />
            </Col>
        </Row>
    );
};

export default ManifestProcessHeader;
