import React from 'react';
import "./index.css"

export const PrimaryTab = ({menus, activeMenuIndex}) => {
    return (
        <ul className={'tabs'}>
            {
                menus.map((item, key) => {
                    return <li data-key={key} key={key} className={(activeMenuIndex - 1 === key) ? 'active' : ''}>
                        <a href={item.link}>
                            {item.name}
                        </a>
                    </li>
                })
            }
        </ul>
    );
};
