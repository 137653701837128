import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import Card from "../../components/card/index";
import AppLayout from "../layout/index";
import AirlineDailyFlightTrackerTable from "./airlineDailyFlightTrackerTable";
import moment from "moment";
import { store } from "../../_store";
import { commonActions, alertActions } from "../../_actions";
import { appHelpers } from "../../_helpers";
import { appConstants } from "../../_constants";
import { commonService } from "../../_services";
import { connect } from "react-redux";
import { EmptyRecord } from "../../components/pageutility";
import { PrimaryTab, SecondaryTab } from "../../components/tabs";
// import { withRouter } from 'react-router-dom'
import { FiNavigation, FiHome } from "react-icons/fi";
import SearchPanel from "./searchPanel";
import AirlineDailyPaxTrackerTable from "./airlinePaxTrackerTable";

const routes = [
  {
    Id: 1,
    Name: "International"
  },
  {
    Id: 2,
    Name: "Domestic"
  }
];

const menus = [
  {
    name: "International",
    link: "/international-flag-metrics",
    icon: <FiNavigation style={{ fontSize: "20px" }} />
  },
  {
    name: "Domestic",
    link: "/domestic-flag-metrics",
    icon: <FiHome style={{ fontSize: "20px" }} />
  }
];

const statuses = [
  {
    Id: false,
    Name: "Issues Exist"
  },
  {
    Id: true,
    Name: "Good"
  }
];

const avicollectMenu = [
  {
    name: "Manifest Process",
    link: "/"
  },
  {
    name: "Manifest List",
    link: "/manifestList"
  },
  {
    name: "Manifest Aggregate",
    link: "/manifestAggregate"
  },
  {
    name: "Flag Metrics",
    link: "/international-flag-metrics"
  },
  {
    name: "TSC Value Tracker",
    link: "/tsc-value-tracker"
  }
];

class DomesticFlagMetrics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routes: routes,
      statuses: statuses,
      startDate: moment(),
      paxStartDate: moment(),
      // paxEndDate: moment(),
      // endDate: moment(),
      selectedRoutes: 1,
      airlines: [],
      paxRecords: null,
      flightRecords: null,
      pageSize: 10,
      flightCurrentPage: 1,
      paxCurrentPage: 1,
      flightTotalCount: 0,
      paxTotalCount: 0,
      activeSubTab: 2,
      selectedStatus: null,
      selectedAirline: null,
      flightTrackerType: 2,
      paxTrackerType: 1
    };
  }

  componentDidMount() {
    const { user } = this.props;
    const reduxState = store.getState();
    let { airlinesCarriers } = reduxState;
    if (user) {
      if (airlinesCarriers) {
        this.setState({ airlines: airlinesCarriers.Results });
      } else {
        this.fetchAirlines();
      }
      this.handleFlightSearch(1, "initial");
      this.handlePaxSearch(1, "initial");
    }
  }

  componentWillReceiveProps() {
    let { airlinesCarriers } = store.getState();
    if (airlinesCarriers) {
      this.setState({ airlines: airlinesCarriers.Results });
    }
  }

  fetchAirlines = () => {
    const { dispatch } = this.props;
    dispatch(commonActions.getAirlinesCarriers())
      .then(() => {
        let { airlinesCarriers } = this.props;
        this.setState({ airlines: airlinesCarriers.Results });
      })
      .catch(error => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        dispatch(alertActions.error(errorMessage));
      });
  };

  // handleAirlineChange = (selected) => {
  //     this.setState({ selectedAirline: (selected) ? selected.Id : "" })
  // };

  handleStatusChange = selected => {
    this.setState({ selectedStatus: selected ? selected.Id : "" });
  };

  // handle apply click on date picker
  handleApply = async (event, picker, type) => {
    try {
      switch (type) {
        case appConstants.AIRLINE_DAILY_FLIGHT_TRACKER:
          this.setState({
            startDate: picker.startDate,
            // endDate: picker.endDate
          });
          break;
        case appConstants.AIRLINE_DAILY_PAX_TRACKER:
          this.setState({
            paxStartDate: picker.startDate,
            // paxEndDate: picker.endDate
          });
          break;
        default:
      }
    } catch (err) {}
  };

  //for Airline daily flight count tracker table
  handleFlightSearch = (pageNumber, initial) => {
    let { startDate, selectedStatus, flightTrackerType, pageSize } = this.state;

    let fromIndex;

    let currentPage;

    if (pageNumber === undefined) {
      fromIndex = 0;
      pageNumber = 1;
    } else {
      fromIndex = pageSize * (pageNumber - 1);
    }

    if (initial) {
      currentPage = 1;
      fromIndex = 0;
    } else {
      currentPage = pageNumber;
    }

    const { dispatch } = this.props;
    let parameter = {
      DateTime: moment(startDate).format("YYYY-MM-DD"),
      TrackerType: flightTrackerType,
      Status: selectedStatus
    };

    // if (selectedStatus) {
    //     parameter.Status = selectedStatus;
    // }

    let payload = {
      PageSize: pageSize,
      From: fromIndex,
      Parameter: parameter
    };

    dispatch(alertActions.startRequest());

    commonService.getAirlineFlaggingStatus(payload).then(
      res => {
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          const response = res.response;

          this.setState({
            flightCurrentPage: currentPage,
            flightTotalCount: response.TotalCount,
            flightRecords: response.Results
          });
          dispatch(alertActions.stopRequest());
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(alertActions.error(res.response));
          dispatch(alertActions.stopRequest());
        }
      },
      error => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        dispatch(alertActions.stopRequest());
        dispatch(alertActions.error(errorMessage));
      }
    );
  };

  //for Airline daily Passenger count tracker table
  handlePaxSearch = (pageNumber = 1) => {
    let { paxStartDate, selectedStatus, paxTrackerType, pageSize } = this.state;

    const { dispatch } = this.props;
    let parameter = {
      DateTime: moment(paxStartDate).format("YYYY-MM-DD"),
      TrackerType: paxTrackerType,
      Status: selectedStatus
    };

    if (selectedStatus) {
      parameter.Status = selectedStatus;
    }

    let from = (pageNumber - 1) * pageSize;

    let payload = {
      PageSize: pageSize,
      From: from,
      Parameter: parameter
    };
    // }

    dispatch(alertActions.startRequest());

    commonService.getAirlineFlaggingStatus(payload).then(
      res => {
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          const response = res.response;
          this.setState({
            paxCurrentPage: pageNumber,
            paxTotalCount: response.TotalCount,
            paxRecords: response.Results
          });
          dispatch(alertActions.stopRequest());
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(alertActions.error(res.response));
          dispatch(alertActions.stopRequest());
        }
      },
      error => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        dispatch(alertActions.stopRequest());
        dispatch(alertActions.error(errorMessage));
      }
    );
  };

  changeSubTab = (e, activeSubTab) => {
    e.preventDefault();
    this.setState({ activeSubTab: activeSubTab + 1 }, () => {
      this.changeView();
    });
  };

  changeView = () => {
    const { history } = this.props;
    const { activeSubTab } = this.state;

    if (activeSubTab === 1) {
      history.push("/international-flag-metrics");
    } else if (activeSubTab === 2) {
      history.push("/domestic-flag-metrics");
    }
  };

  render() {
    const {
      statuses,
      startDate,
      paxStartDate,
      // endDate,
      flightTotalCount,
      flightCurrentPage,
      paxCurrentPage,
      pageSize,
      flightRecords,
      activeSubTab,
      flightTrackerType,
      paxTrackerType,
      paxRecords,
      paxTotalCount,
      // paxEndDate
    } = this.state;

    return (
      <AppLayout title={"Avicollect"} activeMenuIndex={2}>
        <Row>
          <Col md={12}>
            <PrimaryTab menus={avicollectMenu} activeMenuIndex={2} />
          </Col>
        </Row>
        <Row>
          <Col md={6} className={"manifest-list"}>
            <SecondaryTab
              menus={menus}
              activeMenuIndex={activeSubTab}
              handleClick={this.changeSubTab}
            />
          </Col>
        </Row>

        <Row>
          <Col md={12} className={"manifest-list"}>
            <Card title={"Airline Daily Flight Tracker"} dynamicHeight={true}>
              <SearchPanel
                startDate={startDate}
                // endDate={endDate}
                statuses={statuses}
                handleEvent={this.handleEvent}
                handleApply={(e, p) =>
                  this.handleApply(
                    e,
                    p,
                    appConstants.AIRLINE_DAILY_FLIGHT_TRACKER
                  )
                }
                handleStatusChange={this.handleStatusChange}
                handleSearch={this.handleFlightSearch}
              />
              {flightTotalCount > 0 && (
                <AirlineDailyFlightTrackerTable
                  records={flightRecords}
                  currentPage={flightCurrentPage}
                  pageSize={pageSize}
                  fetchRecords={this.handleFlightSearch}
                  totalCount={flightTotalCount}
                  dateTime={startDate}
                  trackerType={flightTrackerType}
                  recordStartDate={startDate}
                />
              )}
              {flightTotalCount === 0 && (
                <EmptyRecord message={"No daily flight record found."} />
              )}
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={12} className={"manifest-list"}>
            <Card
              title={"Airline Daily Passenger Count Tracker"}
              dynamicHeight={true}
            >
              <SearchPanel
                startDate={paxStartDate}
                // endDate={paxEndDate}
                statuses={statuses}
                handleEvent={this.handleEvent}
                handleApply={(e, p) =>
                  this.handleApply(e, p, appConstants.AIRLINE_DAILY_PAX_TRACKER)
                }
                handleStatusChange={this.handleStatusChange}
                handleSearch={this.handlePaxSearch}
              />
              {paxTotalCount > 0 && (
                <AirlineDailyPaxTrackerTable
                  records={paxRecords}
                  currentPage={paxCurrentPage}
                  pageSize={pageSize}
                  fetchRecords={this.handlePaxSearch}
                  totalCount={paxTotalCount}
                  dateTime={paxStartDate}
                  trackerType={paxTrackerType}
                  recordStartDate={paxStartDate}
                />
              )}
              {paxTotalCount === 0 && (
                <EmptyRecord message={"No daily passenger record found."} />
              )}
            </Card>
          </Col>
        </Row>
      </AppLayout>
    );
  }
}

function mapStateToProps(state) {
  const { modal, airlinesCarriers, requesting, user } = state;
  return {
    requesting,
    modal,
    airlinesCarriers,
    user
  };
}
export default connect(mapStateToProps)(DomesticFlagMetrics);

/* Written with love by Juliet on July 2019 */
