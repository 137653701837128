import React, { Component } from 'react';
import {
  Container, Row, Col
} from "react-bootstrap";
import logo from '../../assets/image/logo.svg';
// import 'react-vertical-timeline-component/style.min.css';
import ProgressLoader from '../../components/progressloader'

/*Components*/
import Buttons from "../../components/buttons";
import SummaryCard from "../../components/summaryCards/index";
import Card from "../../components/card/index";
import { AreaChartGraph, LineChartGraph } from "../../components/graph";
import Table from "../../components/table";
import Timeline from "../../components/timeline";
import DatePicker from "../../components/datepicker";
import moment from 'moment';

import {FiPlusSquare, FiClock} from "react-icons/fi";

let percentage = [30, 50, 100]

let progressBars = [
    {
        name: "10 - 20",
        value: 30,
        variant: "success",
        percentage: 40
    },
    {
        name: "20 - 30",
        value: 50,
        variant: "warning",
        percentage: 70
    },
    {
        name: ">50",
        value: 20,
        variant: "danger",
        percentage: 50
    }
];

/*Area graph Data*/
const areaGraphData = {
    legends : {
      success: "10-20",
      fair: "20-30",
      critical: "30"
    },
    data: [
        {name: '11:00', "10-20": 14000, "20-30": 5400, "30": 2400},
        {name: '11:10', "10-20": 3000, "20-30": 1398, "30": 2210},
        {name: '11:20', "10-20": 1000, "20-30": 9800, "30": 12290},
        {name: '11:30', "10-20": 500, "20-30": 13908, "30": 2000},
        {name: '11:40', "10-20": 2000, "20-30": 4800, "30": 2181},
        {name: '11:50', "10-20": 250, "20-30": 3800, "30": 2500},
        {name: '12:00', "10-20": 490, "20-30": 4300, "30": 12100},
    ]
};

/* Line graph Data*/
const lineGraphData = {
    legends : {
      success: "10-20",
      fair: "10-20",
      critical: "10-20"
    },
    data: [
        {name: 'Sunday', successful: 4000, processing: 2400, failed: 2400},
        {name: 'Monday', successful: 3000, processing: 1398, failed: 2210},
        {name: 'Tuesday', successful: 3000, processing: 1398, failed: 2210},
        {name: 'Wednesday', successful: 2000, processing: 9800, failed: 2290},
        {name: 'Thursday', successful: 2780, processing: 3908, failed: 2000},
        {name: 'Friday', successful: 1890, processing: 4800, failed: 2181},
        {name: 'Saturday', successful: 3490, processing: 4300, failed: 2100},
    ]
};

const timelineData = [
    {
        description: "20 manifests pulled from mode 1",
        timestamp: "2019-02-04 13:23:55",
        status: "successful"
    },
    {
        description: "20 manifests pulled from mode 3",
        timestamp: "2019-02-04 13:23:55",
        status: "failed"
    },
    {
        description: "20 manifests pulled from mode 2",
        timestamp: "2019-02-04 13:23:55",
        status: "pending"
    },
    {
        description: "20 manifests pulled from mode 3",
        timestamp: "2019-02-04 13:23:55",
        status: "failed"
    },
    {
        description: "20 manifests pulled from mode 4",
        timestamp: "2019-02-04 13:23:55",
        status: "successful"
    }
]


const headings = [
    'Product name',
    'SKU',
    'Stock quantity',
    'Wholesale cost',
    'Sale price',
    'Quantity sold',
    'Gross sales',
    'Net sales',
    'Notes',
  ];

  const rows = [
    [
      'Red and black plaid scarf with thin red stripes and thick black stripes',
      124689325,
      28,
      '$35.00',
      '$60.00',
      12,
      '$720.00',
      '$300.00',
      '',
    ],
    [
      'Yellow plaid scarf',
      124689389,
      0,
      '$35.00',
      '$60.00',
      20,
      '$1200.00',
      '$500.00',
      'Currently on back order by the supplier. Do not place another order to restock.',
    ],
    [
      'Blue plaid scarf',
      124689332,
      30,
      '$35.00',
      '$60.00',
      10,
      '$600.00',
      '$250.00',
      '',
    ],
    [
      'Pink plaid scarf',
      124689376,
      16,
      '$35.00',
      '$60.00',
      4,
      '$240.00',
      '$100.00',
      '',
    ],
  ]

class App extends Component {

    constructor(props) {
        super(props)

        this.state = {
            startDate: moment(),
            endDate: moment()
        }
    }

    // handle apply click on date picker
    handleApply = (event, picker) => {
        this.setState({ startDate: picker.startDate, endDate: picker.endDate })
    }

    render() {
        const { startDate, endDate } = this.state
        return (
            <div className="App">
            <ProgressLoader message="Loading data..." />
                <header className="App-header">
                    <img src={logo} width={'100'} className="App-logo" alt="logo" />

                    <Container>
                        <div>
                            <DatePicker startDate={startDate}
                                endDate={endDate}
                                onEvent={this.handleEvent}
                                onApply={this.handleApply}

                            />
                        </div>

                        <hr />

                        <div>
                            <Buttons />
                        </div>

                        <hr />

                <Card title={'Manifest Process Duration'}>
                    <AreaChartGraph legends={areaGraphData.legends} data={areaGraphData.data}/>
                </Card>

                <hr/>
                <Card title={'Manifest Status'}>
                    <LineChartGraph {...lineGraphData} />
                </Card>

                <hr/>

                <Row>
                    <Col md={6}>
                        <AreaChartGraph legends={areaGraphData.legends} data={areaGraphData.data}/>
                    </Col>
                    <Col md={6}>
                        <LineChartGraph {...lineGraphData} />
                    </Col>
                </Row>

                <hr/>
                <div>
                    <Timeline timelineData={timelineData} />
                </div>

                <hr/>
                <Row>
                    <Col md="4">
                        <SummaryCard title={'MANIFEST PROCESSED INFO'}
                              bars={progressBars}
                              animation={true}
                              percentage={percentage[0]}
                              firstText={'Total Processed'}
                              firstValue={'500k'}
                              secondText={'Time Spent'}
                              secondValue={'20hrs'}
                              icon1={<FiPlusSquare />}
                              icon2={<FiClock/>}
                        />
                    </Col>
                    <Col md="4">
                        <SummaryCard title="Title" bars={progressBars} animation={true} percentage={percentage[1]}/>
                    </Col>
                    <Col md="4">
                        <SummaryCard title="Title" bars={progressBars} animation={true} percentage={percentage[2]}/>
                    </Col>
                </Row>


                <hr/>

                <div>
                    <Table headings={headings} rows={rows} />
                </div>
            </Container>
        </header>
      </div>
    );
  }
}

export default App;
