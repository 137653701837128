import { appConstants } from '../_constants';
import { appHelpers } from '../_helpers';
// import {relativeTimeThreshold} from "moment";


export const commonService = {
    searchManifestAggregateDuration,
    searchManifestProcessDuration,
    searchManifestStatus,
    searchManifestPullModes,
    searchManifestAggregateStatus,
    fetchManifestList,
    getAirlinesCarriers,
    searchManifestAggregate,
    postSignIn,
    fetchManifestTimeline,
    fetchAggregateTimeline,
    fetchAvicollectPSCFlightsByAirline,
    fetchAvicollectPSCFlightsByLocation,
    fetchBillablePassengersSummary,
    fetchBillablePassengersVariance,
    searchPscManifestStatus,
    searchPscManifestStatusByAirline,
    getAirlineFlaggingStatus,
    getAirportLocation,
    getIntlAirlineFlaggingStatus,
    getIntlAirportLocationDetails,
    getAirlinesFlightNumbers,
    getTscFlaggingStatus,
    getAllAirports,
    getTscDrillDownFlaggingStatus
};

function fetchAvicollectPSCFlightsByAirline(payload) {
    return appHelpers.postRequest(`${appConstants.PAS_URL}/api/TransactionDashboard/SearchAvicollectPscFlightsByAirline`, payload)
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        );
}

function fetchAvicollectPSCFlightsByLocation(payload) {
    return appHelpers.postRequest(`${appConstants.PAS_URL}/api/TransactionDashboard/SearchAvicollectPscFlightsByLocation`, payload)
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        );
}

function fetchBillablePassengersSummary(payload) {
    const { StartDate, EndDate } = payload
    return appHelpers.getRequest(`${appConstants.PAS_URL}/api/TransactionDashboard/GetSumBillablePassengers?sDate=${StartDate}&eDate=${EndDate}`)
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        );
}

function fetchBillablePassengersVariance(payload) {
    const { StartDate, EndDate } = payload
    return appHelpers.getRequest(`${appConstants.PAS_URL}/api/TransactionDashboard/GetBillablePassengersVariance?sDate=${StartDate}&eDate=${EndDate}`)
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        );
}

function searchManifestAggregateDuration(payload) {
    return appHelpers.postRequest(`${appConstants.PAS_URL}/api/transactionmonitor/ManifestAggregationByduration`, payload)
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        );
}

function searchManifestProcessDuration(payload) {
    return appHelpers.postRequest(`${appConstants.PAS_URL}/api/transactionmonitor/manifestprocessbyduration`, payload)
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        );
}
function searchManifestStatus(payload) {
    return appHelpers.postRequest(`${appConstants.PAS_URL}/api/transactionmonitor/manifestprocessbystatus`, payload)
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        );
}
function searchManifestAggregateStatus(payload) {
    return appHelpers.postRequest(`${appConstants.PAS_URL}/api/transactionmonitor/ManifestAggregationByStatus`, payload)
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        );
}

function searchManifestAggregate(payload) {
    return appHelpers.postRequest(`${appConstants.PAS_URL}/api/transactionmonitor/AggregateManifestTimeLine`, payload)
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        );
}

function fetchManifestList(payload) {
    return appHelpers.postRequest(`${appConstants.PAS_URL}/api/TransactionMonitor/SearchManifest`, payload)
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        );
}

function getAirlinesCarriers() {
    return appHelpers.getRequest(`${appConstants.PAS_URL}/api/airline/search?carrier=true`)
        .then(res => {
            return res;
        });
}

function getAllAirports() {
    return appHelpers.getRequest(`${appConstants.PAS_URL}/api/airport/search`)
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        );
}

function searchManifestPullModes(payload) {
    return appHelpers.postRequest(`${appConstants.PAS_URL}/api/transactionmonitor/manifestprocessbypullmode`, payload)
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        );
}

function fetchManifestTimeline(payload) {
    // return appHelpers.postRequest(`${appConstants.PAS_URL}/api/TransactionMonitor/SearchFile`, payload)
    return appHelpers.getRequest(`${appConstants.PAS_URL}/api/transactionmonitor/getfiletimeline?fileid=${payload.fileId}`)
        // return appHelpers.getRequest(`${appConstants.PAS_URL}/api/transactionmonitor/getfiletimeline?fileid=25996`)
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        );
}

function fetchAggregateTimeline(aggregateId) {
    return appHelpers.getRequest(`${appConstants.PAS_URL}/api/TransactionMonitor/GetManifestAggregateTimeline?aggregateid=${aggregateId}`)
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        );
}

// As a result of the assigned security task,
// I had to set a timestamp on the API url to make the url different each time you make the axios call
// and therefore disable caching because it sees a different url everytime the call is made
// There doesn't seem to be a way to force axios or fetch to disable cache.

function postSignIn(payload) {
    return appHelpers.postRequest(`${appConstants.PAS_URL}/api/Account/Login?timestamp=${new Date().getTime()}`, payload)
        .then(res => {
            const { error } = res.response;
            if (!error) {
                return res;
            } else {
                return appHelpers.formatPromiseResponse(error, appConstants.ERROR_RESPONSE);
            }
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        );
}

function searchPscManifestStatus(payload) {
    return appHelpers.postRequest(`${appConstants.PAS_URL}/api/TransactionDashboard/SearchManifestsStatus`, payload)
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        )
}

function searchPscManifestStatusByAirline(payload) {
    return appHelpers.postRequest(`${appConstants.PAS_URL}/api/TransactionDashboard/SearchManifestsStatusByAirline`, payload)
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        )
}

function getAirlineFlaggingStatus(payload) {
    return appHelpers.postRequest(`${appConstants.PAS_URL}/api/Manifest/GetDomFlagStatus`, payload)
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        )
}

function getAirportLocation(payload) {
    return appHelpers.postRequest(`${appConstants.PAS_URL}/api/Manifest/GetDomFlagDetails`, payload)
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        )
}

function getIntlAirlineFlaggingStatus(payload) {
    return appHelpers.postRequest(`${appConstants.PAS_URL}/api/Manifest/GetIntlFlagStatus`, payload)
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        )
}

function getIntlAirportLocationDetails(payload) {
    return appHelpers.postRequest(`${appConstants.PAS_URL}/api/Manifest/GetIntlFlagDetails`, payload)
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        )
}

function getAirlinesFlightNumbers(airlineId) {
    let payload = {From: 0, PageSize: 1000, Parameter: {AirlineId: airlineId}};
    return appHelpers.postRequest(`${appConstants.PAS_URL}/api/Flight/search`, payload)
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        )
}

function getTscFlaggingStatus(payload) {
    return appHelpers.postRequest(`${appConstants.PAS_URL}/api/Manifest/GetTscFlagStatus`, payload)
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        )
}

function getTscDrillDownFlaggingStatus(payload) {
    return appHelpers.postRequest(`${appConstants.PAS_URL}/api/Manifest/GetTscFlagDetails`, payload)
        .then(res => {
            return res;
        }).catch(
            error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                return appHelpers.formatPromiseResponse(errorMessage, appConstants.ERROR_RESPONSE);
            }
        )
}
