import React, { Component } from 'react';
// import './index.scss';
import { Row, Col, Container } from 'react-bootstrap';
import Navbar from '../../components/navbar';
import Sidebar from '../../components/sidebar';
// import { PrimaryTab } from '../../components/tabs';
import { connect } from "react-redux";
import ProgressLoader from '../../components/progressloader'
import { alertConstants } from '../../_constants';
import { alertActions } from '../../_actions';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import {history} from "../../_helpers"

// const avicollectMenu = [
//     {
//         name: "Manifest Process",
//         link: "/"
//     },
//     {
//         name: "Manifest List",
//         link: "/manifestList"
//     },
//     {
//         name: "Manifest Aggregate",
//         link: "/manifestAggregate"
//     }
// ];

class Layout extends Component {

    state = {
        openSideBar: true,
        title: ""
    }

    componentDidMount() {
        const { title } = this.props;
        this.setState({ title });
        let {user} = this.props;
        // console.log('layout user', user)

        if(!user) {
            history.push("/login");
        }
    }

    componentWillReceiveProps = function (nextProps) {
        const { alert, dispatch } = nextProps;
        let msg_;
        if(alert){
            if(alert.type === alertConstants.NOTIFY ){
                msg_ = (typeof alert.message === 'string') ? alert.message : 'Unable to process request';
                this.createNotification('warning', msg_);
                dispatch(alertActions.clear());
            }else if(alert.type === alertConstants.SUCCESS ){
                msg_ = (typeof alert.message === 'string') ? alert.message : 'Request Successful';
                this.createNotification('success', msg_);
                dispatch(alertActions.clear());
            }else if(alert.type === alertConstants.ERROR ){
               dispatch(alertActions.clear());
                msg_ = (typeof alert.message === 'string') ? alert.message : 'Unable to process request';
                this.createNotification('error', msg_);
            }
        }
    };

    createNotification = (type, message) => {
        switch (type) {
            case 'info':
                NotificationManager.info(message);
                break;
            case 'success':
                NotificationManager.success(message);
                break;
            case 'warning':
                NotificationManager.warning(message);
                break;
            case 'error':
                NotificationManager.error(message);
                break;
            default:
                break;
        }

};

    handleMenu = () => {
        let { openSideBar } = this.state;
        this.setState({ openSideBar: !openSideBar });
    };

    logout = () => {
        history.push('/login')
    };


    render() {
        let { openSideBar, title} = this.state;
        const { requesting, user } = this.props;

        return (
            <div>
                <Navbar
                    handleMenu={this.handleMenu}
                    title={title}
                    user={user}
                    logout={this.logout}

                />
                <div className="app">
                    {requesting &&
                        <ProgressLoader message="Loading data..." />
                    }
                    {
                        openSideBar &&
                        (
                            <Container fluid={true}>
                                <Row>
                                    {openSideBar &&
                                        <Sidebar />
                                    }
                                    <Col xs="12" md="10">
                                        {/* <PrimaryTab menus={menus} activeMenuIndex={this.props.activeMenuIndex} /> */}
                                        {this.props.children}
                                    </Col>
                                </Row>
                            </Container>
                        )
                    }
                    {
                        !openSideBar &&
                        <Container fluid={true}>
                            <Row>
                                <Col md={12}>
                                    {/* <PrimaryTab menus={menus} activeMenuIndex={this.props.activeMenuIndex} /> */}
                                </Col>
                                <Col md="12">
                                    {this.props.children}
                                </Col>
                            </Row>
                        </Container>
                    }

                </div>
                <NotificationContainer />
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { requesting, alert, user } = state;
    return {
        requesting,
        alert,
        user
    };
}
export default connect(mapStateToProps)(Layout);
