import React from 'react';
import { Col, Row, Form } from 'react-bootstrap';
import './index.css';
import BDAIcon from '../../assets/image/Rectangle573.svg';
import AvicollectIcon from '../../assets/image/Rectangle570.svg'
import { NavLink } from "react-router-dom";


function Sidebar(props) {
    let { openSideBar } = props;
    const [selected, setSelected] = React.useState(1);

    return (
        <Col xs="12" md="2">
            <Row>
                <Col xs="12">
                    <div className={` ${openSideBar} ? sidebaropen : ""`}>
                        <Row>
                            <Col xs="12">
                                <Form>
                                    <Form.Group>
                                        <Form.Control type="text" name="applications" id="applications" placeholder="Search applications" />
                                    </Form.Group>
                                </Form>
                            </Col>
                            <Col xs="12" className="tx-white">

                                <ul className="list-group list-group-flush">
                                    {/* <li onClick={setSelected.bind(this, 1)} className={`list-group-item ${(selected === 1) ? 'active' : ''}`}> */}
                                    <NavLink onClick={setSelected.bind(this, 1)} exact to="/manifestProcess" className="list-group-item-action" activeClassName={`${(selected === 1) ? 'active' : ''}`}>
                                        <img className="img-logo-cover img-logo" src={AvicollectIcon} alt="Avicollect" />
                                        <span className="name">Avicollect</span>
                                    </NavLink>
                                    {/* </li> */}
                                    {/* <li onClick={setSelected.bind(this, 2)} className={`list-group-item ${(selected === 2) ? 'active' : ''}`}> */}
                                    <NavLink onClick={setSelected.bind(this, 2)} to="/avicollect-psc" className="list-group-item-action" activeClassName={`${(selected === 2) ? 'active' : ''}`}>
                                        <img className="img-logo-cover img-logo" src={BDAIcon} alt="Passenger Service Charge" />
                                        <span className="name">PSC</span>
                                    </NavLink>
                                    {/* </li> */}
                                </ul>
                            </Col>
                        </Row>
                    </div>
                </Col>

            </Row>
        </Col>
    )

}

export default Sidebar
