import React from "react";
import DatePicker from "../../components/datepicker";
import SelectPicker from "../../components/selectpicker";
import { Button } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";

const SearchPanel = ({
  startDate,
  //   endDate,
  handleEvent,
  handleApply,
  handleSearch,
  statuses,
  airlines,
  dateTypes,
  selectedStatus,
  selectedAirline,
  handleAirlineChange,
  handleStatusChange
}) => {
  return (
    <div className="row">
      <div className="col-md-3">
        <DatePicker
          startDate={startDate}
          //   endDate={endDate}
          onEvent={handleEvent}
          onApply={handleApply}
          isDatePicker={true}
        />
      </div>

      <div className="col-md-3">
        <SelectPicker
          options={airlines}
          placeholder="Select Airline"
          selectedValue={selectedAirline}
          handleChange={handleAirlineChange}
          customKeys={true}
        />
      </div>
      <div className="col-md-3">
        <SelectPicker
          options={statuses}
          placeholder="Select Status"
          selectedValue={selectedStatus}
          handleChange={handleStatusChange}
          customKeys={true}
        />
      </div>

      <div className="col-md-3">
        <Button className="btn-search btn-block" onClick={() => handleSearch()}>
          <span>
            <FiSearch />
          </span>{" "}
          &nbsp; Search
        </Button>
      </div>
    </div>
  );
};

export default SearchPanel;

/* Written with love by Juliet on July 2019 */
