import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import moment from "moment";
import 'react-vertical-timeline-component/style.min.css';
import { FiChevronsRight, FiStar, FiDisc } from "react-icons/fi";
import { appHelpers } from "../../../../_helpers";
import { appConstants } from "../../../../_constants";

// const VigipayStepNameStyle = { background: appConstants.YELLOW_COLOR, color: '#ffffff' };
// const VigipayIconStepStyle = { background: 'rgb(255, 255, 255)', color: appConstants.YELLOW_COLOR };
const VigipayStepNameStyle = { background: 'rgb(28, 102, 87)', color: '#ffffff' };
const VigipayIconStepStyle = { background: 'rgb(255, 255, 255)', color: '#1c6657' };
const ProcessErrorIconStepStyle = { background: appConstants.RED_COLOR, color: "#fff" };

export default ({ Vigipay }) => {
    return (
        <div>
            <VerticalTimeline>
                <VerticalTimelineElement
                    date={(<h3 className={'stepName'} style={VigipayStepNameStyle}>Vigipay</h3>)}
                    iconStyle={VigipayStepNameStyle}
                    icon={<FiStar />}
                />

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(Vigipay) ? moment(Vigipay.SentDate).fromNow() : moment().fromNow()}
                    iconStyle={(!Vigipay) ? ProcessErrorIconStepStyle : VigipayIconStepStyle}
                    icon={<FiChevronsRight />}
                >
                    <h2 className="vertical-timeline-element-subtitle">{'Reference'}</h2>
                    <p className="vertical-timeline-element-paragraph">
                        {
                            (Vigipay) &&
                            <span>
                                {Vigipay.ReferenceNumber}
                            </span>
                        }
                        {
                            (!Vigipay) &&
                            <span className={'text-muted'}>
                                No reference number yet.
                            </span>
                        }
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(Vigipay && Vigipay.CallbackNotified) ? moment(Vigipay.CallbackNotifiedDate).fromNow() : moment().fromNow()}
                    iconStyle={(!Vigipay) ? ProcessErrorIconStepStyle : VigipayIconStepStyle}
                    icon={<FiDisc />}
                >
                    <h2 className="vertical-timeline-element-subtitle">{'Notification'}</h2>
                    {
                        (Vigipay && Vigipay.CallbackNotified) &&
                        <p className="vertical-timeline-element-paragraph">
                            Payment notified on <strong>{appHelpers.formatDateSpecific(Vigipay.CallbackNotifiedDate)}</strong>.
                    </p>
                    }
                    {
                        !Vigipay &&
                        <p className="vertical-timeline-element-paragraph">
                            Invoice not sent to VigiPay yet!
                    </p>
                    }
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(Vigipay) ? moment(Vigipay.SentDate).fromNow() : moment().fromNow()}
                    iconStyle={(!Vigipay || Vigipay.Status !== "00") ? ProcessErrorIconStepStyle : VigipayIconStepStyle}
                    icon={<FiDisc />}
                >
                    <h2 className="vertical-timeline-element-subtitle">{'Status'}</h2>
                    {
                        Vigipay &&
                        <p className="vertical-timeline-element-paragraph">
                            {Vigipay.StatusDescription}
                        </p>
                    }
                    {
                        !Vigipay &&
                        <p className="vertical-timeline-element-paragraph">
                            No status yet!
                    </p>
                    }
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    date={`That's all.`}
                    iconStyle={{ background: 'rgb(255, 255, 255)', color: '#2992d0' }}
                    icon={<FiStar />}
                />
            </VerticalTimeline>
        </div>
    );
};
