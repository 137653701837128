import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from "react-redux";
import { persist, store } from './_store';
import { history } from "./_helpers";
import routes from './routes';
import { PersistGate } from 'redux-persist/lib/integration/react';

import './assets/css/App.css';


ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persist}>
        <Router history={history}>
            {routes}
        </Router>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);
