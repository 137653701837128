import React, { Component } from 'react'
import Pagination from 'react-js-pagination';
import './paginatedtable.css'

class ServerPaginatedTable extends Component {

    constructor(props) {
        super(props)
        this.state = {
            activePage: 1
        }
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    handlePageChange(pageNumber, callback) {
        this.setState({ activePage: pageNumber });
        callback(pageNumber);
    }

    render() {
        let { target, totalCount, pageSize, fetchMore, currentPage } = this.props;
        return (
            <div>
                {/*Display The responding table*/}
                {target}
                <Pagination
                    hideDisabled={true}
                    activePage={currentPage}
                    nextPageText={'Next'}
                    prevPageText={'Previous'}
                    lastPageText={'Last Page'}
                    firstPageText={'First Page'}
                    itemsCountPerPage={pageSize}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={7}
                    onChange={(pageNumber) => {
                        this.handlePageChange(pageNumber, fetchMore);
                    }}
                    itemClass={"page-item"}
                    activeClass={'page-item active'}
                    activeLinkClass={'page-item active'}
                    linkClass={'page-link'}
                />
            </div>
        )
    }
}

export default ServerPaginatedTable