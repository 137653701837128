import React from 'react';
import "./index.css"
import {
    Modal,
} from "react-bootstrap";
import FileTimeline from './filetimeline';
import moment from "moment/moment";


class TimelineModal extends React.Component {
    render() {
        const { timelinedata, manifestfile} = this.props;
        return (
            <Modal
                {...this.props}
                 backdrop
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={'modal-90w'}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <span className={'modal-title'}>
                            {
                                manifestfile.Airline
                            } ( {manifestfile.SuccessfulManifests} of {manifestfile.TotalManifests} successful manifests)
                            <span className={'time'}>
                               Received {moment(manifestfile.CreatedDate).fromNow()}
                            </span>
                        </span>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <FileTimeline
                      timelineData={timelinedata}
                      manifestfile={manifestfile}
                      processStatus={manifestfile.ProcessStatus}
                      airline={manifestfile.Airline}
                  />
                </Modal.Body>
                {/*<Modal.Footer>*/}
                    {/*<Button onClick={this.props.onHide}>Close</Button>*/}
                {/*</Modal.Footer>*/}
            </Modal>
        );
    }
}

export default TimelineModal
