import React, { Component } from 'react';
import ServerPagination from "../../components/paginatedtable";
import { appHelpers } from "../../_helpers";
import {
    Table,
    Badge
} from "react-bootstrap";
import './manifestlist.css'
import { FiCheckSquare, FiXSquare } from "react-icons/fi";
import TableCell from '../../components/table/tablecell';
import {appConstants} from "../../_constants";
import {alertActions} from "../../_actions";
import {commonService} from "../../_services";
import TimelineModal from "./filetimeline/timelinemodal"
import {connect} from "react-redux";

const renderStatus = (status) => {
    switch (status) {
        case 100:
            return (<Badge variant='warning'>Pending</Badge>)
        case 101:
            return (<Badge variant='primary'>Processing</Badge>)
        case 102:
            return (<Badge variant='success'>Completed</Badge>)
        default:
            return (<Badge variant='secondary'>No Status</Badge>)
    }

}

const ManifestTableStructure = ({ records, handleRowClick }) => (
    <div>
        <Table hover className='table-vgg-darkblue' responsive='sm'>
            <thead>
                <tr>
                    <th>Airline Name</th>
                    <th>Airline Code</th>
                    <th>Date Received</th>
                    <th>Date Processed</th>
                    <th>Pull Mode</th>
                    <th>Total Manifests</th>
                    <th><FiCheckSquare className={'table-fixchecksquare-icon'} size={'2em'} /></th>
                    <th><FiXSquare className={'table-fixsquare-icon'} size={'2em'} /></th>
                    <th>Processed Status</th>
                </tr>
            </thead>
            <tbody>
                {records.map((manifest, index) => {
                    return (
                        <tr key={index} className='pointer' onClick={() => handleRowClick(manifest)}>
                            <TableCell content={manifest.Airline} />
                            <TableCell content={manifest.AirlineCode} />
                            <TableCell content={appHelpers.formatDate(manifest.CreatedDate)} />
                            <TableCell content={appHelpers.formatDate(manifest.ProcessDate)} />
                            <TableCell content={manifest.PullModeName} />
                            <TableCell content={appHelpers.numberWithCommasOnly(manifest.TotalManifests)} />
                            <TableCell content={appHelpers.numberWithCommasOnly(manifest.SuccessfulManifests)} />
                            <TableCell content={appHelpers.numberWithCommasOnly(manifest.TotalManifests - manifest.SuccessfulManifests)} />
                            <TableCell content={renderStatus(manifest.ProcessStatus)} />
                        </tr>

                    )
                })}
            </tbody>
        </Table>
    </div>
)

class PaginatedTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            timelineData: null,
            timelineShow: false,
            processStatus: '',
            manifestFile: {}
        }

    }

    handleRowClick = (manifestFile) => {
        this.setState({ manifestFile: manifestFile }, () => {this.handleSearchTimeline()});
        // this.handleSearchTimeline(FileId);
    }

    handleSearchTimeline = () => {
        const { dispatch } = this.props;
        let payload = {
            fileId: this.state.manifestFile.FileId
        };
        dispatch(alertActions.startRequest());
        commonService.fetchManifestTimeline(payload)
            .then(res => {
                    if (res.status === appConstants.SUCCESS_RESPONSE) {
                        const response = res.response;
                        this.setState({
                            timelineData: response,
                            timelineShow: true
                        });
                        dispatch(alertActions.stopRequest());
                    }
                    else if (res.status === appConstants.ERROR_RESPONSE) {
                        dispatch(alertActions.error(res.response.Message));
                        dispatch(alertActions.stopRequest());
                        dispatch(alertActions.error(res.response))

                    }
                },
                error => {
                    let errorMessage = appHelpers.interpretErrorResponse(error);
                    dispatch(alertActions.stopRequest());
                    dispatch(alertActions.error(errorMessage))

                }
            );
    }

    modalClose = () =>{
        this.setState({ timelineShow: false });
    }

    render() {
        const { totalCount, records, currentPage, pageSize,
            fetchRecords } = this.props;
        const { timelineData, manifestFile, timelineShow } = this.state;
        return (
            <div>
                <div className="table-responsive" style={{ overflowX: "auto" }}>
                    <ServerPagination fetchMore={fetchRecords}
                        totalCount={totalCount} pageSize={pageSize}
                        currentPage={currentPage}
                        target={
                            <ManifestTableStructure
                                records={records}
                                handleRowClick={this.handleRowClick}
                            />
                        }
                    />
                </div>
                <TimelineModal
                    timelinedata={timelineData}
                    manifestfile={manifestFile}
                    show={timelineShow}
                    onHide={this.modalClose}
                />
            </div>
        )
    }
}
function mapStateToProps(state) {
    const { modal, requesting } = state;
    return {
        requesting,
        modal,
    };
}
export default connect(mapStateToProps)(PaginatedTable);
