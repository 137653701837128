import React from 'react';
import {
    ButtonToolbar,
    Button,
} from "react-bootstrap";
const ButtonComponent = () => {
    return (
        <div>
            <div className="justify-content-md-center">
                <ButtonToolbar>
                    <Button variant="primary">Primary</Button>
                    &nbsp;<Button variant="secondary">Secondary</Button>
                    &nbsp;<Button variant="success">Success</Button>
                    &nbsp;<Button variant="warning">Warning</Button>
                    &nbsp;<Button variant="danger">Danger</Button>
                    &nbsp;<Button variant="info">Info</Button>
                    &nbsp;<Button variant="light">Light</Button>
                    &nbsp;<Button variant="dark">Dark</Button>
                    <Button variant="link">Link</Button>
                </ButtonToolbar>
            </div>
        </div>
    );
};

export default ButtonComponent;
