import React from 'react';
import { FiInfo } from "react-icons/fi";

export const EmptyRecord = ({message}) => {
    return (
        <div className={'empty-container'}>
            <h1 className={'icon'}>
                <FiInfo style={{'color': '#ff851b'}}/>
            </h1>
            <p className={'text'}>
                {message}
            </p>
        </div>
    );
};
