import React from 'react';


export default function TableCell({
  content,
  header,
}) {

  const cellMarkup = header ? (
    <th>
      {content}
    </th>
  ) : (
      <td>
        {content}
      </td>
    );

  return (cellMarkup);
}