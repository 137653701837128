import React, { Component } from 'react';
import ServerPagination from "../../components/paginatedtable";
import { appHelpers } from "../../_helpers";
import {
    Table,
    Badge
} from "react-bootstrap";
import TableCell from '../../components/table/tablecell';
import {appConstants} from "../../_constants";
import {alertActions, commonActions} from "../../_actions";
import {commonService} from "../../_services";
import {connect} from "react-redux";
import RecordModal from "./recordModal"
import DrillDownTable from "./airlineMonthlyPaxDrillDownTable"
import {store} from "../../_store";
import moment from 'moment';



const renderStatus = (status) => {
    switch (status) {
        case false:
            return (<Badge variant='danger'>Issues Exist</Badge>)
        case true:
            return (<Badge variant='success'>Good</Badge>)
        default:
            return (<Badge variant='secondary'>No Status</Badge>)
    }

};


const status = [
    {
        Id: 0,
        Name: "All Status"
    },
    {
        Id: false,
        Name: "Issues Exist"
    },
    {
        Id:true,
        Name: "Good"
    }
];

const AirlinePAXtTrackerTableStructure = ({ records, handleRowClick,  dateTime, trackerType, pageIndex }) => (
    <div>
        <Table hover className='table-vgg-darkblue' responsive='sm'>
            <thead>
            <tr>
                <th>S/N</th>
                <th>Airline Flight Count</th>
                {/* <th>Min Range(Number of PAX Daily)</th> */}
                {/* <th>Max Range (Number of PAX Daily)</th> */}
                <th>Actual Pax Count</th>
                <th>Status</th>
            </tr>
            </thead>
            <tbody>
            {records.map((record, index) => {
                return (
                    <tr key={index} className='pointer' onClick={() => handleRowClick(record, dateTime, trackerType )}>
                        <TableCell content={`${pageIndex + index + 1}`} />
                        <TableCell content={record.AirlineName} />
                        {/* <TableCell content={record.MinRange} /> */}
                        {/* <TableCell content={record.MaxRange} /> */}
                        <TableCell content={record.Actual} />
                        <TableCell content={renderStatus(record.Status)} />
                    </tr>
                )
            })}
            </tbody>


        </Table>
    </div>
);

class PaginatedTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            drillDownData: null,
            timelineShow: false,
            processStatus: '',
            fileRecords: [],
            dateTime: null,
            trackerType: null,
            pageSize: 10,
            drillDownTotalCount: 0,
            drillDownCurrentPage: 1,
            selectedAirport: null,
            drillDownStatus: status,
            airports: []
        }

    }

    componentDidMount() {
        const {dispatch} = this.props;
        const reduxState = store.getState();
        let { airports } = reduxState;

        if (airports) {
            let modifiedAirport = [...airports.Results];
            modifiedAirport.unshift({ Name: "All Airports", Code: "-1" });
            this.setState({ airports: modifiedAirport })
        } else {
            dispatch(commonActions.getAllAirports())
        }

    }

    componentWillReceiveProps() {
        let {  airports } = store.getState();
        if (airports) {
            let modifiedAirport = [...airports.Results];
            modifiedAirport.unshift({ Name: "All Airports", Code: "-1" });
            this.setState({ airports: modifiedAirport })
        }

    }

    handleRowClick = (fileRecords, dateTime, trackerType) => {
        this.setState({dateTime: dateTime, trackerType: trackerType, fileRecords: fileRecords},
            () => {
                this.getDrillDownData()
            });
    };

    handleAirportChange = (selected) => {
        this.setState({ selectedAirport: (selected) ? selected.Code : "" })
    };

    handleStatusChange = (selected) => {
        this.setState({ selectedStatus: (selected) ? selected.Id : "" })
    };

    //method to get data for drill down table on click of row
    getDrillDownData = (pageNumber = 1) => {
        const {fileRecords, dateTime, trackerType,
            pageSize, selectedAirport, selectedStatus} = this.state;
        const {dispatch} = this.props;

        let fromIndex;

        fromIndex = (pageNumber - 1) * pageSize;

        let parameter = {
            "AirlineId": fileRecords.AirlineId,
            "DateTime": moment(dateTime).format("YYYY-MM-DD"),
            "TrackerType": trackerType
        };

        if (selectedAirport !== "-1" && selectedAirport !== "") {
            parameter.AirportCode = selectedAirport
        }

        if (selectedStatus !== 0 && selectedStatus !== "") {
            parameter.Status = selectedStatus
        }

        let payload = {
            "PageSize": pageSize,
            "From": fromIndex,
            "Parameter": parameter
        };
        dispatch(alertActions.startRequest());
        commonService.getIntlAirportLocationDetails(payload)
            .then(res => {
                    if (res.status === appConstants.SUCCESS_RESPONSE) {
                        const response = res.response;
                        let drillDownRecord = response.Results.map(item => item.IntlPassengerAirlineResult);
                        this.setState({
                            drillDownData: drillDownRecord,
                            timelineShow: true,
                            drillDownTotalCount: response.TotalCount,
                            drillDownCurrentPage: pageNumber
                        });
                        dispatch(alertActions.stopRequest());
                    } else if (res.status === appConstants.ERROR_RESPONSE) {
                        dispatch(alertActions.error(res.response.Message));
                        dispatch(alertActions.stopRequest());
                        dispatch(alertActions.error(res.response))

                    }
                },
                error => {
                    let errorMessage = appHelpers.interpretErrorResponse(error);
                    dispatch(alertActions.stopRequest());
                    dispatch(alertActions.error(errorMessage))

                }
            );
    };

    modalClose = () => {
        this.setState({
            timelineShow: false,
            selectedStatus: null,
            selectedAirport: null,
        });
    };


    render() {
        const {
            totalCount, records, currentPage, pageSize,
            fetchRecords, dateTime, trackerType,
            recordStartDate, recordEndDate
        } = this.props;
        const {drillDownData, fileRecords, timelineShow,
            drillDownTotalCount, drillDownCurrentPage,
            drillDownStatus, airports} = this.state;

        let pageIndex = (currentPage - 1) * pageSize;
        let drillDownPageIndex = (drillDownCurrentPage - 1) * pageSize;

        return (
            <div>
                <div className="table-responsive" style={{overflowX: "auto"}}>
                    <ServerPagination fetchMore={fetchRecords}
                                      totalCount={totalCount} pageSize={pageSize}
                                      currentPage={currentPage}
                                      target={
                                          <AirlinePAXtTrackerTableStructure
                                              trackerType={trackerType}
                                              dateTime={dateTime}
                                              records={records}
                                              handleRowClick={this.handleRowClick}
                                              pageIndex={pageIndex}
                                          />
                                      }
                    />
                </div>

                <RecordModal
                    headerData={fileRecords}
                    titlePrefix="Monthly Passenger Count Tracker"
                    show={timelineShow}
                    onHide={this.modalClose}
                    drillDownTotalCount={drillDownTotalCount}
                    recordEndDate={recordEndDate}
                    recordStartDate={recordStartDate}
                >
                            <DrillDownTable
                                records={drillDownData}
                                totalCount={drillDownTotalCount}
                                fetchRecords={this.getDrillDownData}
                                currentPage={drillDownCurrentPage}
                                pageIndex={drillDownPageIndex}
                                departureAirport={airports}
                                handleAirportChange={this.handleAirportChange}
                                drillDownStatus={drillDownStatus}
                                handleDrillDownStatusChange={this.handleStatusChange}
                                handleSearch={this.getDrillDownData}
                                drillDownTotalCount={drillDownTotalCount}
                            />

                </RecordModal>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const { modal, requesting } = state;
    return {
        requesting,
        modal,
    };
}
export default connect(mapStateToProps)(PaginatedTable);

/* Written with love by Juliet on July 2019 */


