import React, { Component } from 'react';
import {
    Row, Col,
} from "react-bootstrap";
import Card from "../../components/card/index";
import AppLayout from "../layout/index";
import ManifestListTable from "../manifestList/manifestTable";
import SearchPanel from './searchpanel';
import moment from 'moment';
import { store } from "../../_store";
import { commonActions, alertActions } from '../../_actions';
import { appHelpers } from '../../_helpers';
import { appConstants } from '../../_constants';
import { commonService } from '../../_services';
import { connect } from "react-redux";
import { EmptyRecord } from "../../components/pageutility";
import { PrimaryTab } from '../../components/tabs';

const avicollectMenu = [
    {
        name: "Manifest Process",
        link: "/"
    },
    {
        name: "Manifest List",
        link: "/manifestList"
    },
    {
        name: "Manifest Aggregate",
        link: "/manifestAggregate"
    },
    {
        name: "Flag Metrics",
        link: "/international-flag-metrics"
    },
    {
        name: "TSC Value Tracker",
        link: "/tsc-value-tracker"
    }
];

class ManifestList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().subtract(1, 'hours'),
            endDate: moment(),
            selectedStatus: null,
            selectedAirline: null,
            selectedDateType: null,
            selectedPullMode: null,
            statuses: [{ Id: 100, Name: 'Pending' },
            { Id: 102, Name: 'Completed' },
            { Id: 101, Name: 'Processing' },
            { Id: 103, Name: 'Failed' }],
            dateTypes: [
                { Id: 1, Name: 'Process Date' },
                { Id: 2, Name: 'Created Date' }],
            pullModes: [
                { Name: "Ftp", Id: 0 },
                { Name: "Sftp", Id: 1 },
                { Name: "Email", Id: 3 },
                { Name: "Webservice", Id: 4 },
                { Name: "DataEntry", Id: 5 }
            ],
            airlines: [],
            records: null,
            pageSize: 10,
            currentPage: 1,
            totalCount: 0
        }
    }

    componentDidMount() {
        const { user } = this.props;
        const reduxState = store.getState();
        let { airlinesCarriers } = reduxState;
        if (user) {
            if (airlinesCarriers) {
                this.setState({ airlines: airlinesCarriers.Results })
            } else {
                this.fetchAirlines();
            }
            this.handleSearch();
        }

    }

    componentWillReceiveProps() {
        let { airlinesCarriers } = store.getState();
        if (airlinesCarriers) {
            this.setState({ airlines: airlinesCarriers.Results });
        }
    }

    fetchAirlines = () => {
        const { dispatch } = this.props;
        dispatch(commonActions.getAirlinesCarriers())
            .then(() => {
                let { airlinesCarriers } = this.props;
                this.setState({ airlines: airlinesCarriers.Results });
            }).catch(error => {
                let errorMessage = appHelpers.interpretErrorResponse(error);
                dispatch(alertActions.error(errorMessage))
            });
    }

    // handle apply click on date picker
    handleApply = (event, picker) => {
        this.setState({
            startDate: picker.startDate,
            endDate: picker.endDate
        })
    }


    handleAirlineChange = (selected) => {
        this.setState({ selectedAirline: (selected) ? selected.Id : "" })
    };

    handleStatusChange = (selected) => {
        this.setState({ selectedStatus: (selected) ? selected.Id : "" })
    };

    handleDateTypeChange = (selected) => {
        this.setState({ selectedDateType: (selected) ? selected.Id : "" })
    };

    handlePullModeChange = (selected) => {
        this.setState({ selectedPullMode: (selected) ? selected.Name : "" })
    };

    handleSearch = (pageNumber = 1) => {
        let { startDate, endDate, selectedAirline, selectedStatus,
            selectedDateType, selectedPullMode, pageSize } = this.state;
        const { dispatch } = this.props;
        // if (!payload) {
        let parameter = {
            "StartDate": moment(startDate).format('YYYY-MM-DD') + "T00:00:00",
            "EndDate": moment(endDate).format('YYYY-MM-DD') + "T23:59:59"
        };

        if (selectedAirline) {
            parameter.AirlineId = selectedAirline;
        }
        if (selectedDateType) {
            parameter.DateType = selectedDateType;
        }
        if (selectedStatus) {
            parameter.Status = selectedStatus;
        }
        if (selectedPullMode) {
            parameter.PullMode = selectedPullMode;
        }

        let from = (pageNumber - 1) * pageSize;

        let payload = {
            "PageSize": pageSize,
            "From": from,
            "Parameter": parameter
        }
        // }

        dispatch(alertActions.startRequest());

        commonService.fetchManifestList(payload)
            .then(
                res => {
                    if (res.status === appConstants.SUCCESS_RESPONSE) {
                        const response = res.response;

                        this.setState({
                            currentPage: pageNumber,
                            totalCount: response.TotalCount,
                            records: response.records
                        });
                        dispatch(alertActions.stopRequest());
                    }
                    else if (res.status === appConstants.ERROR_RESPONSE) {
                        dispatch(alertActions.error(res.response));
                        dispatch(alertActions.stopRequest());
                    }
                },
                error => {
                    let errorMessage = appHelpers.interpretErrorResponse(error);
                    dispatch(alertActions.stopRequest());
                    dispatch(alertActions.error(errorMessage))
                }
            );
    };

    render() {
        const { startDate, endDate, records, statuses, airlines, dateTypes,
            totalCount, currentPage, pageSize, pullModes } = this.state;

        return (
            <AppLayout title={"Avicollect"} activeMenuIndex={2}>
                 <Row>
                    <Col md={12}>
                        <PrimaryTab menus={avicollectMenu} activeMenuIndex={2} />
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className={'manifest-list'}>
                        <Card title={'Manifest List'} dynamicHeight={true}>
                            <SearchPanel
                                startDate={startDate}
                                endDate={endDate}
                                statuses={statuses}
                                airlines={airlines}
                                dateTypes={dateTypes}
                                pullModes={pullModes}
                                handleEvent={this.handleEvent}
                                handleApply={this.handleApply}
                                handleStatusChange={this.handleStatusChange}
                                handleAirlineChange={this.handleAirlineChange}
                                handleDateTypeChange={this.handleDateTypeChange}
                                handlePullModeChange={this.handlePullModeChange}
                                handleSearch={this.handleSearch}
                            />

                            {(totalCount > 0) && <ManifestListTable
                                records={records}
                                currentPage={currentPage}
                                pageSize={pageSize}
                                fetchRecords={this.handleSearch}
                                totalCount={totalCount}
                            />}
                            {(totalCount === 0) && <EmptyRecord
                                message={'No manifest record found.'}
                            />}
                        </Card>
                    </Col>
                </Row>
            </AppLayout>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const { modal, airlinesCarriers, requesting, user } = state;
    return {
        requesting,
        modal,
        airlinesCarriers,
        user
    };
}
export default connect(mapStateToProps)(ManifestList);
