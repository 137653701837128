import React, { Component } from "react";
import DatetimeRangePicker from "react-bootstrap-datetimerangepicker-edited";
import "bootstrap-daterangepicker/daterangepicker.css";
import { Button } from "react-bootstrap";
import "./datepicker.css";
import { FiCalendar, FiChevronDown } from "react-icons/fi";
import moment from "moment";

const ranges = {
  "Last 1 Hour": [moment().subtract(1, "hours"), moment()],
  "Last 6 Hours": [moment().subtract(6, "hours"), moment()],
  "Last 12 Hours": [moment().subtract(12, "hours"), moment()],
  Today: [moment(), moment()],
  Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
  "Last 7 Days": [moment().subtract(6, "days"), moment()],
  "Last 30 Days": [moment().subtract(29, "days"), moment()],
  "This Month": [moment().startOf("month"), moment().endOf("month")],
  "Last Month": [
    moment()
      .subtract(1, "month")
      .startOf("month"),
    moment()
      .subtract(1, "month")
      .endOf("month")
  ]
};

const setDefinedDateRangeLabel = (durationAsHours, start, end) => {
  switch (durationAsHours) {
    case 6:
      return "Last 6 Hours";
    case 12:
      return "Last 12 Hours";
    case 1:
      return "Last 1 Hour";
    case 144:
      return "Last 7 Days";
    case 696:
      return "Last 30 Days";
    default:
      break;
  }
  /*
   ***Cater for other cases
   * */
  start = start.format("MMM DD, YYYY");
  end = end.format("MMM DD, YYYY");
  const today = moment().format("MMM DD, YYYY");
  const yesterday = moment()
    .subtract(1, "days")
    .format("MMM DD, YYYY");
  let dateRangeLabel = start + " - " + end;
  if (start === end && start === today) {
    dateRangeLabel = "Today";
  } else if (start === end && start === yesterday) {
    dateRangeLabel = "Yesterday";
  } else if (
    start ===
      moment()
        .startOf("month")
        .format("MMM DD, YYYY") &&
    end ===
      moment()
        .endOf("month")
        .format("MMM DD, YYYY")
  ) {
    dateRangeLabel = "This Month";
  } else if (
    start ===
      moment()
        .subtract(1, "month")
        .startOf("month")
        .format("MMM DD, YYYY") &&
    end ===
      moment()
        .subtract(1, "month")
        .endOf("month")
        .format("MMM DD, YYYY")
  ) {
    dateRangeLabel = "Last Month";
  } else if (start === end) {
    dateRangeLabel = start;
  }
  return dateRangeLabel;
};

class DatePickerComponent extends Component {
  //   constructor(props) {
  //     super(props);
  //   }

  shouldBlur = e => {
    if (
      e.keyCode === 37 ||
      e.keyCode === 38 ||
      e.keyCode === 39 ||
      e.keyCode === 40
    ) {
      e.target.blur();
    }
  };

  render() {
    const {
      startDate,
      endDate,
      onApply,
      onEvent,
      isDatePicker,
      isMonthPicker
      //   onCancel,
      //   onHide
    } = this.props;

    let dateRangeLabel = "";
    if (isMonthPicker) {
      dateRangeLabel = moment(startDate).format("MMMM YYYY");
    } else if (isDatePicker) {
      dateRangeLabel = moment(startDate).format("MMMM DD, YYYY");

    } else  {
      const durationAsHours = Math.floor(
        moment.duration(endDate.diff(startDate)).asHours()
      );
      dateRangeLabel = setDefinedDateRangeLabel(
        durationAsHours,
        startDate,
        endDate
      );
    }

    return (
      <div>
        {isDatePicker && (
          <DatetimeRangePicker
            singleDatePicker
            startDate={startDate}
            // endDate={endDate}
            onApply={onApply}
            onEvent={onEvent}
            drops={"up"}
            opens={"right"}

            // ranges={ranges}
          >
            <Button
              className="selected-date-range-btn btn-block"
              onKeyDown={this.shouldBlur}
              onKeyUp={this.shouldBlur}
            >
              <div className="pull-left">
                <span>
                  <FiCalendar />
                </span>
                &nbsp; <span className="text-capitalize">{dateRangeLabel}</span>
              </div>
              <div className="pull-right">
                <span>
                  <FiChevronDown />
                </span>
              </div>
            </Button>
          </DatetimeRangePicker>
        )}
        {isMonthPicker && (
          <DatetimeRangePicker
            singleDatePicker
            startDate={startDate}
            // endDate={endDate}
            onApply={onApply}
            onEvent={onEvent}
            showDropdowns={true}
            alwaysShowCalendars={false}
            opens={"right"}
            // ranges={ranges}
          >
            <Button
              className="selected-date-range-btn btn-block"
              onKeyDown={this.shouldBlur}
              onKeyUp={this.shouldBlur}
            >
              <div className="pull-left">
                <span>
                  <FiCalendar />
                </span>
                &nbsp; <span className="text-capitalize">{dateRangeLabel}</span>
              </div>
              <div className="pull-right">
                <span>
                  <FiChevronDown />
                </span>
              </div>
            </Button>
          </DatetimeRangePicker>
        )}
        {!isDatePicker && !isMonthPicker && (
          <DatetimeRangePicker
            timePicker
            startDate={startDate}
            endDate={endDate}
            onApply={onApply}
            onEvent={onEvent}
            ranges={ranges}
          >
            <Button
              className="selected-date-range-btn btn-block"
              onKeyDown={this.shouldBlur}
              onKeyUp={this.shouldBlur}
            >
              <div className="pull-left">
                <span>
                  <FiCalendar />
                </span>
                &nbsp; <span className="text-capitalize">{dateRangeLabel}</span>
              </div>
              <div className="pull-right">
                <span>
                  <FiChevronDown />
                </span>
              </div>
            </Button>
          </DatetimeRangePicker>
        )}
      </div>
    );
  }
}

// const DatePickerComponent = ({
//   startDate,
//   endDate,
//   onApply,
//   onCancel,
//   onEvent,
//   onHide
// }) => {
//   const durationAsHours = Math.floor(
//     moment.duration(endDate.diff(startDate)).asHours()
//   );
//   let dateRangeLabel = setDefinedDateRangeLabel(
//     durationAsHours,
//     startDate,
//     endDate
//   );
//   return (
//     <div>
//       <DatetimeRangePicker
//         timePicker
//         startDate={startDate}
//         endDate={endDate}
//         onApply={onApply}
//         onEvent={onEvent}
//         ranges={ranges}
//       >
//         <Button
//           className="selected-date-range-btn btn-block"
//           onKeyDown={e => e.preventDefault()}
//           onKeyUp={e => e.preventDefault()}
//         >
//           <div className="pull-left">
//             <span>
//               <FiCalendar />
//             </span>
//             &nbsp; <span className="text-capitalize">{dateRangeLabel}</span>
//           </div>
//           <div className="pull-right">
//             <span>
//               <FiChevronDown />
//             </span>
//           </div>
//         </Button>
//       </DatetimeRangePicker>
//     </div>
//   );
// };

export default DatePickerComponent;
