import { commonConstants } from '../_constants';
import { initialState } from '../_store';

export function airlinesCarriers(state = initialState.airlinesCarriers, action) {
    switch (action.type) {
        case commonConstants.AIRLINES_CARRIERS_SUCCESS:
            return Object.assign({}, state, action.airlinesCarriers);
        default:
            return state
    }
}
