import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import Card from "../../components/card/index";
import AppLayout from "../layout/index";
import moment from "moment";
import { store } from "../../_store";
import { commonActions, alertActions } from "../../_actions";
import { appHelpers, graphHelpers } from "../../_helpers";
import { appConstants } from "../../_constants";
import { commonService } from "../../_services";
import { connect } from "react-redux";
import { EmptyRecord } from "../../components/pageutility";
import { PrimaryTab, SecondaryTab } from "../../components/tabs";
// import { withRouter } from 'react-router-dom'
import { FiNavigation, FiHome } from "react-icons/fi";
import SearchPanel from "./searchPanel";
import TscTrackerTable from "./tscTrackerTable";

const menus = [
  {
    name: "International",
    link: "/tsc-value-tracker",
    icon: <FiNavigation style={{ fontSize: "20px" }} />
  },
  {
    name: "Domestic",
    link: "/tsc-value-tracker",
    icon: <FiHome style={{ fontSize: "20px" }} />
  }
];

const status = [
  {
    Id: 0,
    Name: "All Status"
  },
  {
    Id: false,
    Name: "Issues Exist"
  },
  {
    Id: true,
    Name: "Good"
  }
];

const avicollectMenu = [
  {
    name: "Manifest Process",
    link: "/"
  },
  {
    name: "Manifest List",
    link: "/manifestList"
  },
  {
    name: "Manifest Aggregate",
    link: "/manifestAggregate"
  },
  {
    name: "Flag Metrics",
    link: "/international-flag-metrics"
  },
  {
    name: "TSC Value Tracker",
    link: "/tsc-value-tracker"
  }
];

class intlTscValueTracker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: status,
      startDate: moment(),
      // endDate: moment(),
      airlines: [],
      records: [],
      pageSize: 10,
      currentPage: 1,
      totalCount: 0,
      activeSubTab: 1,
      selectedAirline: null,
      flightNumbers: [],
      selectedFlightNumber: null,
      airports: [],
      selectedAirport: null,
      isLocal: false
    };
  }

  componentDidMount() {
    const { user } = this.props;
    const reduxState = store.getState();
    let { airlinesCarriers } = reduxState;
    if (user) {
      if (airlinesCarriers) {
        let modifiedAirlines = [...airlinesCarriers.Results];
        modifiedAirlines.unshift({ Name: "All Airlines", Id: "-1" });
        this.setState({ airlines: modifiedAirlines });
      } else {
        this.fetchAirlines();
      }
      this.handleTscSearch(1, "initial");
    }

    // if (airports) {
    //     let modifiedAirport = [...airports];
    //     modifiedAirport.unshift({ Name: "All Airport", Code: "-1" });
    //     this.setState({ airports: airports })
    // } else {
    //     dispatch(commonActions.getAllAirports())
    // }
  }

  componentWillReceiveProps() {
    let { airlinesCarriers } = store.getState();
    if (airlinesCarriers) {
      let modifiedAirlines = [...airlinesCarriers.Results];
      modifiedAirlines.unshift({ Name: "All Airlines", Id: "-1" });
      this.setState({ airlines: modifiedAirlines });
    }

    // if (airports) {
    //     let modifiedAirport = [...airports];
    //     modifiedAirport.unshift({ Name: "All Airport", Code: "-1" });
    //     this.setState({ airports: modifiedAirport })
    // }
  }

  fetchAirlines = () => {
    const { dispatch } = this.props;
    dispatch(commonActions.getAirlinesCarriers())
      .then(() => {
        let { airlinesCarriers } = this.props;
        this.setState({ airlines: airlinesCarriers.Results });
      })
      .catch(error => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        dispatch(alertActions.error(errorMessage));
      });
  };

  fetchAirlineFlightNumbers = airlineId => {
    const { dispatch } = this.props;
    commonService.getAirlinesFlightNumbers(airlineId).then(res => {
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        let modifiedFlights = [...response.Results];
        let renameKey = graphHelpers.renamePropertyKeys(
          modifiedFlights,
          "FlightNumber",
          "Name"
        );

        renameKey.unshift({ Name: "All Flights", Id: "-1" });
        this.setState({ flightNumbers: renameKey });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  handleAirlineChange = selected => {
    // const {selectedAirline} = this.state
    this.setState({ selectedAirline: selected ? selected.Id : "" }, () => {
      if (selected !== null) {
        this.fetchAirlineFlightNumbers(selected.Id);
      }
    });
  };

  handleStatusChange = selected => {
    this.setState({ selectedStatus: selected ? selected.Id : "" });
  };

  // handleFlightChange = (selected) => {
  //     this.setState({ selectedFlightNumber: (selected) ? selected.Id : "" })
  // };

  handleAirportChange = selected => {
    this.setState({ selectedAirport: selected ? selected.Code : "" });
  };
  // handle apply click on date picker
  handleApply = (event, picker) => {
    this.setState({
      startDate: picker.startDate,
      // endDate: picker.endDate
    });
  };

  //for TSC Value count tracker table
  handleTscSearch = (pageNumber, initial) => {
    let {
      startDate,
      selectedStatus,
      pageSize,
      selectedAirline,
      isLocal
    } = this.state;

    let fromIndex;

    let currentPage;

    if (pageNumber === undefined) {
      fromIndex = 0;
      pageNumber = 1;
    } else {
      fromIndex = pageSize * (pageNumber - 1);
    }

    if (initial) {
      currentPage = 1;
      fromIndex = 0;
    } else {
      currentPage = pageNumber;
    }

    const { dispatch } = this.props;
    let parameter = {
      DateTime: moment(startDate).format("YYYY-MM-DD"),
      isLocal: isLocal
    };

    if (selectedStatus !== 0) {
      parameter.Status = selectedStatus;
    }

    if (selectedAirline !== "-1") {
      parameter.AirlineId = selectedAirline;
    }

    let payload = {
      PageSize: pageSize,
      From: fromIndex,
      Parameter: parameter
    };

    dispatch(alertActions.startRequest());

    commonService.getTscFlaggingStatus(payload).then(
      res => {
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          const response = res.response;

          this.setState({
            currentPage: currentPage,
            totalCount: response.TotalCount,
            records: response.Results
          });
          dispatch(alertActions.stopRequest());
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(alertActions.error(res.response));
          dispatch(alertActions.stopRequest());
        }
      },
      error => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        dispatch(alertActions.stopRequest());
        dispatch(alertActions.error(errorMessage));
      }
    );
  };

  changeSubTab = (e, activeSubTab) => {
    e.preventDefault();
    this.setState({ activeSubTab: activeSubTab + 1 }, () => this.changeView());
  };

  changeView = () => {
    const { history } = this.props;
    const { activeSubTab } = this.state;

    if (activeSubTab === 1) {
      history.push("/tsc-value-tracker");
      this.setState({ isLocal: false }, () => {
        this.handleTscSearch();
      });
    } else if (activeSubTab === 2) {
      history.push("/tsc-value-tracker");
      this.setState({ isLocal: true }, () => {
        this.handleTscSearch();
      });
    }
  };

  render() {
    const {
      status,
      startDate,
      // endDate,
      records,
      currentPage,
      pageSize,
      totalCount,
      activeSubTab,
      airlines,
      isLocal
    } = this.state;

    return (
      <AppLayout title={"Avicollect"} activeMenuIndex={5}>
        <Row>
          <Col md={12}>
            <PrimaryTab menus={avicollectMenu} />
          </Col>
        </Row>
        <Row>
          <Col md={6} className={"manifest-list"}>
            <SecondaryTab
              menus={menus}
              activeMenuIndex={activeSubTab}
              handleClick={this.changeSubTab}
            />
          </Col>
        </Row>

        {isLocal && (
          <Row>
            <Col md={12} className={"manifest-list"}>
              <Card title={"TSC Value Domestic Tracker"} dynamicHeight={true}>
                <SearchPanel
                  startDate={startDate}
                  // endDate={endDate}
                  statuses={status}
                  airlines={airlines}
                  handleAirlineChange={this.handleAirlineChange}
                  handleEvent={this.handleEvent}
                  handleApply={(e, p) =>
                    this.handleApply(
                      e,
                      p,
                      appConstants.AIRLINE_DAILY_FLIGHT_TRACKER
                    )
                  }
                  handleStatusChange={this.handleStatusChange}
                  handleSearch={this.handleTscSearch}
                />
                {totalCount > 0 && (
                  <TscTrackerTable
                    titlePrefix="TSC Value Domestic Tracker"
                    records={records}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    fetchRecords={this.handleTscSearch}
                    totalCount={totalCount}
                    dateTime={startDate}
                    recordDate={startDate}
                    isLocal={isLocal}
                  />
                )}
                {totalCount === 0 && (
                  <EmptyRecord message={"No daily TSC record found."} />
                )}
              </Card>
            </Col>
          </Row>
        )}
        {isLocal === false && (
          <Row>
            <Col md={12} className={"manifest-list"}>
              <Card
                title={"TSC Value International Tracker"}
                dynamicHeight={true}
              >
                <SearchPanel
                  startDate={startDate}
                  // endDate={endDate}
                  statuses={status}
                  airlines={airlines}
                  handleAirlineChange={this.handleAirlineChange}
                  handleEvent={this.handleEvent}
                  handleApply={(e, p) =>
                    this.handleApply(
                      e,
                      p,
                      appConstants.AIRLINE_DAILY_FLIGHT_TRACKER
                    )
                  }
                  handleStatusChange={this.handleStatusChange}
                  handleSearch={this.handleTscSearch}
                />
                {totalCount > 0 && (
                  <TscTrackerTable
                    titlePrefix="TSC Value International Tracker"
                    records={records}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    fetchRecords={this.handleTscSearch}
                    totalCount={totalCount}
                    dateTime={startDate}
                    recordDate={startDate}
                    isLocal={isLocal}
                  />
                )}
                {totalCount === 0 && (
                  <EmptyRecord message={"No daily TSC record found."} />
                )}
              </Card>
            </Col>
          </Row>
        )}
      </AppLayout>
    );
  }
}

function mapStateToProps(state) {
  const { modal, airlinesCarriers, requesting, user, airports } = state;
  return {
    requesting,
    modal,
    airlinesCarriers,
    user,
    airports
  };
}
export default connect(mapStateToProps)(intlTscValueTracker);

/* Written with love by Juliet on July 2019 */
