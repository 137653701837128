import React, { Component } from 'react';
import {
    CartesianGrid, Tooltip, Legend, ResponsiveContainer,
    BarChart, Bar, XAxis, YAxis
} from "recharts";
import { appConstants } from "../../_constants";
import "./index.css"
import { appHelpers } from "../../_helpers";



const ResponsiveBarChartView = ({ barChartWidth, data, legends, renderToolTip, formatLabel,
    handleMouseEnter, handleMouseLeave }) => (
        <ResponsiveContainer width={"100%"} height={405}>
            <BarChart width={barChartWidth} height={405} data={data}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                <CartesianGrid stroke={"#015c75"} strokeDasharray="0 0" />
                <defs>
                    {
                        legends.map((l, key) => {
                            return (
                                <linearGradient key={key} id={l.colorId} x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={l.color} stopOpacity={0.8} />
                                    <stop offset="95%" stopColor={l.color} stopOpacity={0} />
                                </linearGradient>
                            )
                        })
                    }
                </defs>
                <XAxis allowDataOverflow={true} dataKey="name" stroke={"#b5b5b5"} />
                <YAxis stroke={"#b5b5b5"} />
                <Tooltip cursor={false} formatter={renderToolTip}
                    labelFormatter={(value) => formatLabel(value)} />
                <Legend onMouseEnter={(e) => handleMouseEnter(e)}
                    onMouseLeave={(e) => handleMouseLeave(e)} />

                {
                    legends.map((legend, key) => {
                        const fill = `url(#${legend.colorId})`;
                        return (
                            <Bar barSize={50} dataKey={legend.dataKey} fill={fill} key={key} />
                        )
                    })
                }
            </BarChart>
        </ResponsiveContainer>
    )


const BarChartView = ({ barChartWidth, data, legends, renderToolTip, formatLabel,
    handleMouseEnter, handleMouseLeave }) => (
        <BarChart width={barChartWidth} height={405} data={data}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <CartesianGrid stroke={"#015c75"} strokeDasharray="0 0" />
            <defs>
                {
                    legends.map((l, key) => {
                        return (
                            <linearGradient key={key} id={l.colorId} x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor={l.color} stopOpacity={0.8} />
                                <stop offset="95%" stopColor={l.color} stopOpacity={0} />
                            </linearGradient>
                        )
                    })
                }
            </defs>
            <XAxis allowDataOverflow={true} dataKey="name" stroke={"#b5b5b5"} />
            <YAxis stroke={"#b5b5b5"} />
            <Tooltip cursor={false} formatter={renderToolTip}
                labelFormatter={(value) => formatLabel(value)} />
            <Legend onMouseEnter={(e) => handleMouseEnter(e)}
                onMouseLeave={(e) => handleMouseLeave(e)} />

            {
                legends.map((legend, key) => {
                    const fill = `url(#${legend.colorId})`;
                    return (
                        <Bar barSize={50} dataKey={legend.dataKey} fill={fill} key={key} />
                    )
                })
            }
        </BarChart>
    )


class BarChartGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opacity: {},
        }
    }

    componentDidMount() {
        const { legends } = this.props;
        let opacity = {};
        //set legends opacity
        legends.map(l => opacity[l['name']] = appConstants.GRAPH_FOCUS_OPACITY);
        this.setState({
            opacity
        });
    }

    handleMouseEnter(o) {
        const { dataKey } = o;
        const { opacity } = this.state;
        this.setState({
            opacity: { ...opacity, [dataKey]: appConstants.GRAPH_FOCUS_OPACITY },
        });
    }

    handleMouseLeave(o) {
        const { dataKey } = o;
        const { opacity } = this.state;
        this.setState({
            opacity: { ...opacity, [dataKey]: appConstants.GRAPH_DEFAULT_OPACITY },
        });
    }

    renderToolTip = (value, name, props) => {
        return [appHelpers.numberWithCommasOnly(value)];
    };

    render() {
        // const { opacity } = this.state;
        let { data, legends, responsive } = this.props;
        data = (data) ? data : [];
        let barChartWidth = (data.length * 100 > 1200) ? data.length * 100 : 1200;

        return (
            <div style={{ overflowX: "auto" }}>
                {responsive ? <ResponsiveBarChartView
                    barChartWidth={barChartWidth}
                    data={data}
                    legends={legends}
                    renderToolTip={this.renderToolTip}
                    formatLabel={this.props.formatLabel}
                    handleMouseEnter={this.handleMouseEnter}
                    handleMouseLeave={this.handleMouseLeave}
                /> :

                    <BarChartView
                        barChartWidth={barChartWidth}
                        data={data}
                        legends={legends}
                        renderToolTip={this.renderToolTip}
                        formatLabel={this.props.formatLabel}
                        handleMouseEnter={this.handleMouseEnter}
                        handleMouseLeave={this.handleMouseLeave}
                    />
                }
            </div>
        );
    }
}

export default BarChartGraph
