import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import './index.css';
// import Bell from '../../assets/image/bell.svg';
// import Arrow from '../../assets/image/angle-arrow-down.svg';
import Avatar from "../../assets/image/avatar.jpeg"
import {FiPower}  from 'react-icons/fi'

// const NavIcon = (props) => (
//         <div className="img-icon">
//             <img src={props.src} className="img" alt="icons"/>
//         </div>
// )

const Navbar = (props) => {

  let {handleMenu, title, user, logout} = props;


    return(
        <div className="pd-tp-50">
    <Container fluid={true}>
        <Row>
                        <Col xs={8}>
                            <div onClick={handleMenu} className="menu">&#9776; <span className="app-title">{title}</span></div>
                        </Col>
                        <Col xs={4}>
                            <div className="nav-flex">
                                <div className="img-user-cover">
                                    <img alt={'img'} src={Avatar} className="img-fluid circle"/>
                                </div>
                                <div>
                                    <h6 className={'username'}>{user.fullName}</h6>
                                </div>
                                <div className={'logout'} onClick={logout}>
                                    <FiPower />
                                </div>
                                {/*<NavIcon*/}
                                    {/*src = {Arrow}*/}
                                {/*/>*/}
                           {/*<NavIcon*/}
                               {/*src={Bell}*/}
                           {/*/>*/}
                            </div>
                        </Col>
                    </Row>
    </Container>
        </div>
    )

}

export default Navbar
