import React, {Component} from 'react';
import {
    Modal,
} from "react-bootstrap";
import {appHelpers} from "../../_helpers";


export default class recordModal extends Component {
    render() {
        const { headerData,  recordStartDate, drillDownTotalCount, titlePrefix} = this.props;
        return (
            <Modal
                {...this.props}
                backdrop
                aria-labelledby="contained-modal-title-vcenter"
                centered
                dialogClassName={'modal-90w'}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <span className={'modal-title'}>
                            {`${titlePrefix} for ${headerData.AirlineName}`}
                            <span className={'time'}>
                                 {`Total Count: ${drillDownTotalCount}`}
                            </span>
                            <span className={'time'}>
                            {`Flight Date: ${appHelpers.formatDateSpecific(recordStartDate)}`}
                             </span>
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.children}
                </Modal.Body>
            </Modal>
        );
    }
}

/* Written with love by Juliet on July 2019 */
