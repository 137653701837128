import React from 'react'
// import DatePicker from '../../components/datepicker';
import SelectPicker from '../../components/selectpicker'
import { Button } from 'react-bootstrap';
import { FiSearch } from 'react-icons/fi';

const SearchPanel = ({ flightStartDate, flightEndDate, handleEvent,
                         handleApply, handleSearch, statuses, departureAirport,
                         selectedAirport, handleAirportChange, drillDownStatus,
                         selectedDrillDownStatus, handleDrillDownStatusChange

                     }) => {

    return (
        <div className="row">
            {/*<div className="col-md-4">*/}
                {/*<DatePicker startDate={flightStartDate}*/}
                            {/*endDate={flightEndDate}*/}
                            {/*onEvent={handleEvent}*/}
                            {/*onApply={handleApply}*/}
                {/*/>*/}
            {/*</div>*/}
            <div className="col-md-4">
                <SelectPicker
                    options={departureAirport}
                    placeholder="Departure Airport"
                    selectedValue={selectedAirport}
                    handleChange={handleAirportChange}
                    customKeys={true}
                />
            </div>
            <div className="col-md-2">
                <SelectPicker
                    options={drillDownStatus}
                    placeholder="Select Status"
                    selectedValue={selectedDrillDownStatus}
                    handleChange={handleDrillDownStatusChange}
                    customKeys={true}
                />
            </div>

            <div className="col-md-2">
                <Button className="btn-search btn-block" onClick={() => handleSearch()}><span><FiSearch /></span> &nbsp; Search</Button>
            </div>

        </div>
    )
};

export default SearchPanel

/* Written with love by Juliet on July 2019 */