import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Home from "../src/views/homepage";
import ManifestProcess from "../src/views/manifestProcess";
import ManifestList from "../src/views/manifestList";
import Login from "../src/views/Login";
import AvicollectVsPSC from "./views/Avicollect-PSC";
import PDMPSC from "./views/PDM-PSC";
import FlightVsSettlement from "./views/Flight-Settlement";
import AirlineFlightTrackerTable from "./views/international-flagMetrics";
import DomesticFlagMetrics from "./views/Domestic-flagMetrics";
import TscValueTracker from "./views/tsc-value-tracker";
// import DomesticTscValueTracker from "./views/tsc-value-tracker"

import ManifestAggregate from "../src/views/manifestAggregate";
export default (
    <Switch>
        <Route component={ManifestProcess} path='/' exact/>
        <Route component={ManifestProcess} path='/manifestProcess' exact/>
        <Route component={ManifestList} path='/manifestList' exact/>
        <Route component={Login} path='/login' exact/>
        <Route component={ManifestAggregate} path='/manifestAggregate' exact/>
        <Route component={AvicollectVsPSC} path='/avicollect-psc' exact/>
        <Route component={PDMPSC} path='/pdm-psc' exact/>
        <Route component={FlightVsSettlement} path='/flight-settlement' exact/>
        <Route component={AirlineFlightTrackerTable} path='/international-flag-metrics' exact/>
        <Route component={DomesticFlagMetrics} path='/domestic-flag-metrics' exact/>
        <Route component={TscValueTracker} path='/tsc-value-tracker' exact/>
        {/*<Route component={DomesticTscValueTracker} path='/domestic-tsc-value' exact/>*/}
        <Route component={Home} path='/app'/>
    </Switch>
);
