export const initialState = {
    alert: {},
    activity: {},
    requesting: false,
    manifestList: false,
    airlinesCarriers: false,
    user: false,
    users: false,
    airports: false
};
