import React , { Component } from 'react';
import 'react-vertical-timeline-component/style.min.css';
import TimelineHeader from "../../../components/timeline/timelineHeader";
import Aggregate from "./steps/aggregation";
import InvoicingComponent from "./steps/invoicing";
import SpriteComponent from "./steps/sprite";
import VigipayComponent from "./steps/vigipay";

class TimelineComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 1,
            steps: [],
            lastStep : false,
            totalStep: 0
        }
    }

    componentDidMount(){
        const steps = [
            {
                name: 'Aggregation',
                status: 'completed'
            },
            {
                name: 'Invoicing',
                status: 'failed'
            },
            {
                name: 'Sprite',
                status: 'failed'
            },
            {
                name: 'Vigipay',
                status: 'failed'
            }
        ];
        this.setState({steps})
    }

    switchStep = (activeStep) => {
        this.setState({activeStep}, () =>{
            this.renderStepComponent();
        });

    }

    renderStepComponent = () => {
        const { activeStep } = this.state;
        const { Aggregation, Invoicing, Sprite, Vigipay, CurrencyName } = this.props.timelineData;
        const {airline} = this.props;
        switch (activeStep) {
            case 1:
                return <Aggregate CurrencyName={CurrencyName} Aggregation={Aggregation} airline={airline}/>;
            case 2:
                return <InvoicingComponent CurrencyName={CurrencyName} Invoicing={Invoicing}/>;
            case 3:
                return <SpriteComponent CurrencyName={CurrencyName} Sprite={Sprite} />;
            case 4:
                return <VigipayComponent CurrencyName={CurrencyName} Vigipay={Vigipay} />;
            default:
                return <Aggregate CurrencyName={CurrencyName} Aggregation={Aggregation}/>;
        }
    }

    render() {
        const { timelineData } = this.props;
        const { activeStep } = this.state;
        if(!timelineData)
            return "";

        const { Aggregation, Invoicing, Sprite, Vigipay } = timelineData;
        const steps = [
            {
                name: 'Aggregation',
                status: (Aggregation) ? 'completed' : 'failed'
            },
            {
                name: 'Invoicing (BDA)',
                status: (Invoicing === null) ? 'inactive'  : (Invoicing) ? 'completed' : 'failed'
            },
            {
                name: 'Sprite',
                status: (Sprite === null) ? 'inactive'  : (Sprite) ? 'completed' : 'failed'
            },
            {
                name: 'Vigipay',
                status: (Vigipay === null) ? 'inactive'  : (Vigipay.Status === '00') ? 'completed' : 'failed'
            }
        ];

        return (
            <div>
                <TimelineHeader
                    steps={steps}
                    activeStep={activeStep}
                    handleSwitchStep={this.switchStep}
                />
                {
                    this.renderStepComponent()
                }

            </div>
        );
    }
}


export default TimelineComponent;
/*

const TimelineComponent = ({ timelineData, airline }) => {
    if(!timelineData)
        return "";

    const { Aggregation, Invoicing, Sprite, Vigipay, CurrencyName } = timelineData;

    const steps = [
        {
            name: 'Aggregation',
            status: 'completed'
        },
        {
            name: 'Invoicing',
            status: 'failed'
        },
        {
            name: 'Sprite',
            status: 'failed'
        },
        {
            name: 'Vigipay',
            status: appConstants.SIGN_OUT
        }
    ];
    return (
        <div>
            <TimelineHeader
                steps={steps}
                Aggregation={Aggregation}
                Invoicing={Invoicing}
                Sprite={Sprite}
                Vigipay={Vigipay}
            />

            <VerticalTimeline>
                <VerticalTimelineElement
                    date={(<h3 className={'stepName'} style={AggregationIconStepNameStyle}>Aggregation</h3>)}
                    iconStyle={AggregationIconStepNameStyle}
                    icon={<FiStar />}
                />

                <VerticalTimelineElement
                     className="vertical-timeline-element--work"
                     date={(Aggregation) ? moment(Aggregation.CreatedDate).fromNow() : moment().fromNow()}
                     iconStyle={(!Aggregation) ? ProcessErrorIconStepStyle : AggregationIconStepStyle }
                     icon={<FiAirplay/>}
                >
                    <p className="vertical-timeline-element-paragraph">

                          {appHelpers.numberWithCommasOnly(Aggregation.TotalManifests)} manifests was aggregated from {airline} file under
                          <strong> {appHelpers.formatMinutesDescription(Aggregation.Duration)}</strong>
                    </p>
                    <h5 className="vertical-timeline-element-subtitle">
                        {'Manifest Aggregation'}
                    </h5>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                     className="vertical-timeline-element--work"
                     date={(Aggregation) ? moment(Aggregation.CreatedDate).fromNow() : moment().fromNow()}
                     iconStyle={(!Aggregation) ? ProcessErrorIconStepStyle : AggregationIconStepStyle }
                     icon={<FiFileText/>}
                >
                    <p className="vertical-timeline-element-paragraph">
                        <strong>{appHelpers.numberWithCommasOnly(Aggregation.TotalAmount)} ({CurrencyName}) </strong> was calculated as TSC under
                        <strong> {appHelpers.formatMinutesDescription(Aggregation.Duration)}</strong>
                    </p>
                    <h5 className="vertical-timeline-element-subtitle">{'TSC Calculation'}</h5>
                </VerticalTimelineElement>

                {/!*
                 INVOICE NUMBER
                 *!/}
                <VerticalTimelineElement
                    date={(<h3 className={'stepName'} style={InvoicingIconStepNameStyle}>Invoicing</h3>)}
                    iconStyle={InvoicingIconStepNameStyle}
                    icon={<FiStar />}
                />

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(Invoicing) ? moment(Invoicing.CreatedDate).fromNow() : moment().fromNow()}
                    iconStyle={(!Invoicing) ? ProcessErrorIconStepStyle : InvoicingIconStepStyle }
                    icon={<FiHash/>}
                >
                    <p className="vertical-timeline-element-paragraph">
                        {
                            (Invoicing) &&
                            <span>
                                {Invoicing.ReferenceNumber}
                            </span>
                        }
                        {
                            (!Invoicing) &&
                            <span className={'text-muted'}>
                               No Ref. number yet.
                            </span>
                        }
                    </p>
                    <h5 className="vertical-timeline-element-subtitle">{'Reference'}</h5>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(Invoicing) ? moment(Invoicing.CreatedDate).fromNow() : moment().fromNow()}
                    iconStyle={(!Invoicing) ? ProcessErrorIconStepStyle : InvoicingIconStepStyle }
                    icon={<FiDollarSign/>}
                >
                    <p className="vertical-timeline-element-paragraph">
                        {
                            (Invoicing) &&
                            <span>
                                 {appHelpers.numberWithCommasOnly(Invoicing.Amount)} ({CurrencyName})
                            </span>
                        }
                        {
                            (!Invoicing) &&
                            <span className={'text-muted'}>
                              Amount not calculated yet!
                            </span>
                        }

                    </p>
                    <h5 className="vertical-timeline-element-subtitle">{'Amount'}</h5>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(Invoicing) ? moment(Invoicing.CreatedDate).fromNow() : moment().fromNow()}
                    iconStyle={(!Invoicing) ? ProcessErrorIconStepStyle : InvoicingIconStepStyle }
                    icon={<FiDisc/>}
                >
                    <p className="vertical-timeline-element-paragraph">
                        {
                            (Invoicing && Invoicing.Description) &&
                            <span>
                                {Invoicing.Description}
                            </span>
                        }
                        {
                            (!Invoicing || !Invoicing.Description) &&
                            <span className={'text-muted'}>
                               No description.
                            </span>
                        }
                    </p>
                    <h5 className="vertical-timeline-element-subtitle">{'Description'}</h5>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(Invoicing) ? moment(Invoicing.CreatedDate).fromNow() : moment().fromNow()}
                    iconStyle={(!Invoicing) ? ProcessErrorIconStepStyle : InvoicingIconStepStyle }
                    icon={<FiDisc/>}
                >
                    <p className="vertical-timeline-element-paragraph">
                        {
                            (Invoicing) &&
                            <strong>{appHelpers.numberWithCommasOnly(Invoicing.Attempts)}</strong>
                        }
                        {
                            (!Invoicing && !Invoicing.Attempts) &&
                            <strong>
                               0
                            </strong>
                        }
                    </p>
                    <h5 className="vertical-timeline-element-subtitle">{'Number of Attempts'}</h5>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(Invoicing) ? moment(Invoicing.CreatedDate).fromNow() : moment().fromNow()}
                    iconStyle={(!Invoicing) ? ProcessErrorIconStepStyle : InvoicingIconStepStyle }
                    icon={<FiDisc/>}
                >
                    {
                        Invoicing &&
                        <p className="vertical-timeline-element-paragraph">
                            It took <strong>{appHelpers.formatMinutesDescription(Invoicing.Duration)}</strong> to invoice manifest aggregate.
                        </p>
                    }

                    <h5 className="vertical-timeline-element-subtitle">{'Summary'}</h5>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    date={(<h3 className={'stepName'} style={SpriteStepNameStyle}>Sprite</h3>)}
                    iconStyle={SpriteStepNameStyle}
                    icon={<FiStar />}
                />

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(Sprite) ? moment(Sprite.CreatedDate).fromNow() : moment().fromNow()}
                    iconStyle={SpriteIconStepStyle}
                    icon={<FiHash/>}
                >
                    <p className="vertical-timeline-element-paragraph">
                        {
                            (Sprite) &&
                            <span>
                                {Sprite.ReferenceNumber}
                            </span>
                        }
                        {
                            (!Sprite) &&
                            <span className={'text-muted'}>
                               No reference number yet.
                            </span>
                        }
                    </p>
                    <h5 className="vertical-timeline-element-subtitle">{'Reference'}</h5>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(Sprite) ? moment(Sprite.CreatedDate).fromNow() : moment().fromNow()}
                    iconStyle={(!Sprite) ? ProcessErrorIconStepStyle : InvoicingIconStepStyle }
                    icon={<FiDisc/>}
                >
                    {
                        !Sprite &&
                        <p className="vertical-timeline-element-paragraph">
                            Invoice not sent to Sprite yet!
                        </p>
                    }
                    {
                        Sprite &&
                        <p className="vertical-timeline-element-paragraph">
                            It took <strong>{appHelpers.formatMinutesDescription(Sprite.Duration)}</strong> to receive response from Sprite.
                        </p>
                    }
                    <h5 className="vertical-timeline-element-subtitle">{'Summary'}</h5>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    date={(<h3 className={'stepName'} style={VigipayStepNameStyle}>Vigipay</h3>)}
                    iconStyle={VigipayStepNameStyle}
                    icon={<FiStar />}
                />

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(Vigipay) ? moment(Vigipay.SentDate).fromNow() : moment().fromNow()}
                    iconStyle={(!Vigipay) ? ProcessErrorIconStepStyle : VigipayIconStepStyle }
                    icon={<FiChevronsRight/>}
                >
                    <p className="vertical-timeline-element-paragraph">
                        {
                            (Vigipay) &&
                            <span>
                                {Vigipay.ReferenceNumber}
                            </span>
                        }
                        {
                            (!Vigipay) &&
                            <span className={'text-muted'}>
                               No reference number yet.
                            </span>
                        }
                    </p>
                    <h5 className="vertical-timeline-element-subtitle">{'Reference'}</h5>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(Vigipay && Vigipay.CallbackNotified) ? moment(Vigipay.CallbackNotifiedDate).fromNow() : moment().fromNow()}
                    iconStyle={(!Vigipay) ? ProcessErrorIconStepStyle : VigipayIconStepStyle }
                    icon={<FiDisc/>}
                >
                    {
                        (Vigipay && Vigipay.CallbackNotified) &&
                        <p className="vertical-timeline-element-paragraph">
                           Payment notified on <strong>{appHelpers.formatDateSpecific(Vigipay.CallbackNotifiedDate)}</strong>.
                        </p>
                    }
                    {
                        !Vigipay &&
                        <p className="vertical-timeline-element-paragraph">
                            Invoice not sent to VigiPay yet!
                        </p>
                    }
                    <h5 className="vertical-timeline-element-subtitle">{'Notification'}</h5>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(Vigipay) ? moment(Vigipay.SentDate).fromNow() : moment().fromNow()}
                    iconStyle={(!Vigipay) ? ProcessErrorIconStepStyle : VigipayIconStepStyle }
                    icon={<FiDisc/>}
                >
                    {
                        Vigipay &&
                            <p className="vertical-timeline-element-paragraph">
                                {Vigipay.StatusDescription}
                            </p>
                    }
                    {
                        !Vigipay &&
                        <p className="vertical-timeline-element-paragraph">
                            No status yet!
                        </p>
                    }
                    <h5 className="vertical-timeline-element-subtitle">{'Status'}</h5>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    date={`That's all.`}
                    iconStyle={{ background: 'rgb(255, 255, 255)', color: '#2992d0' }}
                    icon={<FiStar />}
                />
            </VerticalTimeline>
        </div>
    );
};
export default TimelineComponent;
*/
