import React, { Component } from 'react';
import ServerPagination from "../../components/paginatedtable";
import { appHelpers } from "../../_helpers";
import {
    Table,
} from "react-bootstrap";
import TableCell from '../../components/table/tablecell';
import TimeLineModal from "./timeline/timelineModal"
import {connect} from "react-redux";
import {appConstants} from "../../_constants";
import {commonService} from "../../_services";
import {alertActions, commonActions} from "../../_actions";
import {store} from "../../_store";

const ManifestTableStructure = ({ searchAirline, records, showTimeline }) => (
    <div>
        <Table hover className='table-vgg-darkblue' responsive='sm'>
            <thead>
                <tr>
                    <th>Airline Name</th>
                    <th>Invoice Number</th>
                    <th>Amount</th>
                    <th>Total Manifest</th>
                    {/*<th><FiCheckSquare className={'table-fixchecksquare-icon'} size={'2em'} /></th>*/}
                    {/*<th><FiXSquare className={'table-fixsquare-icon'} size={'2em'} /></th>*/}
                    <th>Date Created</th>
                </tr>
            </thead>
            <tbody>
                {records.map((manifest, index) => {
                    return (
                        <tr className={'pointer'} key={index} onClick={() => showTimeline(manifest)}>
                            <TableCell content={searchAirline(manifest.AirlineId)} />
                            <TableCell content={manifest.InvoiceNumber} />
                            <TableCell content={appHelpers.numberWithCommasOnly(manifest.Amount)} />
                            <TableCell content={appHelpers.numberWithCommasOnly(manifest.ManifestCount)} />
                            {/*<TableCell content={appHelpers.numberWithCommasOnly(manifest.successfulManifest)} />*/}
                            {/*<TableCell content={appHelpers.numberWithCommasOnly(manifest.failedManifest)} />*/}
                            <TableCell content={appHelpers.formatDate(manifest.AggregateDate)} />
                        </tr>
                    )
                })}
            </tbody>

        </Table>
    </div>
)

class PaginatedTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timelineShow: false,
            timelineData: null,
            manifestAggregate: {},
            airlines: [],
            currentAirline: ''
        };
    }
    componentDidMount() {
        const reduxState = store.getState();
        let { airlinesCarriers } = reduxState;
        if (airlinesCarriers) {
            this.setState({ airlines: airlinesCarriers.Results })
        } else {
            this.fetchAirlines();
        }
    }
    fetchAirlines = () => {
        const { dispatch } = this.props;
        dispatch(commonActions.getAirlinesCarriers())
            .then(() => {
                let { airlinesCarriers } = this.props;
                this.setState({ airlines: airlinesCarriers.Results });
            }).catch(error => {
            let errorMessage = appHelpers.interpretErrorResponse(error);
            console.log({ errorMessage });
            // this.notify(errorMessage, appConstants.NOTIFY_ERROR);
            // this.setState({contentError: errorMessage});
        });
    }

    findAirlineName = (airlineId) => {
        const  { airlines } = this.state;
        const airline = airlines.find(item => item.Id === airlineId);
        return (airline) ? airline.Name : 'Unknown Airline';
    }
    hideTimeline = () => {
        this.setState({ timelineShow: false })
    }
    showTimeline = (manifestAggregate) => {
        const  { airlines } = this.state;
        const airline = airlines.find(item => item.Id === manifestAggregate.AirlineId);
        const currentAirline = (airline) ? airline.Name : 'Unknown Airline';
        this.setState({ currentAirline, manifestAggregate },
            () => this.handleSearchTimeline()
        )
    }
    handleSearchTimeline = () => {
        const { dispatch } = this.props;
        dispatch(alertActions.startRequest());
        commonService.fetchAggregateTimeline(this.state.manifestAggregate.Id)
            .then(res => {
                    if (res.status === appConstants.SUCCESS_RESPONSE) {
                        const response = res.response;
                        this.setState({
                            timelineData: response,
                            timelineShow: true
                        });
                        dispatch(alertActions.stopRequest());
                    }
                    else if (res.status === appConstants.ERROR_RESPONSE) {
                        dispatch(alertActions.error(res.response.Message));
                        dispatch(alertActions.stopRequest());
                    }
                },
                error => {
                    let errorMessage = appHelpers.interpretErrorResponse(error);
                    dispatch(alertActions.stopRequest());
                    dispatch(alertActions.error(errorMessage));
                }
            );
    }

    render() {
        const { totalCount, records, currentPage, pageSize,
            fetchRecords } = this.props;
        const { timelineShow, timelineData, manifestAggregate, currentAirline } = this.state;
        return (
            <div>
                <div className="table-responsive" style={{ overflowX: "auto" }}>
                    <ServerPagination fetchMore={fetchRecords}
                        totalCount={totalCount} pageSize={pageSize}
                        currentPage={currentPage}
                        target={<ManifestTableStructure searchAirline={this.findAirlineName} showTimeline={this.showTimeline}
                            records={records} />} />
                </div>
                <TimeLineModal
                    heading={'Manifest Aggregate'}
                    currentairline={currentAirline}
                    timelinedata={timelineData}
                    manifestaggregate={manifestAggregate}
                    show={timelineShow}
                    onHide={this.hideTimeline}
                />
            </div>
        )
    }
}
function mapStateToProps(state) {
    const { modal, requesting, airlinesCarriers } = state;
    return {
        requesting,
        modal,
        airlinesCarriers
    };
}
export default connect(mapStateToProps)(PaginatedTable);
