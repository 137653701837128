import React, { Component } from 'react';
import AppLayout from "../../views/layout";

// components
import { Row, Col } from 'react-bootstrap';
import Card from "../../components/card/index";
import { GraphHeader } from "../../components/graph/graphHeader";
import { PrimaryTab } from '../../components/tabs';

//libraries
import moment from 'moment';
import { appConstants } from '../../_constants';
import BarChartGraph from '../../components/graph/BarGraph';
import { commonService } from '../../_services';
import { alertActions } from '../../_actions';
import { connect } from 'react-redux';
import { graphHelpers, appHelpers } from '../../_helpers';
import PieChartGraph from '../../components/graph/PieGraph';

const pscMenu = [
    {
        name: "Avicollect vs PSC",
        link: "/avicollect-psc"
    },
    {
        name: "PDM Data vs PSC Data",
        link: "/pdm-psc"
    },
    {
        name: "Flight Date vs Settlement Date",
        link: "/flight-settlement"
    }
];


const airlineFlightCountLegends = [
    {
        name: "Sum of PSC Flight",
        color: appConstants.BLUE_COLOR,
        colorId: 'colorPSCFlight',
        dataKey: "PSC"
    },
    {
        name: "Sum of Avicollect Flight",
        color: appConstants.ORANGE_COLOR,
        colorId: 'colorAvicollectFlight',
        dataKey: "Avicollect"
    },
]

const locationFlightVarianceLegends = [
    {
        name: "Sum of PSC Flight",
        color: appConstants.BLUE_COLOR,
        colorId: 'colorPSCFlight',
        dataKey: "PSC"

    },
    {
        name: "Sum of Avicollect Flight",
        color: appConstants.ORANGE_COLOR,
        colorId: 'colorAvicollectFlight',
        dataKey: "Avicollect"
    },
]

// const thisData = [
//     { "AirlineId": 22, "AirlineCode": "NIG", "AirlineName": "Aero Air", "Avicollect": 50, "PSC": 40, "name": "Aero Air" },
//     { "AirlineId": 64, "AirlineCode": "APK", "AirlineName": "Air Peace", "Avicollect": 0, "PSC": 0, "name": "Air Peace" },
//     { "AirlineId": 21, "AirlineCode": "ARA", "AirlineName": "Arik Air", "Avicollect": 0, "PSC": 0, "name": "Arik Air" },
//     { "AirlineId": 4110, "AirlineCode": "AZM", "AirlineName": "Azman Airline", "Avicollect": 0, "PSC": 0, "name": "Azman Airline" },
//     { "AirlineId": 60, "AirlineCode": "9J", "AirlineName": "Dana Air", "Avicollect": 0, "PSC": 0, "name": "Dana Air" },
//     { "AirlineId": 4116, "AirlineCode": "NGL", "AirlineName": "Max Air Limited", "Avicollect": 0, "PSC": 0, "name": "Max Air Limited" },
//     { "AirlineId": 34, "AirlineCode": "MEV", "AirlineName": "Medview", "Avicollect": 0, "PSC": 0, "name": "Medview" },
//     { "AirlineId": 72, "AirlineCode": "OA", "AirlineName": "Overland Airways", "Avicollect": 0, "PSC": 0, "name": "Overland Airways" }
// ];

// const data = [{ name: 'Group A', value: 400 }, { name: 'Group B', value: 300 },
// { name: 'Group C', value: 300 }, { name: 'Group D', value: 200 }];

class AvicollectVsPSC extends Component {
    constructor(props) {
        super(props)

        this.state = {
            flightStartDate: moment().subtract(1, 'month'),
            flightEndDate: moment(),
            locationFlightStartDate: moment().subtract(1, 'month'),
            locationFlightEndDate: moment(),
            airlineFlightCountChartData: false,
            locationFlightVarianceChartData: false,
            airlineFlightCountSummaryData: false,
            locationFlightVarianceSummaryData: false,
        }
    }

    componentDidMount() {
        let { user } = this.props;
        if (user) {
            this.initializePageData()
        }
    }

    initializePageData = () => {
        let { flightStartDate, flightEndDate } = this.state;
        const { dispatch } = this.props;

        flightStartDate = flightStartDate.format("YYYY-MM-DDTHH:mm:ss");
        flightEndDate = flightEndDate.format("YYYY-MM-DDTHH:mm:ss");
        const payload = {
            StartDate: flightStartDate,
            EndDate: flightEndDate,
        }

        Promise.all([
            commonService.fetchAvicollectPSCFlightsByAirline(payload),
            commonService.fetchAvicollectPSCFlightsByLocation(payload)
        ]).then(response => {
            const airlineFlightCountData = response[0];
            const locationFlightVarianceData = response[1];

            // console.log({ airlineFlightCountData })
            // console.log({ locationFlightVarianceData })

            const airlineFlightCountChartData = graphHelpers.addXAxisNames(airlineFlightCountData.response.Result, "AirlineCode");
            const airlineFlightCountSummaryData = graphHelpers.generateSummaryData(airlineFlightCountData.response.Summary);
            // console.log({ airlineFlightCountSummaryData })

            const locationFlightVarianceChartData = graphHelpers.addXAxisNames(locationFlightVarianceData.response.Result, "AirportCode");
            const locationFlightVarianceSummaryData = graphHelpers.generateSummaryData(locationFlightVarianceData.response.Summary);

            // console.log({ airlineFlightCountChartData })
            // console.log({ locationFlightVarianceChartData })

            this.setState({
                airlineFlightCountChartData, locationFlightVarianceChartData,
                airlineFlightCountSummaryData, locationFlightVarianceSummaryData
            },
                () => dispatch(alertActions.stopRequest()))
        })
        dispatch(alertActions.startRequest());
    }

    // handle apply click on date picker
    handleApply = async (event, picker, type) => {

        let StartDate = picker.startDate.format("YYYY-MM-DDTHH:mm:ss");
        let EndDate = picker.endDate.format("YYYY-MM-DDTHH:mm:ss");

        if (StartDate === EndDate) {
            StartDate = picker.startDate.format("YYYY-MM-DDT00:00:00");
            EndDate = picker.endDate.format("YYYY-MM-DDT23:59:59");
        }
        const payload = { StartDate, EndDate };
        try {
            let response; // = await commonService.searchManifestProcessDuration(payload);
            switch (type) {
                case appConstants.AIRLINE_FLIGHT_COUNT:
                    this.setState({
                        flightStartDate: picker.startDate,
                        flightEndDate: picker.endDate
                    }, () => {
                        response = this.searchAirlineFlightCount(payload);
                    });
                    break;
                case appConstants.LOCATION_FLIGHT_VARIANCE:
                    this.setState({
                        locationFlightStartDate: picker.startDate,
                        locationFlightEndDate: picker.endDate
                    }, () => {
                        response = this.searchLocationFlightVariance(payload);
                    });
                    break;
                default:
                    response = {}
            }

            return response;
        } catch (err) {
            console.log({ err });
        }
    };

    searchAirlineFlightCount = async (payload) => {
        // const { flightStartDate, flightEndDate } = this.state;
        const { dispatch } = this.props;
        dispatch(alertActions.startRequest());
        try {
            const response = await commonService.fetchAvicollectPSCFlightsByAirline(payload);
            if (response.status === appConstants.SUCCESS_RESPONSE) {
                const airlineFlightCountChartData = graphHelpers.addXAxisNames(response.response.Result, "AirlineCode");
                const airlineFlightCountSummaryData = graphHelpers.generateSummaryData(response.response.Summary);

                // console.log({ airlineFlightCountChartData })
                this.setState({ airlineFlightCountChartData, airlineFlightCountSummaryData })
            } else if (response.status === appConstants.ERROR_RESPONSE) {
                dispatch(alertActions.error(response.response));
                // console.log(response.response)
            }
            dispatch(alertActions.stopRequest());

        } catch (err) {
            dispatch(alertActions.stopRequest());
            dispatch(alertActions.error(err))
        }
    }

    searchLocationFlightVariance = async (payload) => {
        // const { flightStartDate, flightEndDate } = this.state;
        const { dispatch } = this.props;
        dispatch(alertActions.startRequest());
        try {
            const response = await commonService.fetchAvicollectPSCFlightsByLocation(payload);
            if (response.status === appConstants.SUCCESS_RESPONSE) {
                const locationFlightVarianceChartData = graphHelpers.addXAxisNames(response.response.Result, "AirportCode");
                const locationFlightVarianceSummaryData = graphHelpers.generateSummaryData(response.response.Summary);

                // console.log({ locationFlightVarianceChartData })
                this.setState({ locationFlightVarianceChartData, locationFlightVarianceSummaryData })
            } else if (response.status === appConstants.ERROR_RESPONSE) {
                dispatch(alertActions.error(response.response));
                // console.log(response.response)
            }
            dispatch(alertActions.stopRequest());

        } catch (err) {
            dispatch(alertActions.stopRequest());
            dispatch(alertActions.error(err))
        }
    }

    // formatLabel = (value, data, initialLabelkey, finalLabelKey) => {
    //     let labelObject = data.find((item) => item[initialLabelkey] === value)
    //     console.log(labelObject)
    //     return labelObject[finalLabelKey]
    // }

    render() {
        const { flightStartDate, flightEndDate, locationFlightStartDate, locationFlightEndDate,
            airlineFlightCountChartData, locationFlightVarianceChartData,
            airlineFlightCountSummaryData, locationFlightVarianceSummaryData } = this.state;
        return (
            <AppLayout>
                <Row>
                    <Col md={12}>
                        <PrimaryTab menus={pscMenu} activeMenuIndex={1} />
                    </Col>
                </Row>
                <Row>
                    <Col md={9}>
                        <Card title={<GraphHeader title={"Airline Flight Count (Avicollect vs PSC)"}
                            startDate={flightStartDate}
                            endDate={flightEndDate}
                            handleEvent={this.handleEvent}
                            handleApply={(e, p) => this.handleApply(e, p, appConstants.AIRLINE_FLIGHT_COUNT)} />}>
                            <BarChartGraph
                                legends={airlineFlightCountLegends}
                                data={airlineFlightCountChartData}
                                formatLabel={(value) => appHelpers.formatToolTipLabel(value, airlineFlightCountChartData, "AirlineCode", "AirlineName")}
                            />
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card title={"Airline Flight Count (Avicollect vs PSC)"}>
                            {airlineFlightCountSummaryData && <PieChartGraph data={airlineFlightCountSummaryData} />}
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={9}>
                        <Card title={<GraphHeader title={"Location Flight Variance (Avicollect VS PSC)"}
                            startDate={locationFlightStartDate}
                            endDate={locationFlightEndDate}
                            handleEvent={this.handleEvent}
                            handleApply={(e, p) => this.handleApply(e, p, appConstants.LOCATION_FLIGHT_VARIANCE)} />}>
                            <BarChartGraph
                                legends={locationFlightVarianceLegends}
                                data={locationFlightVarianceChartData}
                                formatLabel={(value) => appHelpers.formatToolTipLabel(value, locationFlightVarianceChartData, "AirportCode", "AirportName")}
                            />
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card title={"Location Flight Variance (Avicollect VS PSC)"}>
                            {locationFlightVarianceSummaryData && <PieChartGraph data={locationFlightVarianceSummaryData} />}
                        </Card>
                    </Col>
                </Row>
            </AppLayout>
        );
    }
}

function mapStateToProps(state) {
    const { requesting, user } = state;
    return {
        requesting,
        user
    };
}

export default connect(mapStateToProps)(AvicollectVsPSC)