import React from 'react';
import { Row, Col
} from "react-bootstrap";
import "./index.scss"
import {FiInfo} from "react-icons/fi"

const noResponse = ({message}) => {
    return (
        <div className={"noResponse-container"}>
            <Row >
                <Col md={12}  >
                    <FiInfo className={"no-data-icon"}/>
                    <h3>{message}</h3>
                </Col>
            </Row>
        </div>
    );
};

export default noResponse;