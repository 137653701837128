import React, { Component } from 'react';
import {
    Row, Col
} from "react-bootstrap";
import { FiPlusSquare, FiClock, FiActivity } from "react-icons/fi";
import moment from 'moment';
import Card from "../../components/card/index";
import { AreaChartGraph, LineChartGraph } from "../../components/graph";
import SummaryCard from "../../components/summaryCards/index";
import AppLayout from "../layout/index";
import { SecondaryTab } from "../../components/tabs";
import GraphHeader from "./graphHeader";
import { alertActions } from '../../_actions';
import { connect } from "react-redux";
import { commonService } from "../../_services";
import { appConstants } from "../../_constants";
import { appHelpers, graphHelpers } from "../../_helpers";
import ManifestListTable from "../manifestList/manifestTable";
import { EmptyRecord } from "../../components/pageutility";
import { PrimaryTab } from '../../components/tabs';


const avicollectMenu = [
    {
        name: "Manifest Process",
        link: "/"
    },
    {
        name: "Manifest List",
        link: "/manifestList"
    },
    {
        name: "Manifest Aggregate",
        link: "/manifestAggregate"
    },
    {
        name: "Flag Metrics",
        link: "/international-flag-metrics"
    },
    {
        name: "TSC Value Tracker",
        link: "/tsc-value-tracker"
    }
];

const menus = [
    {
        name: "Manifest Processing Status",
        link: "/",
        icon: <FiActivity style={{ fontSize: "20px" }} />
    },
    {
        name: "Manifest Processing Time",
        link: "/",
        icon: <FiClock style={{ fontSize: "20px" }} />
    },
    // {
    //     name: "Pull Modes",
    //     link: "/",
    //     icon: <FiUploadCloud style={{ fontSize: "20px" }} />

    // }
];

const manifestDurationLegends = [
    {
        name: "<10",
        color: appConstants.GREEN_COLOR,
        colorId: 'colorSuccess'
    },
    {
        name: "10-30",
        color: appConstants.ORANGE_COLOR,
        colorId: 'colorFair'
    },
    {
        name: ">30",
        color: appConstants.RED_COLOR,
        colorId: 'colorCritical'
    }
];
const manifestStatusLegends = [
    {
        name: "Successful",
        color: appConstants.GREEN_COLOR,
        colorId: 'colorSuccess'
    },
    {
        name: "Processing",
        color: appConstants.YELLOW_COLOR,
        colorId: 'colorProcessing'
    },
    {
        name: "Pending",
        color: appConstants.ORANGE_COLOR,
        colorId: 'colorPending'
    },
    {
        name: "Partial",
        color: appConstants.BLUE_COLOR,
        colorId: 'colorPartial'
    },
    {
        name: "Failed",
        color: appConstants.RED_COLOR,
        colorId: 'colorFailed'
    }
];

const manifestPullModesLegends = [
    {
        name: "Ftp",
        color: appConstants.GREEN_COLOR,
        colorId: 'colorFtp'
    },
    {
        name: "Sftp",
        color: appConstants.YELLOW_COLOR,
        colorId: 'colorSftp'
    },
    {
        name: "Email",
        color: appConstants.ORANGE_COLOR,
        colorId: 'colorEmail'
    },
    {
        name: "Webservice",
        color: appConstants.PURPLE_COLOR,
        colorId: 'colorWebservice'
    },
    {
        name: "DataEntry",
        color: appConstants.RED_COLOR,
        colorId: 'colorDataEntry'
    }
];

class ManifestProcess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSubTab: 1,
            manifestProcessStartDate: moment().subtract(6, 'hours'),
            manifestProcessEndDate: moment(),
            manifestStatusStartDate: moment().subtract(6, 'hours'),
            manifestStatusEndDate: moment(),
            manifestPullModesStartDate: moment().subtract(6, 'hours'),
            manifestPullModesEndDate: moment(),
            manifestProcessDurationChartData: false,
            manifestProcessDurationBars: false,
            totalManifestProcessOnDuration: 0,
            manifestStatusChartData: false,
            manifestStatusChartBars: false,
            totalManifestStatus: 0,
            totalManifestPullModes: 0,
            manifestStatusSuccessBar: false,
            manifestDurationSuccessBar: false,
            manifestPullModesBar: false,
            manifestPullModesChartBars: false,
            manifestPullModesSuccessChartBars: false,
            manifestPullModesChartData: false,
            currentPage: 1,
            totalCount: 0,
            manifestFiles: [],
            timeSpentOnManifestProcessingDuration: 0,
            manifestProcessMaxTime: 0,
            manifestProcessMinTime: 0,
            manifestProcessAvgTime: 0,
            pageSize: 10
        }
    }

    componentDidMount() {
        //initialize Graphs
        let { user } = this.props;
        // console.log('user', user);
        if (user) {
            this.initializePageData();
        }
    }

    initializePageData = () => {
        let { manifestProcessStartDate, manifestProcessEndDate } = this.state;
        const { dispatch } = this.props;
        manifestProcessStartDate = manifestProcessStartDate.format("YYYY-MM-DDTHH:mm");
        manifestProcessEndDate = manifestProcessEndDate.format("YYYY-MM-DDTHH:mm");
        const duration = moment.duration(moment(manifestProcessEndDate).diff(manifestProcessStartDate)).asDays();

        const payload = { startDate: manifestProcessStartDate, endDate: manifestProcessEndDate };
        Promise.all([
            commonService.searchManifestProcessDuration(payload),
            commonService.searchManifestStatus(payload),
            commonService.searchManifestPullModes(payload),
            this.handleManifestFileSearch()
        ]
        ).then(response => {
            const manifestProcessDuration = response[0];
            const manifestStatus = response[1];
            const manifestPulModes = response[2];
            /* Manifest Process Duration */
            // const manifestProcessDurationSummaryLegends = manifestProcessDuration.response.summary.slice(0, 3);
            delete manifestProcessDuration.response.summary.TotalDuration;
            delete manifestProcessDuration.response.summary.total;
            let manifestDurationReform = graphHelpers.progressBars(manifestProcessDuration.response.summary, appConstants.MANIFEST_DURATION_BARS_VARIANTS);
            const manifestProcessDurationBars = manifestDurationReform.progressBars;
            const totalManifestProcessOnDuration = manifestDurationReform.total;
            const manifestDurationSuccessBar = manifestProcessDurationBars.filter(item => item.name === appConstants.MANIFEST_DURATION_SUCCESS_BAR)[0];
            const manifestProcessDurationChartData = graphHelpers.chartData(manifestProcessDuration.response.records, duration);
            const { Average, Maximum, Minimum } = manifestProcessDuration.response.statistics;
            /* Manifest Status */
            let manifestStatusReform = graphHelpers.progressBars(manifestStatus.response.summary, appConstants.MANIFEST_STATUS_BARS_VARIANTS);
            const manifestStatusChartBars = manifestStatusReform.progressBars;
            const totalManifestStatus = manifestStatusReform.total;
            const manifestStatusSuccessBar = manifestStatusChartBars.filter(item => item.name === appConstants.MANIFEST_STATUS_SUCCESS_BAR)[0];
            const manifestStatusChartData = graphHelpers.chartData(manifestStatus.response.records, duration);

            /* Manifest Pullmodes */
            let manifestPullModesReform = graphHelpers.progressBars(manifestPulModes.response.summary, appConstants.MANIFEST_PULL_MODES_BARS_VARIANTS);
            const manifestPullModesChartBars = manifestPullModesReform.progressBars;
            const totalManifestPullModes = manifestPullModesReform.total;
            const successValue = (totalManifestPullModes === 0) ? 0 : 100;
            const manifestPullModesSuccessChartBars = { percent: successValue, value: successValue };
            const manifestPullModesChartData = graphHelpers.chartData(manifestPulModes.response.records, duration);

            this.setState({
                manifestDurationSuccessBar, manifestProcessDurationBars, totalManifestProcessOnDuration, manifestProcessDurationChartData,
                manifestStatusChartData, manifestStatusSuccessBar, manifestStatusChartBars, totalManifestStatus,
                manifestPullModesChartData, totalManifestPullModes, manifestPullModesSuccessChartBars, manifestPullModesChartBars,
                manifestProcessMaxTime: Maximum, manifestProcessMinTime: Minimum, manifestProcessAvgTime: Average
            }, () => {
                dispatch(alertActions.stopRequest());
            });

        }).catch(err => {
            dispatch(alertActions.stopRequest());
            dispatch(alertActions.error(err))
        })
        dispatch(alertActions.startRequest());
    };

    handleManifestFileSearch = (pageNumber = 1) => {
        const { dispatch } = this.props;
        const { pageSize } = this.state;

        let parameter = {
            "EndDate": moment().format('YYYY-MM-DD') + "T23:59:59",
            "StartDate": moment().subtract(7, 'days').format('YYYY-MM-DD') + "T00:00:00"
            , Status: 101
        };

        let from = (pageNumber - 1) * pageSize;

        let payload = {
            "PageSize": pageSize,
            "From": from,
            "Parameter": parameter
        }

        commonService.fetchManifestList(payload)
            .then(
                res => {
                    if (res.status === appConstants.SUCCESS_RESPONSE) {
                        const response = res.response;
                        this.setState({
                            currentPage: pageNumber,
                            totalCount: response.TotalCount,
                            manifestFiles: response.records
                        });
                    }
                    else if (res.status === appConstants.ERROR_RESPONSE) {
                        dispatch(alertActions.error(res.response))
                    }
                },
                error => {
                    let errorMessage = appHelpers.interpretErrorResponse(error);
                    console.log({ errorMessage });
                    dispatch(alertActions.error(errorMessage))
                }
            );
    };


    // handle apply click on date picker
    handleApply = async (event, picker, type) => {
        // this.setState({ startDate: picker.startDate, endDate: picker.endDate });

        let StartDate = picker.startDate.format("YYYY-MM-DDTHH:mm");
        let EndDate = picker.endDate.format("YYYY-MM-DDTHH:mm");

        if (StartDate === EndDate) {
            StartDate = picker.startDate.format("YYYY-MM-DDT00:00");
            EndDate = picker.endDate.format("YYYY-MM-DDT23:59:59");
        }
        const payload = { StartDate, EndDate };
        try {
            let response; // = await commonService.searchManifestProcessDuration(payload);
            switch (type) {
                case appConstants.MANIFEST_PROCESS_DURATION:
                    this.setState({
                        manifestProcessStartDate: picker.startDate,
                        manifestProcessEndDate: picker.endDate
                    }, () => {
                        response = this.searchManifestProcessDuration(payload);
                    });
                    break;
                case appConstants.MANIFEST_STATUS:
                    this.setState({
                        manifestStatusStartDate: picker.startDate,
                        manifestStatusEndDate: picker.endDate
                    }, () => {
                        response = this.searchManifestStatus(payload);
                    });
                    break;
                case appConstants.MANIFEST_PULL_MODES:
                    this.setState({
                        manifestPullModesStartDate: picker.startDate,
                        manifestPullModesEndDate: picker.endDate
                    }, () => {
                        response = this.searchManifestPullModes(payload);
                    });
                    break;
                default:
                    response = {}
            }

            return response;
        } catch (err) {
            console.log({ err });
        }

    };

    searchManifestProcessDuration = async (payload) => {
        let { manifestProcessStartDate, manifestProcessEndDate } = this.state;
        const { dispatch } = this.props;

        const duration = moment.duration(moment(manifestProcessEndDate).diff(manifestProcessStartDate)).asDays();
        dispatch(alertActions.startRequest());
        try {
            const response = await commonService.searchManifestProcessDuration(payload);
            if (response.status === appConstants.SUCCESS_RESPONSE) {
                delete response.response.summary.TotalDuration;
                delete response.response.summary.total;
                const { Average, Maximum, Minimum } = response.response.statistics;

                let { progressBars, total } = graphHelpers.progressBars(response.response.summary, appConstants.MANIFEST_DURATION_BARS_VARIANTS);
                //check for the success bar
                const manifestDurationSuccessBar = progressBars.filter(item => item.name === appConstants.MANIFEST_DURATION_SUCCESS_BAR)[0];
                /*Chart Data*/
                const manifestProcessDurationChartData = graphHelpers.chartData(response.response.records, duration);
                this.setState(
                    {
                        manifestProcessMaxTime: Maximum, manifestProcessMinTime: Minimum, manifestProcessAvgTime: Average,
                        manifestProcessDurationChartData, manifestDurationSuccessBar, manifestProcessDurationBars: progressBars, totalManifestProcessOnDuration: total
                    }
                );
            } else if (response.status === appConstants.ERROR_RESPONSE) {
                dispatch(alertActions.error(response.response))
            }
            dispatch(alertActions.stopRequest());
        } catch (err) {
            dispatch(alertActions.stopRequest());
            dispatch(alertActions.error(err))

        }
    };

    searchManifestStatus = async (payload) => {
        let { manifestStatusStartDate, manifestStatusEndDate } = this.state;
        const { dispatch } = this.props;
        const duration = moment.duration(moment(manifestStatusEndDate).diff(manifestStatusStartDate)).asDays();
        dispatch(alertActions.startRequest());
        try {
            const response = await commonService.searchManifestStatus(payload);
            if (response.status === appConstants.SUCCESS_RESPONSE) {
                let { progressBars, total } = graphHelpers.progressBars(response.response.summary, appConstants.MANIFEST_STATUS_BARS_VARIANTS);
                // check for the success bar
                const manifestStatusSuccessBar = progressBars.filter(item => item.name === appConstants.MANIFEST_STATUS_SUCCESS_BAR)[0];
                this.setState({ manifestStatusSuccessBar, manifestStatusChartBars: progressBars, totalManifestStatus: total });

                /*Chart Data*/
                const manifestStatusChartData = graphHelpers.chartData(response.response.records, duration);
                this.setState({ manifestStatusChartData });
            } else if (response.status === appConstants.ERROR_RESPONSE) {
                dispatch(alertActions.error(response.response))

            }
            dispatch(alertActions.stopRequest());
        } catch (err) {
            dispatch(alertActions.stopRequest());
            dispatch(alertActions.error(err))

        }
    };

    searchManifestPullModes = async (payload) => {
        let { startDate, endDate } = this.state;
        const { dispatch } = this.props;
        if (!payload) {
            startDate = startDate.format("YYYY-MM-DDTHH:mm");
            endDate = endDate.format("YYYY-MM-DDTHH:mm");
            payload = { startDate, endDate };
        }
        const duration = moment.duration(moment(endDate).diff(startDate)).asDays();
        dispatch(alertActions.startRequest());
        try {
            const response = await commonService.searchManifestPullModes(payload);
            if (response.status === appConstants.SUCCESS_RESPONSE) {
                let { progressBars, total } = graphHelpers.progressBars(response.response.summary, appConstants.MANIFEST_PULL_MODES_BARS_VARIANTS);
                // check for the success bar
                const successValue = (total === 0) ? 0 : 100;
                const manifestPullModesSuccessChartBars = { percent: successValue, value: successValue };

                /*Chart Data*/
                const manifestPullModesChartData = graphHelpers.chartData(response.response.records, duration);
                this.setState({
                    manifestPullModesChartData,
                    manifestPullModesSuccessChartBars, manifestPullModesChartBars: progressBars, totalManifestPullModes: total
                });
            } else if (response.status === appConstants.ERROR_RESPONSE) {
                dispatch(alertActions.error(response.response))

            }
            dispatch(alertActions.stopRequest());
        } catch (err) {
            dispatch(alertActions.stopRequest());
            dispatch(alertActions.error(err))

        }
    };
    changeSubTab = (e, activeSubTab) => {
        e.preventDefault();
        this.setState({ activeSubTab: (activeSubTab + 1) })
    };
    render() {
        const { activeSubTab, manifestStatusStartDate, manifestStatusEndDate, manifestProcessStartDate, manifestProcessEndDate, manifestPullModesStartDate, manifestPullModesEndDate,
            manifestProcessDurationBars, manifestDurationSuccessBar, totalManifestProcessOnDuration, manifestProcessDurationChartData,
            manifestStatusChartBars, manifestStatusSuccessBar, totalManifestStatus, manifestStatusChartData,
            manifestPullModesChartBars, manifestPullModesSuccessChartBars, manifestPullModesChartData, totalManifestPullModes,
            manifestFiles, totalCount, currentPage, pageSize, manifestProcessAvgTime, manifestProcessMaxTime, manifestProcessMinTime } = this.state;

        return (
            <AppLayout title={"Avicollect"} activeMenuIndex={1}>
                <Row>
                    <Col md={12}>
                        <PrimaryTab menus={avicollectMenu} activeMenuIndex={1} />
                    </Col>
                </Row>
                <Row>
                    <Col md={9}>
                        <SecondaryTab menus={menus} activeMenuIndex={activeSubTab} handleClick={this.changeSubTab} />
                    </Col>
                </Row>

                {/* Manifest Processing Status Chart */}
                {(activeSubTab === 1) &&
                    <Row>
                        <Col md={9}>
                            <Card title={<GraphHeader title={"Manifest Processing Status"} startDate={manifestStatusStartDate} endDate={manifestStatusEndDate} handleEvent={this.handleEvent} handleApply={(e, p) => this.handleApply(e, p, appConstants.MANIFEST_STATUS)} />}>
                                <AreaChartGraph legends={manifestStatusLegends} data={manifestStatusChartData} />
                            </Card>

                        </Col>
                        <Col md={3}>
                            {
                                (manifestStatusChartBars && manifestStatusSuccessBar) &&
                                <SummaryCard title={'Manifest Processing Status'}
                                    bars={manifestStatusChartBars}
                                    animation={true}
                                    percentage={manifestStatusSuccessBar.percent}
                                    firstText={'Total Processed Successfully'}
                                    totalCountSuccessful={manifestStatusSuccessBar.value}
                                    firstValue={totalManifestStatus}
                                    secondText={false}
                                    secondValue={false}
                                    icon1={<FiPlusSquare />}
                                    icon2={<FiClock />}
                                />
                            }
                        </Col>
                    </Row>}

                {/*Manifest Processing Time Chart*/}
                {
                    (activeSubTab === 2) &&
                    <Row>
                        <Col md={9}>
                            <Card title={<GraphHeader title={"Manifest Processing Time"} startDate={manifestProcessStartDate} endDate={manifestProcessEndDate} handleEvent={this.handleEvent} handleApply={(e, p) => this.handleApply(e, p, appConstants.MANIFEST_PROCESS_DURATION)} />}>
                                <AreaChartGraph legends={manifestDurationLegends} data={manifestProcessDurationChartData} />
                            </Card>
                        </Col>
                        <Col md={3}>
                            {
                                manifestProcessDurationBars &&
                                <SummaryCard title={'Manifest Processing Time'}
                                    bars={manifestProcessDurationBars}
                                    animation={true}
                                    percentage={manifestDurationSuccessBar.percent}
                                    firstText={'Total Processed within 10 mins'}
                                    totalCountSuccessful={manifestDurationSuccessBar.value}
                                    firstValue={totalManifestProcessOnDuration}
                                    secondText={'Min / Avg / Max'}
                                    secondValue={`${Math.ceil(manifestProcessMinTime)} / ${Math.ceil(manifestProcessAvgTime)} / ${Math.ceil(manifestProcessMaxTime)}`}
                                    // secondValue={`${Math.floor(moment.duration(manifestProcessEndDate.diff(manifestProcessStartDate)).asHours())} hours`}
                                    icon1={<FiPlusSquare />}
                                    icon2={<FiClock />}
                                />
                            }
                        </Col>

                    </Row>
                }

                {/* Manifest Pull Modes */}
                <Row>

                    <Col md={9}>
                        <Card title={<GraphHeader title={"Manifest Pull Modes"} startDate={manifestPullModesStartDate} endDate={manifestPullModesEndDate} handleEvent={this.handleEvent} handleApply={(e, p) => this.handleApply(e, p, appConstants.MANIFEST_PULL_MODES)} />}>
                            <LineChartGraph legends={manifestPullModesLegends} data={manifestPullModesChartData} />
                        </Card>
                    </Col>
                    <Col md={3}>
                        {
                            (manifestPullModesChartBars && manifestPullModesSuccessChartBars) &&
                            <SummaryCard title={'Manifest Pull Modes'}
                                bars={manifestPullModesChartBars}
                                animation={true}
                                percentage={manifestPullModesSuccessChartBars.percent}
                                totalCountSuccessful={false}
                                firstText={'Total Processed'}
                                firstValue={totalManifestPullModes}
                                secondText={false}
                                secondValue={false}
                                icon1={<FiPlusSquare />}
                                icon2={<FiClock />}
                            />
                        }
                    </Col>
                </Row>

                <Row>
                    <Col md={12} className={'failed-manifest-card'} >
                        <Card title={`Manifests Under Processing`} dynamicHeight={true}>
                            {(totalCount > 0) && <ManifestListTable
                                records={manifestFiles}
                                currentPage={currentPage}
                                pageSize={pageSize}
                                fetchRecords={this.handleManifestFileSearch}
                                totalCount={totalCount}
                            />}
                            {(totalCount === 0) && <EmptyRecord
                                message={'No file under processing for the last 7 days.'}
                            />}
                        </Card>
                    </Col>

                </Row>
            </AppLayout>
        );
    }
}

function mapStateToProps(state, ownProps) {
    const { requesting, user } = state;
    return {
        requesting,
        user
    };
}

export default connect(mapStateToProps)(ManifestProcess);
