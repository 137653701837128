import React, {Component} from 'react';
import "./index.css"
import {
    ProgressBar,
    Row,
    Col
} from "react-bootstrap";
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import CardComponent from "../card/index"
import {appHelpers} from "../../_helpers";
class SummaryCardComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            totalBars: 0,
            currentSlide: 1,
            totalSlides: [],
            slideSize: 5,
            currentBars: [],
            bars: [],
        }
    }

    componentDidMount(){
        const { bars } = this.props;
        this.setBars(bars);
    }

    componentDidUpdate(prevProps) {
        if (this.props.bars !== prevProps.bars) {
            this.setBars(this.props.bars);
        }
    }

    setBars = (bars) => {
        const { slideSize, currentSlide } = this.state;
        let start = (currentSlide - 1) * slideSize;
        let end = currentSlide * slideSize;
        const currentBars = bars.slice(start, end);
        const barsLength = bars.length;
        this.setState({ currentBars, totalSlides: new Array(Math.ceil(barsLength/slideSize)).fill('Slide'), bars, totalBars: barsLength })
    };

    setSlideBars = (currentSlide) => {
        const { slideSize, bars} = this.state;
        let start = (currentSlide - 1) * slideSize;
        let end = currentSlide * slideSize;
        const currentBars = bars.slice(start, end);
        this.setState({ currentSlide, currentBars, bars })

    };
    render() {
        let color;
        const {title, animation, percentage, firstText , firstValue,
             secondValue, icon1, totalCountSuccessful, secondText } = this.props;
        const { currentBars, totalSlides, currentSlide } = this.state;

        if (percentage === 100){
            color = <CircularProgressbar
                percentage={percentage}
                text={`${percentage}%`}
                styles={{
                    path: { stroke: `rgb(81, 183, 75)` },
                    text: { fill: '#fff', fontSize: '16px' },
                }}
            />

        } else if (percentage < 100 && percentage <= 60){
            color = <CircularProgressbar
                percentage={percentage}
                text={`${percentage}%`}
                styles={{
                    path: { stroke: `rgb(255,133, 27)` },
                    text: { fill: '#fff', fontSize: '16px' },
                }}
            />
        } else{
            color = <CircularProgressbar
                percentage={percentage}
                text={`${percentage}%`}
                styles={{
                    path: { stroke: `rgb(224,70, 70)` },
                    text: { fill: '#fff', fontSize: '16px' },
                }}
            />
        }

        return (
            <div>
                <CardComponent title={title}>
                    <div className={'card-upper-body'}>
                        <Row>
                            <Col md="6">
                                <div>
                                    {color}
                                </div>
                            </Col>
                            <Col md="6">
                                <div className={'card-text'}>
                                    <div>
                                        <p className={'text-above'}><span className={'card-icon'}>{icon1}</span>{firstText}</p>
                                        <h5 className={'value-below'}>
                                            { totalCountSuccessful !== false &&
                                                <span>
                                                    {totalCountSuccessful} /&nbsp;
                                                </span>
                                            }
                                             {appHelpers.numberWithCommasOnly(firstValue)}
                                        </h5>
                                    </div>
                                    <div className={'line'}></div>
                                    {
                                        secondValue &&
                                        <div>
                                            <p className={'text-above'}>
                                                {/*<span className={'card-icon'}>{icon2}</span> */}
                                                {secondText}
                                            </p>
                                            <h5 className={'value-below'}>{secondValue}</h5>
                                            <p className={'text-above'}>
                                                (Minutes)
                                            </p>
                                        </div>
                                    }

                                </div>
                            </Col>
                        </Row>
                    </div>
                    {
                        currentBars.map((item, key) => {
                            return (
                                <div className={'progress-bar-div'} key={key}>
                                    <div>
                                        <p className={'progress-bar-name'}>{item.name}</p>
                                        <p className={'progress-bar-value'}>{appHelpers.numberWithCommasOnly(item.value)}</p>
                                        <div className="clearfix">&nbsp;</div>
                                    </div>
                                    <ProgressBar variant={item.variant} now={item.percent}  animated={animation} />
                                </div>
                            )
                        })
                    }
                    {
                        (totalSlides.length > 1) &&
                            <div className="summary-slider">
                                {
                                    totalSlides.map((item, key)=> {
                                        return (
                                            <span key={key} onClick={()=> this.setSlideBars(key + 1)}
                                                  className={(currentSlide === (key + 1)) ? 'summary-slide-pointer active' : 'summary-slide-pointer'}>
                                                -
                                            </span>
                                        )
                                    })
                                }
                            </div>
                    }

                </CardComponent>
            </div>

        );
    }
}

export default SummaryCardComponent;
