import React from 'react';
import './progressloader.css'
import LoadingIcon from './loading-icon.gif'

const ProgressLoader = ({ message }) => {
    return (
        <div className="overlay">
            <div className="loading-icon">
                <img src={LoadingIcon} alt="loading icon" />
                <h5 className="text-center">{message}</h5>
            </div>
        </div>
    )
}

export default ProgressLoader
