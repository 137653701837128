import React, { Component } from "react";
import ServerPagination from "../../components/paginatedtable";
import { appHelpers, graphHelpers } from "../../_helpers";
import { Table, Badge } from "react-bootstrap";
import TableCell from "../../components/table/tablecell";
import { appConstants } from "../../_constants";
import { alertActions, commonActions } from "../../_actions";
import { commonService } from "../../_services";
import { connect } from "react-redux";
import RecordModal from "./recordModal";
import DrillDownTable from "./tscTrackerDrillDownTable";
import { store } from "../../_store";
import moment from "moment";

const renderStatus = status => {
  switch (status) {
    case false:
      return <Badge variant="danger">Issues Exist</Badge>;
    case true:
      return <Badge variant="success">Good</Badge>;
    default:
      return <Badge variant="secondary">No Status</Badge>;
  }
};

const status = [
  {
    Id: "",
    Name: "All Status"
  },
  {
    Id: false,
    Name: "Issues Exist"
  },
  {
    Id: true,
    Name: "Good"
  }
];

const TscValueTrackerTable = ({
  records,
  handleRowClick,
  dateTime,
  trackerType,
  pageIndex
}) => (
  <div>
    <Table hover className="table-vgg-darkblue" responsive="sm">
      <thead>
        <tr>
          <th>S/N</th>
          <th>Airline</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {records.map((record, index) => {
          return (
            <tr
              key={index}
              className="pointer"
              onClick={() =>
                handleRowClick(record, dateTime, trackerType, pageIndex)
              }
            >
              <TableCell content={`${pageIndex + index + 1}`} />
              <TableCell content={record.AirlineName} />
              <TableCell content={renderStatus(record.Status)} />
            </tr>
          );
        })}
      </tbody>
    </Table>
  </div>
);

class PaginatedTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      drillDownData: null,
      timelineShow: false,
      processStatus: "",
      fileRecords: [],
      drillDownTotalCount: 0,
      drillDownCurrentPage: 1,
      pageSize: 10,
      isLocal: false,
      accumulatedRecords: [],
      flightNumbers: [],
      selectedFlightNumber: null,
      airports: [],
      selectedAirport: null,
      drillDownStatus: status,
      selectedStatus: null,
      selectedTicket: null,
      selectedDestination: null,
      ticket: []
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const reduxState = store.getState();
    let { airports } = reduxState;

    if (airports) {
      let modifiedAirport = [...airports.Results];
      modifiedAirport.unshift({ Name: "All Airports", Code: "-1" });
      this.setState({ airports: modifiedAirport });
    } else {
      dispatch(commonActions.getAllAirports());
    }
  }

  componentWillReceiveProps() {
    let { airports } = store.getState();
    if (airports) {
      let modifiedAirport = [...airports.Results];
      modifiedAirport.unshift({ Name: "All Airports", Code: "-1" });
      this.setState({ airports: modifiedAirport });
    }
  }

  handleRowClick = (fileRecords, dateTime, trackerType) => {
    this.setState(
      {
        dateTime: dateTime,
        trackerType: trackerType,
        fileRecords: fileRecords
      },
      () => {
        this.fetchAirlineFlightNumbers(fileRecords.AirlineId);
        this.getDrillDownData();
      }
    );
  };

  fetchAirlineFlightNumbers = airlineId => {
    const { dispatch } = this.props;
    commonService.getAirlinesFlightNumbers(airlineId).then(res => {
      let response = res.response;
      if (res.status === appConstants.SUCCESS_RESPONSE) {
        let modifiedFlights = [...response.Results];
        let renameKey = graphHelpers.renamePropertyKeys(
          modifiedFlights,
          "FlightNumber",
          "Name"
        );

        renameKey.unshift({ Name: "All Flights", Id: "-1" });
        this.setState({ flightNumbers: renameKey });
      } else if (res.status === appConstants.ERROR_RESPONSE) {
        dispatch(alertActions.error(res.response));
      }
    });
  };

  handleFlightChange = selected => {
    this.setState({ selectedFlightNumber: selected ? selected.Name : "" });
  };

  handleAirportChange = selected => {
    this.setState({ selectedAirport: selected ? selected.Code : "" });
  };

  handleStatusChange = selected => {
    this.setState({ selectedStatus: selected ? selected.Id : "" });
  };

  handleTicketChange = selected => {
    this.setState({ selectedTicket: selected ? selected.Id : "" });
  };

  handleDestinationChange = selected => {
    this.setState({ selectedDestination: selected ? selected.Id : "" });
  };

  getDrillDownData = (pageNumber = 1) => {
    const {
      fileRecords,
      dateTime,
      pageSize,
      selectedFlightNumber,
      selectedAirport,
      selectedStatus
    } = this.state;
    const { dispatch, isLocal } = this.props;

    let fromIndex;

    fromIndex = (pageNumber - 1) * pageSize;

    let parameter = {
      AirlineId: fileRecords.AirlineId,
      DateTime: moment(dateTime).format("YYYY-MM-DD"),
      isLocal: isLocal
    };

    if (selectedStatus !== 0 && selectedStatus !== "") {
      parameter.Status = selectedStatus;
    }

    if (
      selectedFlightNumber !== "-1" &&
      selectedFlightNumber !== "All Flights" &&
      selectedFlightNumber !== ""
    ) {
      parameter.FlightNumber = selectedFlightNumber;
    }

    if (selectedAirport !== "-1" && selectedAirport !== "") {
      parameter.AirportCode = selectedAirport;
    }

    let payload = {
      PageSize: pageSize,
      From: fromIndex,
      Parameter: parameter
    };
    dispatch(alertActions.startRequest());
    commonService.getTscDrillDownFlaggingStatus(payload).then(
      res => {
        if (res.status === appConstants.SUCCESS_RESPONSE) {
          const response = res.response;
          let drillDownRecord = response.Results;
          // let modifiedDrillDownRecord = [...drillDownRecord];
          // let mappedRecord = modifiedDrillDownRecord.map(item=> item);
          // let ticketNumbers = graphHelpers.renamePropertyKeys(mappedRecord, "FlightNumber", "Name")

          this.setState({
            accumulatedRecords: response,
            drillDownData: drillDownRecord,
            timelineShow: true,
            drillDownTotalCount: response.TotalCount,
            drillDownCurrentPage: pageNumber
            // ticket: ticketNumbers
          });
          dispatch(alertActions.stopRequest());
        } else if (res.status === appConstants.ERROR_RESPONSE) {
          dispatch(alertActions.error(res.response.Message));
          dispatch(alertActions.stopRequest());
          dispatch(alertActions.error(res.response));
        }
      },
      error => {
        let errorMessage = appHelpers.interpretErrorResponse(error);
        dispatch(alertActions.stopRequest());
        dispatch(alertActions.error(errorMessage));
      }
    );
  };

  // getTicketNumbers = (drillDownRecord) => {
  //     let modifiedDrillDownRecord  = [...drillDownRecord];
  //     let ticketNumbers = graphHelpers.renamePropertyKeys(modifiedDrillDownRecord, "TicketNumber", "Name");
  //     this.setState({
  //         ticket: ticketNumbers
  //     })
  // };

  modalClose = () => {
    this.setState({
      timelineShow: false,
      selectedStatus: null,
      selectedFlightNumber: null,
      selectedAirport: null
    });
  };

  render() {
    const {
      totalCount,
      records,
      currentPage,
      pageSize,
      fetchRecords,
      dateTime,
      trackerType,
      recordDate,
      isLocal,
      titlePrefix
    } = this.props;
    const {
      drillDownData,
      fileRecords,
      timelineShow,
      flightNumbers,
      drillDownTotalCount,
      drillDownCurrentPage,
      accumulatedRecords,
      airports,
      drillDownStatus,
      ticket,
      destination
    } = this.state;

    let pageIndex = (currentPage - 1) * pageSize;

    let drillDownPageIndex = (drillDownCurrentPage - 1) * pageSize;

    return (
      <div>
        <div className="table-responsive" style={{ overflowX: "auto" }}>
          <ServerPagination
            fetchMore={fetchRecords}
            totalCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            target={
              <TscValueTrackerTable
                trackerType={trackerType}
                dateTime={dateTime}
                records={records}
                handleRowClick={this.handleRowClick}
                pageIndex={pageIndex}
              />
            }
          />
        </div>

        <RecordModal
          headerData={fileRecords}
          titlePrefix={titlePrefix}
          show={timelineShow}
          onHide={this.modalClose}
          recordDate={recordDate}
          drillDownTotalCount={drillDownTotalCount}
        >
          <DrillDownTable
            records={drillDownData}
            totalCount={drillDownTotalCount}
            fetchRecords={this.getDrillDownData}
            currentPage={drillDownCurrentPage}
            pageIndex={drillDownPageIndex}
            isLocal={isLocal}
            accumulatedRecords={accumulatedRecords}
            drillDownStatus={drillDownStatus}
            flightNumbers={flightNumbers}
            handleFlightChange={this.handleFlightChange}
            airports={airports}
            handleAirportChange={this.handleAirportChange}
            destination={destination}
            handleDestinationChange={this.handleDestinationChange}
            ticket={ticket}
            handleTicketChange={this.handleTicketChange}
            handleStatusChange={this.handleStatusChange}
            handleDrillDownSearch={this.getDrillDownData}
            drillDownTotalCount={drillDownTotalCount}
            minTscNaira={accumulatedRecords.MinTscNaira}
            maxTscNaira={accumulatedRecords.MaxTscNaira}
            minTscDollar={accumulatedRecords.MinTscDollar}
            maxTscDollar={accumulatedRecords.MaxTscDollar}
          />
        </RecordModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { modal, requesting, airports } = state;
  return {
    requesting,
    modal,
    airports
  };
}
export default connect(mapStateToProps)(PaginatedTable);

/* Written with love by Juliet on July 2019 */
