import React from 'react'
import SelectPicker from '../../components/selectpicker'
import { Button } from 'react-bootstrap';
import { FiSearch } from 'react-icons/fi';

const SearchPanel = ({ handleDrillDownSearch, drillDownStatus, dateTypes, selectedStatus, flightNumbers,
                         selectedFlightNumber,handleFlightChange, selectedAirport, handleAirlineChange,
                         handleStatusChange, airports, handleAirportChange, destination,
                        //  selectedDestination, handleDestinationChange,
                         // ticket, selectedTicket, handleTicketChange
                     }) => {

    return (
        <div className="row">
            <div className="col-md-2">
                <SelectPicker
                    options={flightNumbers}
                    placeholder="Select Flight No"
                    selectedValue={selectedFlightNumber}
                    handleChange={handleFlightChange}
                    customKeys={true}
                />
            </div>
            <div className="col-md-3">
                <SelectPicker
                    options={airports}
                    placeholder="Departure Airport"
                    selectedValue={selectedAirport}
                    handleChange={handleAirportChange}
                    customKeys={true}
                />
            </div>
            {/* Desination airport selection not needed for now */}
            {/* <div className="col-md-3">
                <SelectPicker
                    options={airports}
                    placeholder="Destination Airport"
                    selectedValue={selectedDestination}
                    handleChange={handleDestinationChange}
                    customKeys={true}
                />
            </div> */}
            {/*<div className="col-md-2">*/}
                {/*<SelectPicker*/}
                    {/*options={ticket}*/}
                    {/*placeholder="Select Ticket"*/}
                    {/*selectedValue={selectedTicket}*/}
                    {/*handleChange={handleTicketChange}*/}
                    {/*customKeys={true}*/}
                {/*/>*/}
            {/*</div>*/}
            <div className="col-md-2">
                <SelectPicker
                    options={drillDownStatus}
                    placeholder="Select Status"
                    selectedValue={selectedStatus}
                    handleChange={handleStatusChange}
                    customKeys={true}
                />
            </div>

            <div className="col-md-2">
                <Button className="btn-search btn-block" onClick={() => handleDrillDownSearch()}><span><FiSearch /></span> &nbsp; Search</Button>
            </div>

        </div>
    )
}

export default SearchPanel

/* Written with love by Juliet on July 2019 */
