import React, { Component } from 'react';
import Select from 'react-select';


class SelectPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false,
            clearable: true,
            selectedValue: ''
        }
    }

    render() {
        let { clearable } = this.state;
        let { options, selectedValue, handleChange, multiSelectMode,
            placeholder, disabled, customKeys } = this.props;

        multiSelectMode = (multiSelectMode !== undefined);
        disabled = (disabled !== undefined) ? disabled : false;
        // customKeys = (customKeys !== undefined) ? customKeys : false;

        return (
            <div>
                {customKeys &&
                    <Select
                        placeholder={placeholder}
                        options={options}
                        onChange={handleChange}
                        isMulti={multiSelectMode}
                        value={selectedValue}
                        isClearable={clearable}
                        disabled={disabled}
                        getOptionLabel={option => option.Name}
                        getOptionValue={option => option.Id}

                    />
                }
                {!customKeys &&
                    <Select
                        placeholder={placeholder}
                        options={options}
                        onChange={handleChange}
                        isMulti={multiSelectMode}
                        value={selectedValue}
                        isClearable={clearable}
                        disabled={disabled}
                    />
                }

            </div>
        )

    }
}

export default SelectPicker