import React from 'react';
import {
    Table,
    Pagination,
} from "react-bootstrap";
import './table.css';
import TableCell from './tablecell';

//POSSIBLE FEATURES
// ==================
// Pagination
// Filterable
// Search
// Responsiveness, Readable, Contextual, Accessible

let active = 2;
let items = [];
for (let number = 1; number <= 5; number++) {
    items.push(
        <Pagination.Item key={number} active={number === active}>
            {number}
        </Pagination.Item>,
    );
}

const TableComponent = ({ headings, rows, clicked }) => {

    //for headers
    const renderHeaderRow = (currentHeader, currentIndex) => {
        return (
            <TableCell
                key={`heading-${currentIndex}`}
                content={headings[currentIndex]}
                header={true}
            />
        )
    }

    //for rows
    const renderRow = (currentRow, rowIndex) => {
        return (
            <tr key={`row-${rowIndex}`}>
                {rows[rowIndex].map((currentCell, cellIndex) => {
                    return (
                        <TableCell
                            key={`${rowIndex}-${cellIndex}`}
                            content={rows[rowIndex][cellIndex]}
                        />
                    )
                }
                )
                }
            </tr>
        )
    }


    // headers markup
    const theadMarkup = (
        <tr key="heading">
            {headings.map(renderHeaderRow)}
        </tr>
    )

    // body markup
    const tbodyMarkup = (
        rows.map(renderRow)
    )

    return (
        <div>
            <Table hover className='table-vgg-darkblue' responsive='sm'>
                <thead>
                    {theadMarkup}
                </thead>
                <tbody onClick={clicked}>
                    {tbodyMarkup}
                </tbody>
            </Table>
            {/* <div>
                <Pagination>{items}</Pagination>
            </div> */}
        </div>
    );
};

export default TableComponent;
