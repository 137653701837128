import React from 'react';
import {
    AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from "recharts";
import {appConstants} from "../../_constants";
import  "./index.css"
import {appHelpers} from "../../_helpers";

class AreaChartGraphComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            opacity:{},
        }
    }

    componentDidMount(){
        const { legends } = this.props;
        let opacity = {};
        //set legends opacity
        legends.map(l => opacity[l['name']] = appConstants.GRAPH_FOCUS_OPACITY);
        this.setState({
            opacity
        });
    }

    handleMouseEnter(o) {
        const { dataKey } = o;
        const { opacity } = this.state;
        this.setState({
            opacity: { ...opacity, [dataKey]: appConstants.GRAPH_FOCUS_OPACITY },
        });
    }

    handleMouseLeave(o) {
        const { dataKey } = o;
        const { opacity } = this.state;
        this.setState({
            opacity: { ...opacity, [dataKey]: appConstants.GRAPH_DEFAULT_OPACITY },
        });
    }

    renderToolTip = (value, name, props) => {
        return [appHelpers.numberWithCommasOnly(value)];
    };

    render() {
        const { opacity } = this.state;
        let { data, legends } = this.props;
        data = (data) ? data : [];
        return (
            <div>
                <ResponsiveContainer width={"100%"} height={405}>
                    <AreaChart data={data}
                               margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                        <CartesianGrid stroke={"#015c75"} strokeDasharray="0 0"/>
                        {/* <defs>
                            {
                                legends.map((l, key) => {
                                    return (
                                        <linearGradient key={key} id={l.colorId} x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor={l.color} stopOpacity={0.8}/>
                                            <stop offset="95%" stopColor={l.color} stopOpacity={0}/>
                                        </linearGradient>
                                    )
                                })
                            }
                        </defs> */}
                        <XAxis dataKey="name" stroke={"#b5b5b5"}/>
                        <YAxis stroke={"#b5b5b5"} />
                        <Tooltip formatter={this.renderToolTip}/>
                        <Legend onMouseEnter={(e) => this.handleMouseEnter(e)}
                                onMouseLeave={(e) => this.handleMouseLeave(e)}/>
                        {
                            legends.map((legend, key) => {
                                const fill = `url(#${legend.colorId})`;
                                return (
                                    <Area key={key} type='monotone' dataKey={legend.name}
                                          strokeOpacity={opacity[legend.name]}
                                          fillOpacity={opacity[legend.name]}
                                          stroke={legend.color}
                                          fill={fill}
                                    />
                                )
                            })
                        }
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        );
    }
};

export const AreaChartGraph = AreaChartGraphComponent;
