import React, { Component } from 'react';
import ServerPagination from "../../components/paginatedtable";
import {
    Table,
    Badge,
    Row,
    Col,
    Card
} from "react-bootstrap";
import TableCell from '../../components/table/tablecell';
import {connect} from "react-redux";
import DrillDownSearchPanel from "./drillDownSearchPanel"
import {EmptyRecord} from "../../components/pageutility";
import "./tsc.scss";
import ProgressLoader from "../../components/progressloader";

const renderStatus = (drillDownStatus) => {
    switch (drillDownStatus) {
        case false:
            return (<Badge variant='danger'>Issues Exist</Badge>)
        case true:
            return (<Badge variant='success'>Ok</Badge>)
        default:
            return (<Badge variant='secondary'>No Status</Badge>)
    }

};

const ModalTableStructure = ({records, pageIndex, isLocal, accumulatedRecords, drillDownStatus, flightNumbers,
                             airports, handleFlightChange, handleAirportChange, handleStatusChange,
                                 handleDrillDownSearch, drillDownTotalCount, minTscNaira, maxTscNaira,
                                 minTscDollar, maxTscDollar
                             }) => (
    <div>
        <Row>
            <Col md={12} className={'manifest-list'}>
                <DrillDownSearchPanel
                    drillDownStatus={drillDownStatus}
                    flightNumbers={flightNumbers}
                    handleFlightChange={handleFlightChange}
                    airports={airports}
                    handleAirportChange={handleAirportChange}
                    handleStatusChange={handleStatusChange}
                    handleDrillDownSearch={handleDrillDownSearch}
                />
          </Col>
        </Row>

        <Row className={"text-white"}>
            <Col md={3}>
                <Card className={"range-card"}>
                    <h5>Expected Min Range <br/> <span className={"range-amt"}> &#8358; {minTscNaira.toFixed(2)}</span></h5>
                </Card>
            </Col>
            <Col md={3}>
                <Card className={"range-card"}>
                    <h5>Expected Max Range <br/><span className={"range-amt"}>&#8358; {maxTscNaira.toFixed(2)}</span></h5>
                </Card>
            </Col>
            <Col md={3}>
                <Card className={"range-card"}>
                    <h5>Expected Min Range <br/><span className={"range-amt"}>&#36; {minTscDollar.toFixed(2)}</span></h5>
                </Card>
            </Col>
            <Col md={3}>
                <Card className={"range-card"}>
                    <h5>Expected Max Range <br/> <span className={"range-amt"}>&#36; {maxTscDollar.toFixed(2)}</span></h5>
                </Card>
            </Col>

        </Row>

        {
            (drillDownTotalCount > 0) &&
            <Row>
                <Col md={12}>
                        <Table hover className='table-vgg-darkblue' responsive='sm'>
                            <thead>
                            <tr>
                                <th>S/N</th>
                                <th>Flight Number</th>
                                <th>Departure Airport</th>
                                <th>Destination Airport</th>
                                <th>Ticket Number</th>
                                <th>Currency</th>
                                <th>Actual TSC Amount</th>
                                <th>Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {records.map((record, index) => {
                                return (
                                    <tr key={index} className='pointer'>
                                        <TableCell content={pageIndex + index + 1}/>
                                        <TableCell content={record.FlightNumber}/>
                                        <TableCell content={record.AirportName}/>
                                        <TableCell content={record.Destination}/>
                                        <TableCell content={record.TicketNumber}/>
                                        {
                                            (record.Currency === 840) ?
                                                <td>
                                                    &#36;
                                                </td>
                                                :
                                                <td>
                                                    &#8358;
                                                </td>
                                        }
                                        <TableCell content={record.Actual}/>
                                        <TableCell content={renderStatus(record.Status)}/>
                                    </tr>

                                )
                            })}
                            </tbody>
                        </Table>
                </Col>
            </Row>
        }
        {
            (drillDownTotalCount === 0) && <EmptyRecord
                message={'No breakdown record found'}
            />
        }
    </div>
);

class PaginatedTable extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const { totalCount, records, currentPage, pageSize,
            fetchRecords , pageIndex, isLocal, accumulatedRecords,
            drillDownStatus, flightNumbers, airports, handleFlightChange,
            handleAirportChange, handleStatusChange, handleDrillDownSearch,
            drillDownTotalCount, minTscNaira, maxTscNaira, minTscDollar, maxTscDollar,
            requesting
        } = this.props;
        return (
            <div>
                {
                    requesting &&
                    <ProgressLoader message="Loading data..." />
                }
                <div className="table-responsive" style={{overflowX: "auto"}}>
                    <ServerPagination fetchMore={fetchRecords}
                                      totalCount={totalCount} pageSize={pageSize}
                                      currentPage={currentPage}
                                      target={
                                          <ModalTableStructure
                                              records={records}
                                              pageIndex={pageIndex}
                                              isLocal={isLocal}
                                              accumulatedRecords={accumulatedRecords}
                                              drillDownStatus={drillDownStatus}
                                              flightNumbers={flightNumbers}
                                              handleFlightChange={handleFlightChange}
                                              airports={airports}
                                              handleAirportChange={handleAirportChange}
                                              handleStatusChange={handleStatusChange}
                                              handleDrillDownSearch={handleDrillDownSearch}
                                              drillDownTotalCount={drillDownTotalCount}
                                              minTscNaira={minTscNaira}
                                              maxTscNaira={maxTscNaira}
                                              minTscDollar={minTscDollar}
                                              maxTscDollar={maxTscDollar}
                                          />
                                      }
                    />
                </div>

            </div>
        )
    }
}

function mapStateToProps(state) {
    const { modal, requesting } = state;
    return {
        requesting,
        modal,
    };
}
export default connect(mapStateToProps)(PaginatedTable);

/* Written with love by Juliet on July 2019 */