import React, { Component } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import "./index.css";


const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#E04646'];

class PieChartGraph extends Component {

    constructor(props) {
        super(props)
        this.state = {

        }
    }

    componentDidMount() {

    }

    render() {
        let { data, nameKey, dataKey } = this.props;
        return (
            <div>
                <ResponsiveContainer width={"100%"} height={405} >
                    <PieChart>
                        <Tooltip />
                        <Legend />
                        <Pie
                            data={data}
                            innerRadius="60%"
                            outerRadius="80%"
                            fill="#8884d8"
                            paddingAngle={0}
                            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                            nameKey={nameKey}
                            dataKey={dataKey}
                        >

                            {
                                data.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]} />)
                            }
                        </Pie>
                    </PieChart>
                </ResponsiveContainer>
            </div>
        );
    }
}

export default PieChartGraph