import { appHelpers } from "./index";

function progressBars(summary, colors, hasTotal = false) {
    let progressBars = [];
    let summaryKeys = Object.keys(summary);
    if (hasTotal)
        summaryKeys.pop();

    let total = 0;
    summaryKeys.forEach((item) => {
        total += summary[item];
    });

    summaryKeys.forEach((item) => {
        progressBars.push({
            name: item,
            value: summary[item],
            variant: colors[item],
            percent: (total > 0) ?
                (summary[item] / total).toFixed(2) * 100 :
                0
        });
    });
    return { progressBars, total };
}

function chartData(data, duration = 0) {
    const format = (duration < 1) ? "H:mm" : "MMM DD, H:mm";
    let data_ = [];
    data.forEach((item) => {
        let item_ = { ...item };
        item_.name = appHelpers.formatDateSpecific(item.name, format);
        data_.push(item_)
    });
    return data_;
}

function addXAxisNames(data, key) {
    let data_ = [...data];
    data_.map((item) => {
        item.name = item[key]
        return item
    })
    return data_;
}

function generateSummaryData(data) {
    let keys = Object.keys(data);
    const data_ = keys.map((item, i) => {
        return {
            name: item,
            value: data[item]
        }
    })
    return data_;
}

function renamePropertyKeys(data, oldKey, newKey) {
    let data_ = [...data];
    data_.map((item) => {
        item[newKey] = item[oldKey];
        delete item[oldKey];
        return item;
    });
    return data_;
}

export const graphHelpers = {
    progressBars,
    chartData,
    addXAxisNames,
    generateSummaryData,
    renamePropertyKeys
}