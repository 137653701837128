import { commonService } from "../_services";
import { appHelpers } from "../_helpers";
import { alertConstants, commonConstants, appConstants } from "../_constants";
import {alertActions} from "../_actions"


export const commonActions = {
    fetchManifestList,
   getAirlinesCarriers,
    postSignIn,
    postSignOut,
    getAllAirports
}

function postSignOut() {
    return { type: appConstants.SIGN_OUT, user: false };
}

function fetchManifestList(payload) {
    return dispatch => {
        return commonService.fetchManifestList(payload)
            .then(
                response => {
                    if (response.error == null) {
                        dispatch(success(response.data));
                    } else {
                        dispatch(failure(response));
                    }
                },
                error => {
                    let errorMessage = appHelpers.interpretErrorResponse(error);
                    dispatch(failure(errorMessage));
                }
            );
    };

    function success(manifestList) { return { type: commonConstants.MANIFEST_LIST_SUCCESS, manifestList } }
    function failure(message) { return { type: alertConstants.ERROR, message } }
}

function getAirlinesCarriers() {
    return dispatch => {
        return commonService.getAirlinesCarriers()
            .then(response => {
                    if(response.error == null){
                        dispatch(success(response.response));
                    }else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    let errorMessage = (error.response.data) ? error.response.data : 'Unable to handle request';
                    dispatch(failure(errorMessage));
                }
            );
    };

    function success(airlinesCarriers) { return { type: commonConstants.AIRLINES_CARRIERS_SUCCESS, airlinesCarriers } }
    function failure(message) { return { type: appConstants.REQUEST_FAILURE, message } }
}

function getAllAirports() {
    return dispatch => {
        return commonService.getAllAirports()
            .then(response => {
                    if(response.error == null){
                        dispatch(success(response.response));
                    }else{
                        dispatch(failure(response));
                    }
                },
                error => {
                    let errorMessage = (error.response.data) ? error.response.data : 'Unable to handle request';
                    dispatch(failure(errorMessage));
                }
            );
    };

    function success(airports) { return { type: commonConstants.AIRPORTS_SUCCESS, airports } }
    function failure(message) { return { type: appConstants.REQUEST_FAILURE, message } }
}

function postSignIn(payload) {
    return dispatch => {
        dispatch(alertActions.startRequest());
        return commonService.postSignIn(payload)
            .then(response => {
                    // console.log('Payload', response);
                    if(response.status === appConstants.SUCCESS_RESPONSE) {
                        dispatch(success(response.response));
                        dispatch(alertActions.stopRequest());
                        dispatch(alertActions.clear());
                        //redirect user
                        // history.push('/#/landing');
                        // window.location.href = '/';
                        // userHomepage(response.response); //redirect user to homepage
                    }else{
                        dispatch(failure(response.response));
                        dispatch(alertActions.stopRequest());
                    }
                },
                error => {
                    let errorMessage = (error.response.data) ? error.response.data : 'Unable to handle request';
                    dispatch(failure(errorMessage));
                    dispatch(alertActions.stopRequest());
                }
            );
    };
    function success(user) { return { type: appConstants.SIGN_IN_SUCCESS, user } }
    function failure(message) { return { type: alertConstants.ERROR, message } }
}
