import React from 'react'
import SelectPicker from '../../components/selectpicker'
import { Button } from 'react-bootstrap';
import { FiSearch } from 'react-icons/fi';

const SearchPanel = ({  handleSearch, statuses, departureAirport,
                         selectedAirport, handleAirportChange, drillDownStatus,
                         selectedDrillDownStatus, handleDrillDownStatusChange,
                         drillDownFlightNumber, selectedFlightNumber, handleFlightNumberChange
                     }) => {

    return (
        <div className="row">
            <div className="col-md-4">
                <SelectPicker
                    options={drillDownFlightNumber}
                    placeholder="Flight Number"
                    selectedValue={selectedFlightNumber}
                    handleChange={handleFlightNumberChange}
                    customKeys={true}
                />
            </div>
            <div className="col-md-4">
                <SelectPicker
                    options={departureAirport}
                    placeholder="Departure Airport"
                    selectedValue={selectedAirport}
                    handleChange={handleAirportChange}
                    customKeys={true}
                />
            </div>
            <div className="col-md-2">
                <SelectPicker
                    options={drillDownStatus}
                    placeholder="Select Status"
                    selectedValue={selectedDrillDownStatus}
                    handleChange={handleDrillDownStatusChange}
                    customKeys={true}
                />
            </div>

            <div className="col-md-2">
                <Button className="btn-search btn-block" onClick={() => handleSearch()}><span><FiSearch /></span> &nbsp; Search</Button>
            </div>

        </div>
    )
};

export default SearchPanel

/* Written with love by Juliet on July 2019 */