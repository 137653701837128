import React, {Component} from 'react';
import {
    Card,
} from "react-bootstrap";
import './index.css';

class CardComponent extends Component {
    render() {
        //pass dynamic height prop if you want the card to take the width of the children elements.
        //use the style-card className to style unique cards
        const { title, dynamicHeight } = this.props;
        let cardStyle;
        if (!dynamicHeight) {
            cardStyle = <Card className="card" style={{ height: "34rem" }}>
                <Card.Body>
                    <Card.Title className="title" >{title}</Card.Title>
                    {this.props.children}
                </Card.Body>
            </Card>
        } else {
           cardStyle =  <Card className="card style-card" >
                <Card.Body>
                    <Card.Title className="title" >{title}</Card.Title>
                    {this.props.children}
                </Card.Body>
            </Card>
        }
        return (
            <div>
                {cardStyle}
            </div>
        );
    }
}


export default CardComponent;
