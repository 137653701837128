import React from 'react';
import "./index.css"

export const SecondaryTab = ({menus, activeMenuIndex, handleClick}) => {
    return (
        <ul className={'tabs secondary'}>
            {
                menus.map((item, key) => {
                    return (
                        <li onClick={(e)=>handleClick(e, key)} data-key={key} key={key} className={(activeMenuIndex - 1 === key) ? 'active' : ''}>
                            <a href={item.link}>
                                {
                                    (item.icon) &&
                                     item.icon
                                }
                                &nbsp;{item.name}
                            </a>
                        </li>
                    )
                })
            }
        </ul>
    );
};
