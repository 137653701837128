import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import moment from "moment";
import 'react-vertical-timeline-component/style.min.css';
import { FiStar, FiFileText, FiAirplay } from "react-icons/fi";
import { appHelpers } from "../../../../_helpers";
import { appConstants } from "../../../../_constants";

// import HorizontalTimelineContent from '../../../components/horizontalTimeline/HorizontalTimelineContent';
// import GameInfo from '../../../components/horizontalTimeline/resources/content';

const AggregationIconStepNameStyle = { background: 'rgb(28, 102, 87)', color: '#ffffff' };
const AggregationIconStepStyle = { background: 'rgb(255, 255, 255)', color: '#1c6657' };
const ProcessErrorIconStepStyle = { background: appConstants.RED_COLOR, color: "#fff" };

export default ({ Aggregation, CurrencyName, airline }) => {

    return (
        <div>
            <VerticalTimeline>
                <VerticalTimelineElement
                    date={(<h3 className={'stepName'} style={AggregationIconStepNameStyle}>Aggregation</h3>)}
                    iconStyle={AggregationIconStepNameStyle}
                    icon={<FiStar />}
                />

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(Aggregation) ? moment(Aggregation.CreatedDate).fromNow() : moment().fromNow()}
                    iconStyle={(!Aggregation) ? ProcessErrorIconStepStyle : AggregationIconStepStyle}
                    icon={<FiAirplay />}
                >
                    <h2 className="vertical-timeline-element-subtitle">
                        {'Aggregate Manifest'}
                    </h2>
                    <p className="vertical-timeline-element-paragraph">

                        {appHelpers.numberWithCommasOnly(Aggregation.TotalManifests)} manifests were aggregated from {airline} file under
                        <strong> {appHelpers.formatMinutesDescription(Aggregation.Duration)}</strong>
                    </p>

                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(Aggregation) ? moment(Aggregation.CreatedDate).fromNow() : moment().fromNow()}
                    iconStyle={(!Aggregation) ? ProcessErrorIconStepStyle : AggregationIconStepStyle}
                    icon={<FiFileText />}
                >
                    <h2 className="vertical-timeline-element-subtitle">{'Calculate TSC'}</h2>

                    <p className="vertical-timeline-element-paragraph">
                        <strong>{appHelpers.numberWithCommasOnly(Aggregation.TotalAmount)} ({CurrencyName}) </strong> was calculated as TSC under
                        <strong> {appHelpers.formatMinutesDescription(Aggregation.Duration)}</strong>
                    </p>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    date={`That's all.`}
                    iconStyle={{ background: 'rgb(255, 255, 255)', color: '#2992d0' }}
                    icon={<FiStar />}
                />
            </VerticalTimeline>

        </div>
    );
};
