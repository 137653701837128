import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import moment from "moment";
import 'react-vertical-timeline-component/style.min.css';
import { FiCheck, FiChevronsRight, FiXCircle, FiStar } from "react-icons/fi";

const IconStyleSuccessful = { background: 'rgb(31, 177, 89)', color: '#ffffff' };
const IconStylePending = { background: 'rgb(255, 195, 0)', color: '#ffffff' };
const IconStyleFailed = { background: 'rgb(224, 70, 70)', color: '#ffffff' };

// set icon based on status
const renderIcon = (status) => {
    switch (status) {
        case "successful":
            return (<FiCheck />);
        case "pending":
            return (<FiChevronsRight />);
        case "failed":
            return (<FiXCircle />);
        default:
            return (<FiStar />)
    }
}

// set icon style based on status
const renderIconStyle = (status) => {
    switch (status) {
        case "successful":
            return (IconStyleSuccessful);
        case "pending":
            return (IconStylePending);
        case "failed":
            return (IconStyleFailed);
        default:
            return (IconStyleSuccessful)
    }
}

const TimelineComponent = ({ timelineData }) => {
    return (
        <div>
            <VerticalTimeline>
                {
                    timelineData.map((item, key) => {
                        return (
                            <VerticalTimelineElement key={key}
                                className="vertical-timeline-element--work"
                                date={moment(item.timestamp).fromNow()}
                                iconStyle={renderIconStyle(item.status)}
                                icon={renderIcon(item.status)}
                            >
                                <h5 className="vertical-timeline-element-subtitle">{item.description}</h5>
                                <p className="vertical-timeline-element-paragraph">{item.status} </p>
                            </VerticalTimelineElement>
                        )
                    })
                }

                <VerticalTimelineElement
                    date={`That's all.`}
                    iconStyle={{ background: 'rgb(255, 255, 255)', color: '#2992d0' }}
                    icon={<FiStar />}
                />
            </VerticalTimeline>
        </div>
    );
};

export default TimelineComponent;
