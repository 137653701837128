import React, { Component } from 'react';
import { FiXCircle , FiStar, FiCheckCircle } from "react-icons/fi";
import { appConstants } from "../../_constants";

class TimelineHeader extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeStep: 1,
            steps: [],
            lastStep : false,
            totalStep: 0
        }
    }

    componentDidMount() {
        const { steps } = this.props;

        const totalStep = steps.length;
        const lastStep = steps.pop();

        this.setState({steps, totalStep, lastStep});

    }


    getClassNames = (status) => {
        // const { activeStep } = this.props;
        // if(activeStep === stepIndex + 1)
        //     return "form-steps__step form-steps__step--active";

        switch (status) {
            case appConstants.TIMELINE_STEP_COMPLETED:
                return "form-steps__step form-steps__step--completed";
            case appConstants.TIMELINE_STEP_FAILED:
                return "form-steps__step form-steps__step--failed";
            case appConstants.TIMELINE_STEP_ACTIVE:
                return "form-steps__step form-steps__step--active";
            case appConstants.TIMELINE_STEP_INACTIVE:
                return "form-steps__step form-steps__step--inactive";
            default:
                return "form-steps__step form-steps__step--active";
        }
    };

    getStepIcon = (status) => {
        switch (status) {
            case appConstants.TIMELINE_STEP_FAILED:
                return <FiXCircle/>;
            default:
                return <FiCheckCircle/>
        }
    }

    render() {
        const { steps, totalStep, lastStep} = this.state;
        const {handleSwitchStep} = this.props;
        const stepWidth = Math.floor(100/totalStep);

        return (
            <div className="tfx-step grid__full">
                <div className="form-steps">
                    {
                        steps.map((item, key) => {
                            return (
                                <div className={this.getClassNames(item.status)} key={key}
                                     onClick={() => handleSwitchStep(key + 1)}
                                     style={{width: `${stepWidth}%`}}
                                >
                                    <div className="form-steps__step-circle">
                                        <span className="form-steps__step-number">
                                           <FiStar/>
                                        </span>
                                        <span className="form-steps__step-check">
                                            {
                                                this.getStepIcon(item.status)
                                            }
                                     </span>
                                    </div>
                                    <span className="form-step__step-name"> {item.name} </span>
                                </div>
                            );
                        })
                    }
                    <div
                        className={`${this.getClassNames(lastStep.status, (totalStep - 1))} form-steps__step--last`}
                        style={{width: `${stepWidth}%`}}
                        onClick={() => handleSwitchStep(totalStep)}
                    >
                        <div className="form-steps__step-circle">
                            <span className="form-steps__step-number">
                                 <FiStar/>
                            </span>
                            <span className="form-steps__step-check">
                                <FiCheckCircle/>
                            </span>
                        </div>
                        <span className="form-step__step-name">{lastStep.name}</span>
                    </div>

                </div>
            </div>

        );
    }
}


export default TimelineHeader;
