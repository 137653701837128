import React, {Component} from 'react';
import { Row, Col, Form, Button, InputGroup
} from "react-bootstrap";
import {FiMail, FiLock} from "react-icons/fi";
// import {alertActions, commonActions} from "../../_actions";
// import {alertConstants, appConstants} from "../../_constants";
// import {store} from "../../_store"
import {connect} from 'react-redux';
import Card from "../../components/card/index";
import "./index.scss"
import {alertActions, commonActions} from '../../_actions';
import {alertConstants, appConstants} from "../../_constants";
import {commonService} from "../../_services";
import ProgressLoader from '../../components/progressloader';
import {store} from "../../_store";
import {history} from "../../_helpers"


class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentError: false,
            error: false,
            success: false,
            Username: '',
            Password: '',
        }
    }

    componentDidMount() {
        const {alert} = store.getState();
        const {dispatch} = this.props;
        // dispatch(alertActions.stopRequest());
        //check user is authenticated;
        dispatch(commonActions.postSignOut());

        if (alert) {
            if (alert.type === alertConstants.SUCCESS) {
                this.setState({error: false, success: alert.message});
            } else if (alert.type === alertConstants.CLEAR) {
                this.setState({success: false, error: false})
            } else if (alert.type === alertConstants.ERROR) {
                this.setState({success: false, error: alert.message});
                dispatch(alertActions.clear());
            }
        }
    }

    componentWillReceiveProps = function (nextProps) {
        const { alert } = nextProps;
        if(alert){
            if(alert.type === alertConstants.SUCCESS ) {
                this.setState({error: false, success: alert.message});
            }
            else if(alert.type === alertConstants.CLEAR ){
                this.setState({success: false, error: false})
            }
            else if(alert.type === alertConstants.ERROR ){
                this.setState({success: false, error: alert.message })
            }
        }

    };

    handleChange = (event) => {
        // event.persist();
        this.setState({[event.target.name]: event.target.value});
    };

    validateInput = () => {
        const {Username, Password} = this.state;
        if(Username === ''){
            this.setState({componentError: 'Please enter username'});
            return false;
        }
        else if (Password === ''){
            this.setState({componentError: "Please enter password"});
            return false;
        }else {
            this.setState({componentError: false});
            return true;
        }
    };

    resetError = () => {
        this.setState({error: false, componentError: false})
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const {Username, Password} = this.state;
        const { dispatch } = this.props;
        dispatch(alertActions.startRequest());
        this.resetError();
        if(this.validateInput()){
            let payload = JSON.stringify({Username, Password});
            commonService.postSignIn(payload)
                .then(res => {
                    if(res.status === appConstants.SUCCESS_RESPONSE){
                        const user = res.response;
                        dispatch({type: appConstants.SIGN_IN_SUCCESS, user});
                        history.push('/');
                    }else{
                        dispatch(alertActions.stopRequest());
                        this.setState({error: res.response})
                    }

                }).catch(err => {
                            dispatch(alertActions.stopRequest());
            });
            dispatch(commonActions.postSignIn(payload));
        }else{
            this.setState({error: 'Provide valid details'});
            dispatch(alertActions.stopRequest());
        }
    };

    render() {
        const { requesting } = this.props;
        const { error } = this.state;

        return (
            <div className={'login'}>
                {requesting &&
                <ProgressLoader message="Logging in..." />
                }
                    <Row className={'login-view'}>
                        <Col md={{ span: 6, offset: 3 }}>
                            <Card title={'Transaction Monitoring'} dynamicHeight={true}>
                                <Form onSubmit={this.handleSubmit} className={'login-form'}>
                                    {
                                        error &&
                                            <p className={'login-error'}>{error}</p>
                                    }
                                    <h5 className={'text-center'}>Please sign in to your account</h5>
                                    <Form.Group controlId="formBasicEmail" custom={"true"}>
                                        <Form.Label>Email address</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Prepend className={'inputGroupPrepend'}>
                                                <InputGroup.Text id="inputGroupPrepend"><FiMail/></InputGroup.Text>
                                            </InputGroup.Prepend>
                                        <Form.Control name="Username" type="email" placeholder="Enter email" aria-describedby="inputGroupPrepend" onChange={this.handleChange}/>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicPassword" custom={"true"}>
                                        <Form.Label>Password</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Prepend className={'inputGroupPrepend'}>
                                                <InputGroup.Text id="inputGroupPrepend"><FiLock/></InputGroup.Text>
                                            </InputGroup.Prepend>
                                        <Form.Control name="Password" type="password" placeholder="Password" onChange={this.handleChange}/>
                                        </InputGroup>
                                    </Form.Group>
                                    <Form.Group  custom={"true"} className={'forgot-text'}>
                                        <Form.Text className={'text-right'}>Forgot Password?</Form.Text>
                                    </Form.Group>
                                    <div className={'button-container'} >
                                    <Button className={'login-button'} type="submit" size={'lg'}>
                                        Login
                                    </Button>
                                    </div>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    const {alert, user, requesting, loginError} = state;
    return {
        alert,
        user,
        requesting,
        loginError
    }
}

export default connect(mapStateToProps)(Login);
