import React, {Component} from 'react';
import { Row, Col
} from "react-bootstrap";
import { FiPlusSquare, FiClock } from "react-icons/fi";
import moment from 'moment';
import Card from "../../components/card/index";
import { AreaChartGraph } from "../../components/graph";
import SummaryCard from "../../components/summaryCards/index";
import AppLayout from "../layout/index";
import { GraphHeader } from "../../components/graph/graphHeader";
import {alertActions, commonActions} from '../../_actions';
import { connect } from "react-redux";
import {commonService} from "../../_services";
import {appConstants} from "../../_constants";
import {appHelpers, graphHelpers} from "../../_helpers";
import ManifestAggregateTable from "./manifestAggregateTable"
import SearchPanel from './searchpanel';
import {store} from "../../_store";
import { EmptyRecord } from "../../components/pageutility";
import Timeline from "./timeline/timeline";
import { PrimaryTab } from '../../components/tabs';

const avicollectMenu = [
    {
        name: "Manifest Process",
        link: "/"
    },
    {
        name: "Manifest List",
        link: "/manifestList"
    },
    {
        name: "Manifest Aggregate",
        link: "/manifestAggregate"
    },
    {
        name: "Flag Metrics",
        link: "/international-flag-metrics"
    },
    {
        name: "TSC Value Tracker",
        link: "/tsc-value-tracker"
    }
];

const manifestDurationLegends = [
    {
        name: "<10",
        color: appConstants.GREEN_COLOR,
        colorId: 'colorSuccess'
    },
    {
        name: "10-30",
        color: appConstants.YELLOW_COLOR,
        colorId: 'colorFair'
    },
    {
        name: ">30",
        color: appConstants.RED_COLOR,
        colorId: 'colorCritical'
    }
];
const manifestAggregateStatusLegends = [
    {
        name: "Completed",
        color: appConstants.GREEN_COLOR,
        colorId: 'completed'
    },
    {
        name: "Processing",
        color: appConstants.BLUE_COLOR,
        colorId: 'processing'
    },
    {
        name: "Pending",
        color: appConstants.ORANGE_COLOR,
        colorId: 'pending'
    },
    {
        name: "Failed",
        color: appConstants.RED_COLOR,
        colorId: 'failed'
    }
];

class ManifestAggregate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSubTab: 1,
            startDate: moment().subtract(6, 'hours'),
            endDate: moment(),
            manifestDurationEndDate: moment(),
            manifestDurationStartDate: moment().subtract(6, 'hours'),
            manifestStatusEndDate: moment(),
            manifestStatusStartDate: moment().subtract(6, 'hours'),
            aggregateStartDate: moment().subtract(1, 'months').startOf('day'),
            aggregateEndDate: moment().endOf('day'),
            manifestProcessDurationChartData: false,
            manifestProcessDurationBars: false,
            totalManifestProcessOnDuration: 0,
            manifestStatusChartData: false,
            manifestStatusChartBars: false,
            totalManifestStatus: 0,
            totalManifestPullModes: 0,
            manifestStatusSuccessBar: false,
            manifestDurationSuccessBar: false,
            manifestAggregate: [],
            airlines: [],
            selectedAirline: "",
            TotalCount: 0,
            PageSize: 10,
            currentPage:  1
        }
    }

    componentDidMount(){
        //initialize Graphs
       let {user } = store.getState();
        // console.log('agreggate user', user);
        if(user) {
            this.initializePageData();
            const reduxState = store.getState();
            let {airlinesCarriers} = reduxState;
            if (airlinesCarriers) {
                this.setState({airlines: airlinesCarriers.Results})
            } else {
                this.fetchAirlines();
            }
        }
    }

    fetchAirlines = () => {
        const { dispatch } = this.props;
        dispatch(commonActions.getAirlinesCarriers())
            .then(() => {
                let { airlinesCarriers } = this.props;
                this.setState({ airlines: airlinesCarriers.Results });
            }).catch(error => {
            let errorMessage = appHelpers.interpretErrorResponse(error);
            console.log({ errorMessage });
            dispatch(alertActions.error(errorMessage))

        });
    }
    handleAirlineChange = (selected) => {
        this.setState({ selectedAirline: (selected) ? selected.Id : "" })
    }
    initializePageData = () => {
        let { startDate, endDate, aggregateStartDate, aggregateEndDate, PageSize } = this.state;
        const { dispatch } = this.props;
        startDate = startDate.format("YYYY-MM-DDTHH:mm");
        endDate = endDate.format("YYYY-MM-DDTHH:mm");
        const duration = moment.duration(moment(endDate).diff(startDate)).asDays();

        const payload = { startDate, endDate };
        Promise.all([
             commonService.searchManifestAggregateDuration(payload),
             commonService.searchManifestAggregateStatus(payload),
             commonService.searchManifestAggregate({

                 parameter: {
                     "startdate": aggregateStartDate,
                     "enddate": aggregateEndDate
                 },
                 from: 0,
                 pageSize: PageSize
             })
            ]
        ).then(response => {
            const manifestProcessDuration = response[0];
            const manifestStatus = response[1];
            const manifestAggregate = response[2];
            /* Manifest Process Duration */
            let  manifestDurationReform = graphHelpers.progressBars(manifestProcessDuration.response.summary, appConstants.MANIFEST_DURATION_BARS_VARIANTS, true);
            const manifestProcessDurationBars =  manifestDurationReform.progressBars;
            const totalManifestProcessOnDuration =  manifestDurationReform.total;
            const manifestDurationSuccessBar = manifestProcessDurationBars.filter(item => item.name === appConstants.MANIFEST_DURATION_SUCCESS_BAR)[0];
            const manifestProcessDurationChartData = graphHelpers.chartData(manifestProcessDuration.response.records, duration);

            /* Manifest Status */
            let  manifestStatusReform = graphHelpers.progressBars(manifestStatus.response.summary, appConstants.MANIFEST_AGGREGATE_BARS_VARIANTS);
            const manifestStatusChartBars =  manifestStatusReform.progressBars;
            const totalManifestStatus =  manifestStatusReform.total;
            const manifestStatusSuccessBar = manifestStatusChartBars.filter(item => item.name === appConstants.MANIFEST_AGGREGATE_STATUS_SUCCESS_BAR)[0];
            const manifestStatusChartData = graphHelpers.chartData(manifestStatus.response.records, duration);

            this.setState({
                manifestDurationSuccessBar,  manifestProcessDurationBars, totalManifestProcessOnDuration, manifestProcessDurationChartData,
                manifestStatusChartData, manifestStatusSuccessBar, manifestStatusChartBars, totalManifestStatus,
                manifestAggregate: manifestAggregate.response.AggregateRecords, TotalCount: manifestAggregate.response.TotalCount
            }, () => {
                dispatch(alertActions.stopRequest());
            });

        }).catch(err => {
            dispatch(alertActions.stopRequest());
            dispatch(alertActions.error(err))

        })
        dispatch(alertActions.startRequest());
    };
    handleAggregateListDate = (event, picker) => {
        this.setState({
            aggregateStartDate: picker.startDate,
            aggregateEndDate: picker.endDate
        })
        // this.handleSearch()
    }
    // handle apply click on date picker
    handleApply = async (event, picker, type) => {
        let StartDate = picker.startDate.format("YYYY-MM-DDTHH:mm");
        let EndDate = picker.endDate.format("YYYY-MM-DDTHH:mm");

        if(StartDate === EndDate){
            StartDate = picker.startDate.format("YYYY-MM-DDT00:00");
            EndDate = picker.endDate.format("YYYY-MM-DDT23:00");
        }
        const payload = { StartDate, EndDate };
        try{
            let response; // = await commonService.searchManifestProcessDuration(payload);
            switch (type){
                case appConstants.MANIFEST_PROCESS_DURATION:
                    this.setState({
                        manifestDurationStartDate: picker.startDate,
                        manifestDurationEndDate: picker.endDate
                    }, () => {
                        response = this.searchManifestProcessDuration(payload);
                    });
                    break;
                case appConstants.MANIFEST_STATUS:
                    this.setState({
                        manifestStatusStartDate: picker.startDate,
                        manifestStatusEndDate: picker.endDate
                    }, () => {
                        response = this.searchManifestStatus(payload);
                    });
                    break;
                default:
                    response = {}
            }

            return response;
        } catch (err){
            console.log({err});
        }

    };

    searchManifestProcessDuration = async (payload) => {
        let { manifestDurationStartDate, manifestDurationEndDate } = this.state;
        const { dispatch } = this.props;
        const duration = moment.duration(moment(manifestDurationEndDate).diff(manifestDurationStartDate)).asDays();
        dispatch(alertActions.startRequest());
        try{
            const response = await commonService.searchManifestAggregateDuration(payload);
            if (response.status === appConstants.SUCCESS_RESPONSE){
                let { progressBars, total } = graphHelpers.progressBars(response.response.summary, appConstants.MANIFEST_DURATION_BARS_VARIANTS, true);
                //check for the success bar
                const manifestDurationSuccessBar = progressBars.filter(item => item.name === appConstants.MANIFEST_DURATION_SUCCESS_BAR)[0];
                /*Chart Data*/
                const manifestProcessDurationChartData = graphHelpers.chartData(response.response.records, duration);
                this.setState({manifestProcessDurationChartData, manifestDurationSuccessBar,  manifestProcessDurationBars: progressBars, totalManifestProcessOnDuration: total});
            }else if (response.status === appConstants.ERROR_RESPONSE){
                dispatch(alertActions.error(response.response))

            }
            dispatch(alertActions.stopRequest());
        }catch (err){
            dispatch(alertActions.stopRequest());
            dispatch(alertActions.error(err))

        }
    };

    searchManifestStatus = async (payload) => {
        let { manifestStatusStartDate, manifestStatusEndDate } = this.state;
        const { dispatch } = this.props;

        const duration = moment.duration(moment(manifestStatusEndDate).diff(manifestStatusStartDate)).asDays();
        dispatch(alertActions.startRequest());
        try{
            const response = await commonService.searchManifestAggregateStatus(payload);
            if (response.status === appConstants.SUCCESS_RESPONSE){
                let { progressBars, total } = graphHelpers.progressBars(response.response.summary, appConstants.MANIFEST_AGGREGATE_BARS_VARIANTS);
                // check for the success bar
                const manifestStatusSuccessBar = progressBars.filter(item => item.name === appConstants.MANIFEST_AGGREGATE_STATUS_SUCCESS_BAR)[0];
                this.setState({manifestStatusSuccessBar, manifestStatusChartBars: progressBars, totalManifestStatus: total});
                /*Chart Data*/
                const manifestStatusChartData = graphHelpers.chartData(response.response.records, duration);
                this.setState({manifestStatusChartData, manifestStatusSuccessBar, manifestStatusChartBars: progressBars, totalManifestStatus: total});

            }else if (response.status === appConstants.ERROR_RESPONSE){
                dispatch(alertActions.error(response.response))

            }
            dispatch(alertActions.stopRequest());
        }catch (err){
            console.log(err)
            dispatch(alertActions.stopRequest());
            dispatch(alertActions.error(err))

        }
    };

    searchManifestAggregate = async (pageNumber = 1) => {
        let { aggregateStartDate, aggregateEndDate, PageSize, selectedAirline } = this.state;
        if(aggregateEndDate.format("YYYY-MM-DDTHH:mm") === aggregateStartDate.format("YYYY-MM-DDTHH:mm")){
            aggregateStartDate = moment(aggregateStartDate).startOf('day').format("YYYY-MM-DDTHH:mm");
            aggregateEndDate = moment(aggregateEndDate).endOf('day').format("YYYY-MM-DDTHH:mm");
        }
        const { dispatch } = this.props;
        let parameter = {
            "startdate": aggregateStartDate,
            "enddate": aggregateEndDate
        };

       let from = (pageNumber - 1) * PageSize;
       
        let payload = {
            from,
            pageSize: PageSize
        };

        if(selectedAirline !== ""){
            parameter.AirlineId = selectedAirline;
        }
        payload.parameter = parameter;

        dispatch(alertActions.startRequest());
        try{
            const response = await commonService.searchManifestAggregate(payload);
            if (response.status === appConstants.SUCCESS_RESPONSE){
                this.setState({
                    manifestAggregate: response.response.AggregateRecords,
                    TotalCount: response.response.TotalCount,
                    currentPage: pageNumber
                });
            }else if (response.status === appConstants.ERROR_RESPONSE){
                dispatch(alertActions.error(response.response))

            }
            dispatch(alertActions.stopRequest());
        }catch (err){
            console.log(err)
            dispatch(alertActions.stopRequest());
            dispatch(alertActions.error(err))

        }
    };

    render() {
       // return <Timeline/>
        const  {
             manifestProcessDurationBars, manifestDurationSuccessBar, totalManifestProcessOnDuration, manifestProcessDurationChartData,
            manifestStatusChartBars, manifestStatusSuccessBar, totalManifestStatus, manifestStatusChartData,
            manifestAggregate, airlines, aggregateStartDate, aggregateEndDate, TotalCount, currentPage, PageSize,
            manifestDurationEndDate, manifestDurationStartDate, manifestStatusStartDate, manifestStatusEndDate
        } = this.state;
        return (
            <AppLayout title={"Avicollect"} activeMenuIndex={3}>
                  <Row>
                    <Col md={12}>
                        <PrimaryTab menus={avicollectMenu} activeMenuIndex={3} />
                    </Col>
                </Row>
                <Row>
                    <Col md={9}>
                        <Card title={<GraphHeader title={"Manifest Aggregate Status"} startDate={manifestStatusStartDate} endDate={manifestStatusEndDate} handleEvent={this.handleEvent} handleApply={(e, p) => this.handleApply(e, p, appConstants.MANIFEST_STATUS)}/>}>
                            <AreaChartGraph legends={manifestAggregateStatusLegends} data={manifestStatusChartData}/>
                        </Card>
                    </Col>
                    <Col md={3}>
                        {
                            (manifestStatusChartBars && manifestStatusSuccessBar) &&
                            <SummaryCard title={'Summary'}
                                         bars={manifestStatusChartBars}
                                         animation={true}
                                         percentage={manifestStatusSuccessBar.percent}
                                         firstText={'Total Processed'}
                                         firstValue={totalManifestStatus}
                                         totalCountSuccessful={manifestStatusSuccessBar.value}
                                         secondText={false}
                                         secondValue={false}
                                         icon1={<FiPlusSquare />}
                                         icon2={<FiClock/>}
                            />
                        }
                    </Col>

                </Row>

                <Row>
                    <Col md={9}>
                        <Card title={<GraphHeader title={"Manifest Aggregate Duration"} startDate={manifestDurationStartDate} endDate={manifestDurationEndDate} handleEvent={this.handleEvent} handleApply={(e, p) => this.handleApply(e, p, appConstants.MANIFEST_PROCESS_DURATION)}/>}>
                            <AreaChartGraph legends={manifestDurationLegends} data={manifestProcessDurationChartData}/>
                        </Card>
                    </Col>
                    <Col md={3}>
                        {
                            (manifestProcessDurationBars && manifestDurationSuccessBar) &&
                                <SummaryCard title={'Manifest Aggregate'}
                                             bars={manifestProcessDurationBars}
                                             animation={true}
                                             percentage={manifestDurationSuccessBar.percent}
                                             firstText={'Total Processed'}
                                             firstValue={totalManifestProcessOnDuration}
                                             totalCountSuccessful={manifestDurationSuccessBar.value}
                                             secondText={false}
                                             secondValue={false}
                                             icon1={<FiPlusSquare />}
                                             icon2={<FiClock/>}
                                />
                        }

                    </Col>
                </Row>



                <Row>
                    <Col md={12} className={'failed-manifest-card'} >
                        <Card title={'Aggregated Manifests'} dynamicHeight={true}>
                            <SearchPanel
                                startDate={aggregateStartDate}
                                endDate={aggregateEndDate}
                                airlines={airlines}
                                handleApply={this.handleAggregateListDate}
                                handleSearch={this.searchManifestAggregate}
                                handleAirlineChange={this.handleAirlineChange}
                            />
                            {
                                (TotalCount === 0) &&
                                (
                                    <EmptyRecord message={"No record match search criteria"}/>
                                )
                            }
                            {
                                (TotalCount > 0) &&
                                <ManifestAggregateTable
                                    airlines={airlines}
                                    totalCount={TotalCount}
                                    records={manifestAggregate}
                                    currentPage={currentPage}
                                    pageSize={PageSize}
                                    fetchRecords={this.searchManifestAggregate}
                                />
                            }

                            <Timeline/>


                        </Card>
                    </Col>

                </Row>
            </AppLayout>
        );
    }
}

function mapStateToProps(state) {
    const { requesting, airlinesCarriers , user} = state;
    return {
        requesting,
        airlinesCarriers,
        user
    };
}
export default connect(mapStateToProps)(ManifestAggregate);
