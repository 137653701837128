import React from 'react'
import DatePicker from '../../components/datepicker';
import SelectPicker from '../../components/selectpicker'
import { Button } from 'react-bootstrap';
import { FiSearch } from 'react-icons/fi';
import './manifestlist.css'

const SearchPanel = ({ startDate, endDate, handleEvent,
    handleApply, handleSearch, statuses, airlines, dateTypes, selectedStatus,
    selectedAirline, selectedDateType, selectedPullMode, handlePullModeChange,
    handleAirlineChange, handleStatusChange, handleDateTypeChange, pullModes
}) => {

    return (
        <div className="row">
            <div className="col-md-2">
                <DatePicker startDate={startDate}
                    endDate={endDate}
                    onEvent={handleEvent}
                    onApply={handleApply}
                />
            </div>

            <div className="col-md-2">
                <SelectPicker
                    options={airlines}
                    placeholder="Select Airline"
                    selectedValue={selectedAirline}
                    handleChange={handleAirlineChange}
                    customKeys={true}
                />
            </div>
            <div className="col-md-2">
                <SelectPicker
                    options={dateTypes}
                    placeholder="Select Date Type"
                    selectedValue={selectedDateType}
                    handleChange={handleDateTypeChange}
                    customKeys={true}
                />
            </div>
            <div className="col-md-2">
                <SelectPicker
                    options={statuses}
                    placeholder="Select Status"
                    selectedValue={selectedStatus}
                    handleChange={handleStatusChange}
                    customKeys={true}
                />
            </div>

            <div className="col-md-2">
                <SelectPicker
                    options={pullModes}
                    placeholder="Select Pull Mode"
                    selectedValue={selectedPullMode}
                    handleChange={handlePullModeChange}
                    customKeys={true}
                />
            </div>

            <div className="col-md-2">
                <Button className="btn-search btn-block" onClick={() => handleSearch()}><span><FiSearch /></span> &nbsp; Search</Button>
            </div>

        </div>
    )
}

export default SearchPanel