export const appConstants = {
    GREEN_COLOR: "#51B74B",
    RED_COLOR: "#E04646",
    YELLOW_COLOR: "#CEB629",
    ORANGE_COLOR: "#FF851B",
    BLUE_COLOR: "#017691",
    PURPLE_COLOR: "#6A4EAF",
    GRAPH_GRID_COLOR: "#015c75",
    GRAPH_AXIS_COLOR: "#b5b5b5",

    REQUEST_SUCCESS: 'REQUEST_SUCCESS',
    REQUEST_FAILURE: 'REQUEST_FAILURE',

    ADD_LOG : 'add_log',
    PAS_URL: (process.env.NODE_ENV === 'development')? 'https://passerelle.test.vggdev.com' : window.env.passerelleUrl,


    APP_USER_KEY : 'tm-dashboard',
    APP_ID : 'AviTechDashboard',
    SUCCESS_RESPONSE: 1,
    ERROR_RESPONSE: 2,
    SIGN_IN_SUCCESS: 'POST_SIGN_IN_SUCCESS',
    SIGN_OUT: 'SIGN_OUT',
    INACTIVITY_EXPIRATION_LIMIT: 3600,

    /* Charts */
    MANIFEST_PROCESS_DURATION: "01",
    MANIFEST_STATUS: "02",
    MANIFEST_PULL_MODES: "03",
    AIRLINE_FLIGHT_COUNT: "04",
    LOCATION_FLIGHT_VARIANCE: "05",
    LOCATION_PAX_DIFFERENTIAL: "06",
    AIRLINE_PAX_VARIANCE: "07",
    FLIGHT_STATUS_COUNT: "08",
    TOTAL_SETTLED_UNSETTLED: "09",
    NOT_SETTLED: "10",
    ABOVE_SLA: "11",
    AIRLINE_DAILY_FLIGHT_TRACKER: "12",
    AIRLINE_DAILY_PAX_TRACKER: "13",
    AIRLINE_MONTHLY_FLIGHT_TRACKER: "14",
    AIRLINE_MONTHLY_PAX_TRACKER: "15",


    PROGRESS_BARS_VARIANTS: ["success", "warning", "danger", "info"],
    MANIFEST_PULL_MODES_BARS_VARIANTS: {
        Ftp : "success", Sftp: "info", Medview: "warning", Email: "success", Webservice: "danger", DataEntry : "warning",
        OverlandService: "info", AirfranceKlm: "warning", Azman: "success", AirPeace: "danger", Arik: "info", Emirate: "warning",
        Meridiana: "success", Lufthansa: "warning", MaxAir: "info", RoyalAirMaroc: "success"
    },
    MANIFEST_STATUS_BARS_VARIANTS: { Pending : "warning", Processing: "info", Partial: "warning", Successful: "success", Failed: "danger"},
    MANIFEST_AGGREGATE_BARS_VARIANTS: { Processing : "info", Completed: "success", Failed: "danger"},
    MANIFEST_DURATION_BARS_VARIANTS: { "<10" : "success", "10-30": "warning", ">30": "danger" },
    MANIFEST_DURATION_SUCCESS_BAR: "<10",
    MANIFEST_STATUS_SUCCESS_BAR: "Successful",
    MANIFEST_AGGREGATE_STATUS_SUCCESS_BAR: "Completed",

    GRAPH_DEFAULT_OPACITY: 0.75,
    GRAPH_FOCUS_OPACITY: 1,

    TIMELINE_STEP_FAILED: "failed",
    TIMELINE_STEP_COMPLETED: "completed",
    TIMELINE_STEP_ACTIVE: "active",
    TIMELINE_STEP_INACTIVE: "inactive"
}
