import React from 'react'
import DatePicker from '../../components/datepicker';
import SelectPicker from '../../components/selectpicker'
import { Button } from 'react-bootstrap';
import { FiSearch } from 'react-icons/fi';
import './manifestlaggregate.scss'

const SearchPanel = ({ startDate, endDate, handleEvent,
    handleApply, handleSearch, statuses, airlines,
    selectedAirline, handleAirlineChange
}) => {

    return (
        <div className="row">
            <div className="col-md-3">
                <DatePicker startDate={startDate}
                    endDate={endDate}
                    onEvent={handleEvent}
                    onApply={handleApply}
                />
            </div>

            <div className="col-md-3">
                <SelectPicker
                    options={airlines}
                    placeholder="All Airlines"
                    selectedValue={selectedAirline}
                    handleChange={handleAirlineChange}
                    customKeys={true}
                />
            </div>

            <div className="col-md-2">
                <Button className="btn-search btn-block" onClick={()=>handleSearch()}><span><FiSearch /></span> &nbsp; Search</Button>
            </div>

        </div>
    )
}

export default SearchPanel
