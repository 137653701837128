import React, { Component } from 'react';
import 'react-vertical-timeline-component/style.min.css';
import FilePullComponent from "./steps/filepull";
import FileProcessComponent from "./steps/fileprocess";
import TimelineHeader from "../../../components/timeline/timelineHeader";

class TimelineComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 1,
            steps: [],
            lastStep : false,
            totalStep: 0
        }
    }

    switchStep = (activeStep) => {
        this.setState({activeStep}, () =>{
            this.renderStepComponent();
        });

    }

    renderStepComponent = () => {
        const { activeStep } = this.state;
        const { SourceFilePull, ProcessRetries, ConvertToCsv, S3Upload, SaveToDB, S3Download, ReadCsvRecords, ValidateRecords, GenerateManifest, SendManifestsToFmd, FileProcessSummary } = this.props.timelineData;
        switch (activeStep) {
            case 1:
                return <FilePullComponent airline={this.props.airline} SourceFilePull={SourceFilePull} ConvertToCsv={ConvertToCsv} S3Upload={S3Upload} SaveToDB={SaveToDB}/>;
            case 2:
                return <FileProcessComponent ProcessRetries={ProcessRetries} S3Download={S3Download} ReadCsvRecords={ReadCsvRecords} ValidateRecords={ValidateRecords} GenerateManifest={GenerateManifest} SendManifestsToFmd={SendManifestsToFmd} FileProcessSummary={FileProcessSummary} />;
            default:
                return <FilePullComponent SourceFilePull={SourceFilePull} ConvertToCsv={ConvertToCsv} S3Upload={S3Upload} SaveToDB={SaveToDB}/>;
        }
    }

    render() {
        const { timelineData } = this.props;
        const { activeStep } = this.state;
        if(!timelineData)
            return "";

        let { SourceFilePull, S3Download } = timelineData;

        const steps = [
            {
                name: 'Pull File',
                status: (SourceFilePull === null) ? 'inactive'  : (SourceFilePull) ? 'completed' : 'failed'
            },
            {
                name: 'Process File',
                status: (S3Download === null) ? 'inactive'  : (S3Download) ? 'completed' : 'failed'
            }
        ];

        return (
            <div>
                <TimelineHeader
                    steps={steps}
                    activeStep={activeStep}
                    handleSwitchStep={this.switchStep}
                />
                {
                    this.renderStepComponent()
                }
            </div>
        );
    }
}

export default TimelineComponent;


/*
export default MyComponent;


const TimelineComponent = ({ timelineData, processStatus, airline }) => {
    let { SourceFilePull, ConvertToCsv, S3Upload, SaveToDB, S3Download, ReadCsvRecords, ValidateRecords, GenerateManifest, SendManifestsToFmd, FileProcessSummary} = timelineData;
    return (
        <div>
            <VerticalTimeline>

                <VerticalTimelineElement
                    date={(<h3 className={'stepName'} style={FilePullIconStepNameStyle}>File Pull</h3>)}
                    iconStyle={FilePullIconStepNameStyle}
                    icon={<FiStar />}
                />

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(SourceFilePull) ? moment(SourceFilePull.CreatedDate).fromNow() : moment().fromNow()}
                    icon={<FiDownload/>}
                    iconStyle={(!SourceFilePull) ? ProcessErrorIconStepStyle : FilePullIconStepStyle }
                >
                    <p className="vertical-timeline-element-paragraph">
                        {
                            SourceFilePull &&
                                <span>
                                    {airline} file was pulled via <strong>{SourceFilePull.PullMode}</strong> pull mode under
                                     <strong> {appHelpers.formatMinutesDescription(SourceFilePull.Duration)}</strong>
                                </span>
                        }
                        {
                            !SourceFilePull &&
                                <span>
                                    File Not Pull Yet!
                                </span>
                        }
                    </p>
                    <h5 className="vertical-timeline-element-subtitle">{'File Pull'}</h5>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(ConvertToCsv) ? moment(ConvertToCsv.Ended).fromNow() : moment().fromNow()}
                    iconStyle={(!ConvertToCsv) ? ProcessErrorIconStepStyle : FilePullIconStepStyle }
                    icon={<FiEdit/>}
                >
                    {
                        ConvertToCsv &&
                        <p className="vertical-timeline-element-paragraph">
                            It took <strong>{appHelpers.formatMinutesDescription(ConvertToCsv.Duration)}</strong> to convert the file to CSV
                        </p>
                    }
                    {
                        !ConvertToCsv &&
                            <span>File not converted yet!</span>
                    }

                    <h5 className="vertical-timeline-element-subtitle">{'Conversion To CSV'}</h5>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(S3Upload) ? moment(S3Upload.Ended).fromNow() : moment().fromNow()}
                    iconStyle={(!S3Upload) ? ProcessErrorIconStepStyle : FilePullIconStepStyle }
                    icon={<FiUploadCloud/>}
                >
                    {
                        S3Upload &&
                        <p className="vertical-timeline-element-paragraph">
                            It took <strong> {appHelpers.formatMinutesDescription(S3Upload.Duration)}</strong> to upload the file to S3 bucket. The file can be accessed <a
                            href={S3Upload.FileUrl}>here</a>
                        </p>
                    }
                    {
                        !S3Upload &&
                            <span>File not uploaded yet!</span>
                    }

                    <h5 className="vertical-timeline-element-subtitle">{'S3 Bucket Upload'}</h5>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(SaveToDB) ? moment(SaveToDB.Ended).fromNow() : moment().fromNow()}
                    iconStyle={(!SaveToDB) ? ProcessErrorIconStepStyle : FilePullIconStepStyle }
                    icon={<FiSave/>}
                >
                    {
                        SaveToDB &&
                        <p className="vertical-timeline-element-paragraph">
                            It took <strong> {appHelpers.formatMinutesDescription(SaveToDB.Duration)}</strong> to save file record in the database.
                        </p>
                    }
                    {
                        !SaveToDB &&
                        <span>File not saved yet!</span>

                    }
                    <h5 className="vertical-timeline-element-subtitle">{'Save to Database'}</h5>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    date={(<h3 className={'stepName'} style={FileProcessIconStepNameStyle}>File Process</h3>)}
                    iconStyle={FileProcessIconStepNameStyle}
                    icon={<FiStar />}
                />

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(S3Download) ? moment(S3Download.Ended).fromNow() : moment().fromNow()}
                    iconStyle={(!S3Download) ? ProcessErrorIconStepStyle : FileProcessIconStepStyle }
                    icon={<FiDownloadCloud/>}
                >
                    {
                        S3Download &&
                        <p className="vertical-timeline-element-paragraph">
                            File was downloaded successfully under <strong> {appHelpers.formatMinutesDescription(S3Download.Duration)}</strong>
                        </p>
                    }
                    {
                        !S3Download &&
                        <span>File not downloaded yet!</span>
                    }

                    <h5 className="vertical-timeline-element-subtitle">{'Download S3 File'}</h5>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(ReadCsvRecords) ? moment(ReadCsvRecords.Ended).fromNow() : moment().fromNow()}
                    iconStyle={(!ReadCsvRecords) ? ProcessErrorIconStepStyle : FileProcessIconStepStyle }
                    icon={<FiGitPullRequest/>}
                >
                    {
                        ReadCsvRecords &&
                        <p className="vertical-timeline-element-paragraph">
                            It took <strong> {appHelpers.formatMinutesDescription(ReadCsvRecords.Duration)}</strong> to read manifests in the file.
                        </p>
                    }
                    {
                        !ReadCsvRecords &&
                        <span>Records not available yet!</span>
                    }

                    <h5 className="vertical-timeline-element-subtitle">{'Read CSV Records'}</h5>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(ValidateRecords) ? moment(ValidateRecords.Ended).fromNow() : moment().fromNow()}
                    iconStyle={(!ValidateRecords || ValidateRecords.Error) ? ProcessErrorIconStepStyle : FileProcessIconStepStyle }
                    icon={<FiCheckCircle/>}
                >
                    <p className="vertical-timeline-element-paragraph">
                        {
                            (ValidateRecords && ValidateRecords.Error) &&
                                <span>{ValidateRecords.Error}</span>
                        }
                        {
                            (ValidateRecords && !ValidateRecords.Error) &&
                                <span>No error was found! </span>
                        }
                    </p>
                    <h5 className="vertical-timeline-element-subtitle">{'Validate Records'}</h5>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(GenerateManifest && GenerateManifest.Ended) ? moment(GenerateManifest.Ended).fromNow() : (GenerateManifest && GenerateManifest.Started) ? moment(GenerateManifest.Started).fromNow() : moment().fromNow()}
                    iconStyle={(!GenerateManifest || !GenerateManifest.Ended) ? ProcessErrorIconStepStyle : FileProcessIconStepStyle }
                    icon={<FiFileText/>}
                >
                    <p className="vertical-timeline-element-paragraph">
                        {
                            !GenerateManifest &&
                               <span>Manifests not generated yet!</span>
                        }
                        {
                            (GenerateManifest && GenerateManifest.Ended) &&
                            <span>
                                {appHelpers.numberWithCommasOnly(GenerateManifest.TotalManifests)} manifest was generated under <strong>{appHelpers.formatMinutesDescription(GenerateManifest.Duration)}</strong>
                            </span>
                        }
                        {
                            (GenerateManifest && !GenerateManifest.Ended) &&
                            <span>Manifest generation not completed yet!</span>
                        }
                    </p>
                    <h5 className="vertical-timeline-element-subtitle">{'Generate Manifest'}</h5>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(SendManifestsToFmd && SendManifestsToFmd.Ended) ? moment(SendManifestsToFmd.Ended).fromNow() : (SendManifestsToFmd && SendManifestsToFmd.Started) ? moment(SendManifestsToFmd.Started).fromNow() : moment().fromNow()}
                    iconStyle={(!SendManifestsToFmd || !SendManifestsToFmd.Ended) ? ProcessErrorIconStepStyle : FileProcessIconStepStyle }
                    icon={<FiFileText/>}
                >
                    <p className="vertical-timeline-element-paragraph">
                        {
                            !SendManifestsToFmd &&
                            <span>Manifest not sent to FMD yet!</span>
                        }
                        {
                            (SendManifestsToFmd && SendManifestsToFmd.Ended) &&
                            <span>
                                <strong>{appHelpers.numberWithCommasOnly(SendManifestsToFmd.SuccesfulManifests)}</strong> out of &nbsp;
                                <strong>{appHelpers.numberWithCommasOnly(SendManifestsToFmd.TotalManifests)}</strong> manifests was successfully created.
                                &nbsp;It took <strong>{appHelpers.formatMinutesDescription(SendManifestsToFmd.Duration)}</strong>
                            </span>
                        }
                        {
                            (SendManifestsToFmd && !SendManifestsToFmd.Ended) &&
                            <span>Manifest not sent to FMD yet!</span>
                        }
                    </p>
                    <h5 className="vertical-timeline-element-subtitle">{'FMD'}</h5>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    date={(FileProcessSummary && FileProcessSummary.Ended) ? moment(FileProcessSummary.Ended).fromNow() : (FileProcessSummary && FileProcessSummary.Started) ? moment(FileProcessSummary.Started).fromNow() : moment().fromNow()}
                    iconStyle={(!FileProcessSummary || FileProcessSummary.Error) ? ProcessErrorIconStepStyle : FileProcessIconStepStyle }
                    icon={<FiInfo/>}
                >
                    <p className="vertical-timeline-element-paragraph">
                        {
                            !FileProcessSummary &&
                            <span>File not processed yet!</span>
                        }
                        {
                            (FileProcessSummary && FileProcessSummary.Error) &&
                            <span>
                                {FileProcessSummary.Error}
                            </span>
                        }
                        {
                            (FileProcessSummary && !FileProcessSummary.Error) &&
                            <span>File processed successfully. It took <strong>{appHelpers.formatMinutesDescription(FileProcessSummary.Duration)}</strong></span>
                        }
                    </p>
                    <h5 className="vertical-timeline-element-subtitle">{'File Process Summary'}</h5>
                </VerticalTimelineElement>

                <VerticalTimelineElement
                    date={`That's all.`}
                    iconStyle={{ background: 'rgb(255, 255, 255)', color: '#2992d0' }}
                    // iconStyle={renderIconStyle()}
                    icon={<FiStar />}
                />
            </VerticalTimeline>
        </div>
    );
};

export default TimelineComponent;*/
