import React from 'react';
import DatePicker from "../../components/datepicker";
import { Row, Col
} from "react-bootstrap";

export const GraphHeader = ({ title, startDate, endDate, handleEvent, handleApply, flightSettlement }) => {
    return (
        <Row>
            <Col lg={4}>
                <h5>{title}</h5>
            </Col>
            <Col lg={ flightSettlement ? {span: 6, offset: 2} : {span: 4, offset: 4 }}>
                <DatePicker
                    startDate={startDate}
                    endDate={endDate}
                    onEvent={handleEvent}
                    onApply={handleApply}
                />
            </Col>
        </Row>
    );
};
