import React, { Component } from 'react';
import ServerPagination from "../../components/paginatedtable";
import { appHelpers } from "../../_helpers";
import {
    Table,
    Badge,
    Col,
    Row
} from "react-bootstrap";
import TableCell from '../../components/table/tablecell';
import {connect} from "react-redux";
import DrillDownSearchPanel from "./drillDownSearchPanel";
import {EmptyRecord} from "../../components/pageutility";
import ProgressLoader from "../../components/progressloader";

const renderStatus = (status) => {
    switch (status) {
        case false:
            return (<Badge variant='danger'>Issues Exist</Badge>);
        case true:
            return (<Badge variant='success'>Ok</Badge>);
        default:
            return (<Badge variant='secondary'>No Status</Badge>)
    }

};

    const ModalTableStructure = ({records, pageIndex, departureAirport, selectedAirport, handleAirportChange,
                                     drillDownStatus, selectedDrillDownStatus, handleDrillDownStatusChange,
                                     flightStartDate, flightEndDate, handleEvent, handleApply, handleSearch,
                                     drillDownTotalCount}) => (
        <div>
            <Row>
                <Col md={12} className={'manifest-list'}>
                    <DrillDownSearchPanel
                        // flightStartDate={flightStartDate}
                        // flightEndDate={flightEndDate}
                        // handleEvent={handleEvent}
                        // handleApply={handleApply}
                        departureAirport={departureAirport}
                        selectedAirport={selectedAirport}
                        handleAirportChange={handleAirportChange}
                        drillDownStatus={drillDownStatus}
                        selectedDrillDownStatus={selectedDrillDownStatus}
                        handleDrillDownStatusChange={handleDrillDownStatusChange}
                        handleSearch={handleSearch}
                    />
                </Col>
            </Row>

            {
                (drillDownTotalCount > 0) &&
            <Table hover className='table-vgg-darkblue' responsive='sm'>
                <thead>
                <tr>
                    <th>S/N</th>
                    <th>Flight Date</th>
                    <th>Airport</th>
                    <th>Min Range</th>
                    <th>Max Range</th>
                    <th>Actual Flight Count</th>
                    <th>Status</th>
                    {/*<th>Action</th>*/}
                </tr>
                </thead>
                <tbody>
                {records.map((record, index) => {
                    return (
                        <tr key={index} className='pointer'>
                            <TableCell content={pageIndex + index + 1}/>
                            <TableCell content={appHelpers.formatDate(record.FlightDate)}/>
                            <TableCell content={record.AirportName}/>
                            <TableCell content={record.MinRange}/>
                            <TableCell content={record.MaxRange}/>
                            <TableCell content={record.Actual}/>
                            <TableCell content={renderStatus(record.Status)}/>
                            {/*<TableCell content={<FiEdit className={'table-fixchecksquare-icon'} size={'2em'} />}/>*/}
                        </tr>

                    )
                })}
                </tbody>
            </Table>
            }
            {
                (drillDownTotalCount === 0) && <EmptyRecord
                    message={'No breakdown record found'}
                />
            }
        </div>
    );

class PaginatedTable extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const { totalCount, records, currentPage, pageSize,
            fetchRecords, pageIndex ,  departureAirport,
            selectedAirport, handleAirportChange, drillDownStatus,
            selectedDrillDownStatus, handleDrillDownStatusChange,
            handleSearch, drillDownTotalCount, requesting
            // flightStartDate, flightEndDate, handleEvent, handleApply,
            } = this.props;
        return (
            <div>
                <div className="table-responsive" style={{overflowX: "auto"}}>
                    {
                        requesting &&
                        <ProgressLoader message="Loading data..." />
                    }
                    <ServerPagination fetchMore={fetchRecords}
                                      totalCount={totalCount} pageSize={pageSize}
                                      currentPage={currentPage}
                                      target={
                                          <ModalTableStructure
                                              records={records}
                                              pageIndex={pageIndex}
                                              // flightStartDate={flightStartDate}
                                              // flightEndDate={flightEndDate}
                                              // handleEvent={handleEvent}
                                              // handleApply={handleApply}
                                              departureAirport={departureAirport}
                                              selectedAirport={selectedAirport}
                                              handleAirportChange={handleAirportChange}
                                              drillDownStatus={drillDownStatus}
                                              selectedDrillDownStatus={selectedDrillDownStatus}
                                              handleDrillDownStatusChange={handleDrillDownStatusChange}
                                              handleSearch={handleSearch}
                                              drillDownTotalCount={drillDownTotalCount}
                                          />
                                      }
                    />
                </div>

            </div>
        )
    }
}

function mapStateToProps(state) {
    const { modal, requesting } = state;
    return {
        requesting,
        modal,
    };
}
export default connect(mapStateToProps)(PaginatedTable);

/* Written with love by Juliet on July 2019 */